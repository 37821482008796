.thumbnail {
    position: relative;
    display: inline-block;
}

.fullBanner {
    padding: 8px;
}

.caption {
    position: absolute;
    top: 50px;
    padding: 4px;
    left: 0px;
    width: 110px;
    z-index: 2;
    border: 1px solid white;
    border-radius: 5px 30px 30px 5px;
    background-color: #fff;
}

.overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.4);
}

.container-fluid {
    position: relative !important;
}

.caption1 {
    position: absolute;
    width: 100vw;
    bottom: 0px;
    height: 40px;
    left: -13px;
    z-index: 2;
    background-color: rgba(6, 10, 6, 0.8);
    color: white;
    font-weight: bold;
}

.buttonsSFContainer {
    margin-top: 10px;
}

.filtButn {
    margin-top: 20px;
}


.resLoader {
    margin-bottom: 10px;
}

.caption img {
    width: 90px;
}

.imgWid0 {
    width: 3vw;
}

#popover-basic1 {
    margin-top: 30px;
}

@media screen and (min-width: 480px) {
    .fullBanner {
        padding: 10px;
        padding-left: 140px;
        padding-right: 80px;
    }

    .imgWid0 {
        width: 20px;
    }
}

.hotel-details-container {
    line-height: 1.2;
    white-space: inherit;
    padding-left: 0px !important;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    cursor: pointer;
}
.imgWidr {
    width: 23px;
}



.area {
    vertical-align: text-top;
}

.dfl {
    margin: 0 !important;
    padding: 0 !important;
}

/* .slick-slide img {
    display: inline-block;
    padding: 0;
    margin: 0;
} */


/* Arrows */

.slick-prev,
.slick-next {
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 47%;
    display: block;
    padding: 0;
    -webkit-transform: translate(50, -50%);
    -ms-transform: translate(50, -50%);
    transform: translate(50, -50%);
    cursor: pointer;
    color: transparent;
    border: none;
    outline: none;
    background: #fff;
    box-shadow: rgb(166, 167, 167) 0px 1px 2px 1px;
    -moz-transform: translate(50, -50%);
    -o-transform: translate(50, -50%);
}

.slick-prev:before,
.slick-next:before {
    font: normal normal normal 12px/1 FontAwesome;
    font-size: 10px;
    color: #ff3d00;
    display: flex;
    top: 50%;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}

.slick-prev {
    width: 20px;
    height: 20px;
    z-index: 999;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}

.slick-prev:before {
    background-image: url('./angle-left-solid.svg');
    background-repeat: no-repeat;
    height: 9px;
    color: white;
    margin-top: 5.5px;
    margin-left: 7px;
}

.slick-next {
    width: 20px;
    height: 20px;
    z-index: 999;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}

.slick-next:before {
    background-image: url('./angle-right-solid.svg');
    background-repeat: no-repeat;
    height: 11px;
    color: white;
    margin-top: 4.5px;
    margin-left: 7.5px;
}

.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
    color: transparent;
    outline: none;
    background: #fff;
}

.hotel-card {
    height: 13rem;
    box-sizing: border-box;
}
.sliderImgs{
    height: 13rem;
}
.h-45{
    height: 13rem !important;
}

.sliderImgs {
    width: 100%;
    cursor: pointer;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
.hotel-pricing-container-p {
    background-color: rgb(255, 51, 79);
    opacity: 0.8;
    color: #fff;
    padding: 2px 10px 2px 10px;
    border-radius: 20px;
    font-size: 10px;
    white-space: nowrap;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
}

@media screen and (min-width: 480px) {
    .hotel-pricing-container-p {
        font-size: 15px;
        /* padding-right: 10px; */
    }
}

.hotel-pricing-container-p1 {
    color: white;
    margin: 15px;
    z-index: 0;
    opacity: 0.1;
    height: 30px;
}

.very-good span {
    font-size: 15px;
    font-weight: 900;
}



.hotel-pricing-container {
    /* display: flex;
    justify-content: space-between;
    align-items: baseline; */
    /* padding: 0px;
    margin: 0px; */
    /* margin-top: -30px; */
}

@media screen and (min-width: 480px) {
    .very-good span {
        font-size: 15px;
        font-weight: 900;

    }

    .hotel-pricing-container {
        margin-top: 10px;
    }

    .very-good u {
        font-size: 13px;
        text-align: right;
    }
}
.ww{
    font-size: 20px;
    font-weight: 700;
}


.very-good {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-left: 5px;
    font-weight: 900;
    align-items: flex-end;
}

.user-rating-container {
    display: flex;
    flex-direction: row-reverse;
    /* margin-top: 15px; */
    display: flex;
    flex-direction: row-reverse;
    margin-bottom: 5px;
    /* margin-top: 15px; */
    align-items: flex-end;
}

.reviewCount {
    border-radius: 5px 5px 5px 5px ;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    padding: 10px;
    background-color: #F58D3C;
    justify-content: center;
    width: 32px;
    height: 32px;
    box-shadow: 0px 3px 4px 2px #e0e0e0;

    background-size: cover;
    font-size: 14px;
    color: #fff;
    font-weight: 900;
}

.imgwid4 {
    margin-left: -19px;
}

@media screen and (min-width: 480px) {
    .reviewCount {
        width: 42px;
        height: 42px;
        padding-top: 10px;
        font-size: 16px;
    }

}


.chattxt {
    margin-left: -29px;
}

@media screen and (min-width: 480px) {
    .chattxt {
        margin-top: -5px;
        line-height: 100%;
    }

    .getText {
        line-height: 0.9;
    }

    .searchLogo {
        margin-top: 5px;
        width: 100px;
    }

}

.imgwiddd {
    margin-top: 10px;
}

.chatBtn {
    border: none;
    background-color: #7ed34f;
    padding: 3px;
    margin-left: .1px;
    color: white;
    border-radius: 20px;
}

.para2 {
    font-size: 13px;
}

@media screen and (min-width: 480px) {
    .chatBtn {
        padding-left: 8px;
        padding-right: 8px;
    }


    .para2 {
        font-size: 18px;
    }

}

@media screen and (max-width: 480px) {
    .rev {
        font-size: 3.9vw;
    }

    .left {
        font-size: 3vw;
    }

    .rs {
        font-size: 3vw;
    }

    .free {
        font-size: 3vw;
    }

    .area {
        font-size: 3.9vw;
        vertical-align: text-top;
    }

    .imgWid1 {
        width: 12vw;
        vertical-align: text-top;
    }

    .chat {
        font-size: 2.5vw;
    }
}

.freepartTxt {
    font-size: 13px;
}


@media screen and (min-width: 480px) {
    .imgWid1 {
        width: 70px;
        margin-top: 12px;
    }

   
 
}

.para {
    font-size: 16px;
}

@media screen and (min-width: 480px) {
    .rs {
        font-size: 3vw;
        float: right;
    }
}

.VAL {
    vertical-align: text-bottom;
}




/* sort screen */

.side-popup,
.side-search-popup,
.side-gender-popup,
.filter-side-popup,
.sort-popup {
    /* right: -200%; */
    transition: all 0.5s linear;
}

.sort-popup-hide {
    right: -200%;
}

.sort-popup-show {
    right: 0;
}

.top-0 {
    top: 0;
}

.z-index-999 {
    z-index: 999;
}

.line-height-21 {
    line-height: 21px;
}

.ft-18 {
    font-size: 18px !important;
}

.z-index-999 {
    z-index: 999;
}



.border-radius {
    border-radius: 8px !important;
}

.border-orange {
    border-color: var(--main-theme-color) !important;
}

.text-orange {
    color: var(--main-theme-color);
}

.gr-medium {
    font-family: 'gothamroundedmedium' !important;
}

.sort-popup a {
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
}

.custom-control-label-sort {
    width: 85%;
}

.custom-control-label-sort:before,
.custom-control-label-sort:after {
    right: -1.5rem;
    left: unset;
    border-radius: 0 !important;
    color: #000 !important;
    border-color: transparent !important;
    background-color: transparent !important;
}

.custom-checkbox .custom-control-input-sort:checked~.custom-control-label-sort::after {
    background-image: url(https://www.roomph.pk/mobile/images/checked.svg);
    background-size: 100%;
}

.custom-control-label-sort:before,
.custom-control-label-sort:after {
    right: -1.5rem;
    left: unset;
    border-radius: 0 !important;
    color: #000 !important;
    border-color: transparent !important;
    background-color: transparent !important;
}
.range-slider{
    width: 100%;
}
.cityStar {
    font-size: 15px;
}

@media screen and (min-width: 480px) {
    .cityStar {
        font-size: 15px;
    }

    .chkbox1r {
        margin-left: -3px !important;
        margin-bottom: -5px !important;
    }
}

.priceRs{
    display: flex;
    /* flex-direction: row-reverse; */
    /* display: flex; */
    /* flex-direction: row-reverse; */
    align-items: flex-end;
    justify-content: flex-end;
    /* font-weight: 700; */

}
.priceRs1 {
    color: #FF334F;
}

.changeDatebtn {
    background-size: 13px;
    /* background-repeat: no-repeat; */
    font-size: 8px;
    border: 1px solid #b3b3b3;
    border-radius: 10px;
    padding: 5px;
    width: 100px;
    height: fit-content;
    text-align: center;
    display: block;
    flex-direction: column;
    background-color: rgba(230, 230, 230, 0.5);
    -webkit-border-radius: 10px;
    align-items: center;
    -moz-border-radius: 10px;

}

.changeDatebtn img{
    width: 20px;
}

@media screen and (min-width: 480px) {
    
    .changeDatebtn {
        font-size: 12px;
    }

}

.property_container {
    margin: auto;
    display: flex;
}

.destination-sidebar {
    box-shadow: 0px 3px 12px #00000021;
    border: 1px solid #DCDCDC;
    border-radius: 9px;
    padding-left: 21px;
    padding-right: 21px;
}

.ft-25 {
    font-size: 0.9rem !important;
}

.text-gray {
    color: #666666;
}

.star-rating {
    margin-bottom: 28px !important;
}

.mt-55 {
    margin-top: 55px;
}

.ft-21 {
    font-size: 1.2 !important;
}

.text-gray {
    color: #666666;
}




[type="checkbox"]:not(:checked),
[type="checkbox"]:checked {
    position: absolute;
    left: -9999px;
    opacity: 0;
}



.btn-flat {
    background: transparent !important;
    color: #111111 !important;
    border: 1px solid #D6D6D6;
}
.btn-orange {
    background: var(--main-theme-color) !important;
    color: #ffff !important;
    border: 1px solid var(--main-theme-color) !important;
    color: #000;
    position: relative;
    cursor: pointer;
    overflow: hidden;
    padding-top: 10px !important;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    z-index: 1;
    font-size: 0.9rem !important;
    transition: .3s ease-out;
    padding: 0;
    margin: 2px;
    text-align: center;
    border-radius: 10px !important;
    padding-top: 8px;
    width: 44px;
    height: 39px;
    -webkit-border-radius: 10px !important;
    -moz-border-radius: 10px !important;
    -ms-border-radius: 10px !important;
    -o-border-radius: 10px !important;
}
/* .waves-effect {
    background: var(--main-theme-color);
    color: #fff;
    position: relative;
    cursor: pointer;
    display: inline-block;
    overflow: hidden;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    vertical-align: middle;
    z-index: 1;
    transition: .3s ease-out;
    width: 44px;
    height: 39px;
    border: 1px solid var(--main-theme-color);
    border-radius: 9px;
    padding: 0;
    line-height: 40px;
} */
.mb-28{
    margin-bottom: 28px !important;
}
.br-theme-bars-square .br-widget {
    height: 15px;
    white-space: nowrap;
}

.addui-slider-range {
    left: 0;
    width: 100%;
}

.f-13 {
    font-size: 13px;
}

.addui-slider.addui-slider-horizontal {
    height: 50px;
    width: calc(100% - 0px);
    margin: 0 auto 33px;
}

.addui-slider * {
    box-sizing: border-box;
}

.addui-slider.addui-slider-horizontal .addui-slider-track {
    height: 4px;
    width: 100%;
    top: 23px;
    left: 0;
}

.addui-slider .addui-slider-track {
    display: block;
    position: relative;
    background: #eee;
    border-radius: 4px;
}

.addui-slider * {
    box-sizing: border-box;
}

.addui-slider.addui-slider-horizontal .addui-slider-track .addui-slider-range {
    width: auto;
    height: 4px;
}

.addui-slider .addui-slider-track .addui-slider-range {
    display: block;
    background: var(--main-theme-color-opacity);
    border-radius: 4px;
    position: absolute;
}

.addui-slider.addui-slider-horizontal .addui-slider-track .addui-slider-handle {
    left: auto;
    top: -24px;
    transform: translateX(-50%);
}

.addui-slider .addui-slider-track .addui-slider-handle {
    display: block;
    width: 50px;
    height: 50px;
    background: rgba(63, 81, 181, 0);
    box-shadow: 0 0 5px rgb(63 81 181 / 0%);
    position: absolute;
    border-radius: 50px;
    cursor: pointer;
    transition: background 1s, box-shadow 1s;
    z-index: 0;
}

.addui-slider .addui-slider-track .addui-slider-handle .addui-slider-value {
    opacity: 1;
    margin-top: 20px;
}

.addui-slider .addui-slider-track .addui-slider-handle .addui-slider-value {
    display: block;
    float: right;
    border-radius: 50%;
    margin-top: 0px;
    color: #666666;
    pointer-events: none;
    position: absolute;
    z-index: 1;
}

.addui-slider * {
    box-sizing: border-box;
}

.addui-slider .addui-slider-track .addui-slider-handle:after {
    display: block;
    content: " ";
    width: 17px;
    height: 17px;
    background: var(--main-theme-color);
    border-radius: 25px;
    position: relative;
    top: 17px;
    left: 13px;
}

.addui-slider .addui-slider-track .addui-slider-handle .addui-slider-value span {
    display: block;
    width: 100%;
    height: 100%;
    text-align: center;
    line-height: 50px;
    position: absolute;
    font-family: 'roboto-light' !important;
    z-index: 1;
    color: #666666;
    margin-left: 13px;
}

.addui-slider * {
    box-sizing: border-box;
}

.addui-slider .addui-slider-track .addui-slider-handle .addui-slider-value span:after {
    content: 'k';
    position: relative;
    left: 0;
    top: 0;
    right: 0;
    margin: 0 auto;
    font-size: 13px;
}

.addui-slider.addui-slider-horizontal .addui-slider-track .addui-slider-handle {
    left: auto;
    top: -24px;
    transform: translateX(-50%);
}

.addui-slider .addui-slider-track .addui-slider-handle {
    display: block;
    width: 50px;
    height: 50px;
    background: rgba(63, 81, 181, 0);
    box-shadow: 0 0 5px rgb(63 81 181 / 0%);
    position: absolute;
    border-radius: 50px;
    cursor: pointer;
    transition: background 1s, box-shadow 1s;
    z-index: 0;
}

.addui-slider .addui-slider-track .addui-slider-handle .addui-slider-value {
    opacity: 1;
    margin-top: 20px;
}

.addui-slider .addui-slider-track .addui-slider-handle .addui-slider-value {
    display: block;
    float: right;
    border-radius: 50%;
    margin-top: 0px;
    color: #666666;
    pointer-events: none;
    position: absolute;
    z-index: 1;
}

.addui-slider * {
    box-sizing: border-box;
}

.addui-slider .addui-slider-track .addui-slider-handle:after {
    display: block;
    content: " ";
    width: 17px;
    height: 17px;
    background: var(--main-theme-color);
    border-radius: 25px;
    position: relative;
    top: 17px;
    left: 13px;
}

.addui-slider {
    display: block;
    box-sizing: border-box;
}

.br-theme-bars-square .br-widget a {
    display: block;
    float: left;
    background-color: white;
    margin: 2px;
    text-decoration: none;
    text-align: center;
    background: transparent !important;
    color: #111111 !important;
    border: 1px solid #D6D6D6 !important;
    border-radius: 10px;
    padding-top: 8px;
    width: 44px;
    height: 39px;
    font-size: 0.9rem !important;
}

a,
a:hover {
    text-decoration: none !important;
    color: inherit;
}

[type="checkbox"]:not(:checked),
[type="checkbox"]:checked {
    position: absolute;
    left: -9999px;
    opacity: 0;
}

.all-room-check:before {
    border: 1px dashed var(--main-theme-color);
    border-radius: 10px;
    padding: 0;
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: 0;
}

.w-h-50 {
    width: 55px;
    height: 55px;
}

.custom-control-label {
    padding-left: 10px;
}

.custom-control-label {
    margin-bottom: 0.5rem;
    padding-top: 0px;
}

.custom-control-label {
    padding-left: 5px;
}

.custom-control-label {
    position: relative;
    margin-bottom: 0;
    vertical-align: top;
}

label {
    display: inline-block;
    margin-bottom: 0.5rem;
}

label {
    cursor: default;
}

.custom-checkbox .custom-control-label::before {
    border-radius: 0.25rem;
}

.custom-control-label::before {
    width: 19px;
    height: 19px;
    border: var(--main-theme-color) solid 1px !important;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
    transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.ft-17 {
    font-size: 0.8625rem !important;
}

.bold {
    font-weight: 600 !important;
}

.custom-control-label::before {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #fff;
    border: #adb5bd solid 1px;
}

*,
::after,
::before {
    box-sizing: border-box;
}

.custom-control-label::after {
    width: 19px;
    height: 19px;
}

.custom-control-label::before {
    width: 19px;
    height: 19px;
    border: var(--main-theme-color) solid 1px !important;
}

.custom-control-label::before {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #fff;
    border: #adb5bd solid 1px;
}

.btn-flat {
    background: transparent !important;
    color: #111111 !important;
    border: 1px solid #D6D6D6;
}

.f-18 {
    font-size: 18px !important;
}

.m-1px {
    margin: 1px !important;
}

.waves-effect {
    color: #000;
    position: relative;
    cursor: pointer;
    overflow: hidden;
    padding-top: 10px !important;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    z-index: 1;
    font-size: 0.9rem !important;
    transition: .3s ease-out;
    padding: 0;
    margin: 2px;
    text-align: center;
    background: transparent !important;
    color: #111111 !important;
    border: 1px solid #D6D6D6 !important;
    border-radius: 10px !important;
    padding-top: 8px;
    width: 44px;
    height: 39px;
    -webkit-border-radius: 10px !important;
    -moz-border-radius: 10px !important;
    -ms-border-radius: 10px !important;
    -o-border-radius: 10px !important;
}

@media screen and (min-width: 900px) {
    .sortFilterLg {
        height: 50%;
        box-shadow: 0px 3px 12px #00000021;
        border: 1px solid #DCDCDC;
        border-radius: 9px;
        max-height: 30rem;
        -webkit-border-radius: 9px;
        -moz-border-radius: 9px;
        -ms-border-radius: 9px;
        -o-border-radius: 9px;
    }
}

.property_listing_container {
    display: flex;
}



.chkBoxTextN {
    margin-left: 10px !important;
}

.dottedLineResult {
    margin-bottom: 1.5px;
}

.pkr1 {
    border: 2px solid #ff3d00;
    border-radius: 10px;
    width: 80px;
    height: 30px;
    font-size: 12px;
    box-shadow: 1px 1px 1px rgb(205, 206, 206);
}

.priceRangeLP {
    margin-left: 20px;
}

.owl-carousel .owl-nav button.owl-prev,
.owl-carousel .owl-nav button.owl-next,
.owl-carousel button.owl-dot {
    margin-top: 30px;
}

.owl-theme-property-listing .owl-next {
    margin-top: 45px !important;
    width: 25px !important;
    height: 26px !important;
}

.owl-theme-property-listing .owl-prev {
    margin-top: 45px !important;
    width: 25px !important;
    height: 26px !important;
}