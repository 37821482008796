body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.signupBtn {
    width: 100%;
    height: 30px;
    font-size: 15px;
    color: #fff;
    background-color: #EF4E22;
    border: none;
    border-radius: 45px;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease 0s;
    cursor: pointer;
    outline: none;
}

button:focus {
    outline: none;
}

.signupBtn:focus {
    outline: none;
}

.signupBtn:hover {
    background-color: #fca527;
}

.regFrom p {
    overflow: hidden;
    text-align: center;
    font-size: 12px;
}

.regFrom p:before,
.regFrom p:after {
    background-color: #000;
    content: "";
    display: inline-block;
    height: .5px;
    position: relative;
    vertical-align: middle;
    width: 50%;
}

.regFrom p:before {
    right: 0.5em;
    margin-left: -50%;
}

.regFrom p:after {
    left: 0.5em;
    margin-right: -50%;
}

.GBtn,
.FBtn {
    width: 100%;
    height: 22px;
    font-size: 11px;
    letter-spacing: 1px;
    font-weight: 500;
    color: blue;
    background-color: #fff;
    transition: all 0.3s ease 0s;
    cursor: pointer;
    outline: none;
}

.aBtn a {
    color: #EF4E22;
    font-size: 9px;
}

.errMsg {
    text-transform: capitalize;
}

.personIcon {
    font-size: 100px;
    margin-left: 100px;
}

.signinSuccessIcons {
    font-size: 40px;
    margin-left: 140px;
}

.hotN {
  vertical-align: -webkit-baseline-middle;
}

.addressCont {
  font-size: 14px;
  margin-top: -3px;
}

.Button {
  width: 80px;
  height: 20px;
  font-size: 9px;
  font-weight: 500;
  color: #fff;
  background-color: #856ac5;
  border: none;
  border-radius: 45px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none;
}


.Button:hover {
  background-color: #fca527;
}

.revD {
  margin-top: -6px;
  margin-left: -8px;
}


.DSearchButton {
  position: fixed;
  top: 93%;
  left: 10%;
  width: 80%;
  height: 28px;
  font-size: 14px;
  font-family: Gotham Rounded Bold;
  color: #fff;
  margin: 0 auto;
  padding: 0;
  display: inline-block;
  line-height: 30px;
  text-align: center;
  background-color: #EF4E22;
  border: none;
  border-radius: 45px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none;
  z-index: 1000;
}

.DSearchButton:hover {
  background-color: #fca527;
}

.newFull {
  margin-left: 30px;
}



.facility {
  vertical-align: middle;
}

.facility1 {
  vertical-align: text-bottom;
}


.dropbtn {
  background-color: #fff;
  color: black;
  padding: 2px;
  padding-left: 10px;
  border-radius: 0px 10px 10px 10px;
  border: 1px solid rgb(205, 206, 206);
  box-shadow: 2px 2px 2px 2px rgb(205, 206, 206);
  font-size: 12px;
  border: none;
  border-radius: 15px;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 85px;
  border-radius: 10px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content button {
  color: black;
  text-align: center;
  border: none;
  padding-left: 50%;
  outline: none;
  font-family: Montserrat Regular;
  background: none;
  display: block;
}

/* .dropdown-content button:hover {
  background-color: #ddd;
} */

.dropdown:hover .dropdown-content {
  display: block;
}

/* .dropdown:hover .dropbtn {
  background-color: #b0b0b0;
} */

.dropbtn::after .dropdown-content {
  display: none;
}


#popover-basic {
  margin-top: 30px;
}

.topCitiesBtns {
  border: none;
  background: none;
  display: block;
  font-family: Montserrat Regular;
  color: #EF4E22;
}

.mapHeading {
  margin: -3px;
  padding: -3px;
}

.availableRoomsCont {
  padding: 0;
}

.reviewCont {
  height: 200px;
}

.PDPrice {
  font-size: 20px;
}

.locationHeading {
  margin-top: 15px;
  margin-left: 3px;
}

.vg {
  font-size: 14px;
}

.knowMore {
  margin: 0;
  padding: 0;
  font-size: 18px;
}

.knowMore {
  margin: 0;
  padding: 0;
  font-size: 18px;
}

.knowMore2 {
  font-size: 18px;
}

.roomNamePD {
  font-size: 16px;
}

.roomDetailsText {
  font-size: 10px;
}

.cancelText {
  font-size: 8px;
}

.cancelImage {
  width: 10px;
}

.maxText {
  font-size: 8px;
}

.priceRoomAvPD {
  font-size: 16px;
}

.knowMoreDesc {
  font-size: 13px;
}


.price {
  margin-top: 2px;
}

.roomBottom {
  padding-left: 15px;
  padding-right: 15px;
  margin-top: -20px;
}

.revText {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  /* number of lines to show */
  -webkit-box-orient: vertical;
}



.priceContain {
  margin-top: 0px;
}

.mapPic {
  color: #fff;
  /* margin-right: 18px; */
}

.mapp {
  background-image: linear-gradient(to right, rgba(6, 33, 82, .4), rgba(6, 33, 82, .4)), url(/static/media/map.b909908e.svg);
  background-repeat: no-repeat;
  background-size: cover;
  border: 1px solid rgb(205, 206, 206);
  border-radius: 25px 25px 25px 25px;
}

.iitem {
  width: 100%;
}

.ccaption {
  color: #fff;
  font-weight: 900;
  /* margin-right: 16px; */
  margin-top: -5px;
  height: 20px;
}

.CarouselImg {
  height: 200px;
}


.vAllBtn {
  margin-left: 15px;
}


/* Dropdown */


.dropdown {
  position: relative;
  display: inline-block;
  margin-left: 5px;
  white-space: nowrap;
}

.dropdown-btn {
  font-size: 12px;
  background: none;
  border-radius: 15px;
  border: 1px solid rgb(205, 206, 206);
  box-shadow: 2px 2px 2px 2px rgb(205, 206, 206);
  padding-left: 5px;
  height: 20px;
  cursor: pointer;
  font-family: Montserrat Regular;
}

.ddicon {
  padding-bottom: 3px;
}

.dropdown-btn:hover,
.dropdown-btn:focus {
  background: #f2f2f2;
}

.dropdown-items {
  position: absolute;
  font-family: Montserrat Regular;
  background-color: #f1f1f1;
  min-width: 85px;
  border-radius: 10px;
  margin-left: -5px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-item {
  padding-top: 0px;
  text-align: center;
  padding-bottom: 0px;
  cursor: pointer;
  font-family: Montserrat Regular;
}

.dropdown-item:not(first-child) {
  padding-top: 0px;
  padding-bottom: 0px;
}

.dropdown-item:not(last-child) {
  padding-top: 0px;
  padding-bottom: 0px;
}

.isVisible {
  visibility: visible;
}

.isHidden {
  visibility: hidden;
}
.thumbnail {
    position: relative;
    display: inline-block;
}

.fullBanner {
    padding: 8px;
}

.caption {
    position: absolute;
    top: 50px;
    padding: 4px;
    left: 0px;
    width: 110px;
    z-index: 2;
    border: 1px solid white;
    border-radius: 5px 30px 30px 5px;
    background-color: #fff;
}

.overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.4);
}

.container-fluid {
    position: relative !important;
}

.caption1 {
    position: absolute;
    width: 100vw;
    bottom: 0px;
    height: 40px;
    left: -13px;
    z-index: 2;
    background-color: rgba(6, 10, 6, 0.8);
    color: white;
    font-weight: bold;
}

.buttonsSFContainer {
    margin-top: 10px;
}

.filtButn {
    margin-top: 20px;
}


.resLoader {
    margin-bottom: 10px;
}

.caption img {
    width: 90px;
}

.imgWid0 {
    width: 3vw;
}

#popover-basic1 {
    margin-top: 30px;
}

@media screen and (min-width: 480px) {
    .fullBanner {
        padding: 10px;
        padding-left: 140px;
        padding-right: 80px;
    }

    .imgWid0 {
        width: 20px;
    }
}

.hotel-details-container {
    line-height: 1.2;
    white-space: inherit;
    padding-left: 0px !important;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    cursor: pointer;
}
.imgWidr {
    width: 23px;
}



.area {
    vertical-align: text-top;
}

.dfl {
    margin: 0 !important;
    padding: 0 !important;
}

/* .slick-slide img {
    display: inline-block;
    padding: 0;
    margin: 0;
} */


/* Arrows */

.slick-prev,
.slick-next {
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 47%;
    display: block;
    padding: 0;
    -webkit-transform: translate(50, -50%);
    transform: translate(50, -50%);
    cursor: pointer;
    color: transparent;
    border: none;
    outline: none;
    background: #fff;
    box-shadow: rgb(166, 167, 167) 0px 1px 2px 1px;
    -moz-transform: translate(50, -50%);
    -o-transform: translate(50, -50%);
}

.slick-prev:before,
.slick-next:before {
    font: normal normal normal 12px/1 FontAwesome;
    font-size: 10px;
    color: #ff3d00;
    display: flex;
    top: 50%;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}

.slick-prev {
    width: 20px;
    height: 20px;
    z-index: 999;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}

.slick-prev:before {
    background-image: url(/static/media/angle-left-solid.5eaae68d.svg);
    background-repeat: no-repeat;
    height: 9px;
    color: white;
    margin-top: 5.5px;
    margin-left: 7px;
}

.slick-next {
    width: 20px;
    height: 20px;
    z-index: 999;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}

.slick-next:before {
    background-image: url(/static/media/angle-right-solid.74227f01.svg);
    background-repeat: no-repeat;
    height: 11px;
    color: white;
    margin-top: 4.5px;
    margin-left: 7.5px;
}

.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
    color: transparent;
    outline: none;
    background: #fff;
}

.hotel-card {
    height: 13rem;
    box-sizing: border-box;
}
.sliderImgs{
    height: 13rem;
}
.h-45{
    height: 13rem !important;
}

.sliderImgs {
    width: 100%;
    cursor: pointer;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
.hotel-pricing-container-p {
    background-color: rgb(255, 51, 79);
    opacity: 0.8;
    color: #fff;
    padding: 2px 10px 2px 10px;
    border-radius: 20px;
    font-size: 10px;
    white-space: nowrap;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
}

@media screen and (min-width: 480px) {
    .hotel-pricing-container-p {
        font-size: 15px;
        /* padding-right: 10px; */
    }
}

.hotel-pricing-container-p1 {
    color: white;
    margin: 15px;
    z-index: 0;
    opacity: 0.1;
    height: 30px;
}

.very-good span {
    font-size: 15px;
    font-weight: 900;
}



.hotel-pricing-container {
    /* display: flex;
    justify-content: space-between;
    align-items: baseline; */
    /* padding: 0px;
    margin: 0px; */
    /* margin-top: -30px; */
}

@media screen and (min-width: 480px) {
    .very-good span {
        font-size: 15px;
        font-weight: 900;

    }

    .hotel-pricing-container {
        margin-top: 10px;
    }

    .very-good u {
        font-size: 13px;
        text-align: right;
    }
}
.ww{
    font-size: 20px;
    font-weight: 700;
}


.very-good {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-left: 5px;
    font-weight: 900;
    align-items: flex-end;
}

.user-rating-container {
    display: flex;
    flex-direction: row-reverse;
    /* margin-top: 15px; */
    display: flex;
    flex-direction: row-reverse;
    margin-bottom: 5px;
    /* margin-top: 15px; */
    align-items: flex-end;
}

.reviewCount {
    border-radius: 5px 5px 5px 5px ;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    padding: 10px;
    background-color: #F58D3C;
    justify-content: center;
    width: 32px;
    height: 32px;
    box-shadow: 0px 3px 4px 2px #e0e0e0;

    background-size: cover;
    font-size: 14px;
    color: #fff;
    font-weight: 900;
}

.imgwid4 {
    margin-left: -19px;
}

@media screen and (min-width: 480px) {
    .reviewCount {
        width: 42px;
        height: 42px;
        padding-top: 10px;
        font-size: 16px;
    }

}


.chattxt {
    margin-left: -29px;
}

@media screen and (min-width: 480px) {
    .chattxt {
        margin-top: -5px;
        line-height: 100%;
    }

    .getText {
        line-height: 0.9;
    }

    .searchLogo {
        margin-top: 5px;
        width: 100px;
    }

}

.imgwiddd {
    margin-top: 10px;
}

.chatBtn {
    border: none;
    background-color: #7ed34f;
    padding: 3px;
    margin-left: .1px;
    color: white;
    border-radius: 20px;
}

.para2 {
    font-size: 13px;
}

@media screen and (min-width: 480px) {
    .chatBtn {
        padding-left: 8px;
        padding-right: 8px;
    }


    .para2 {
        font-size: 18px;
    }

}

@media screen and (max-width: 480px) {
    .rev {
        font-size: 3.9vw;
    }

    .left {
        font-size: 3vw;
    }

    .rs {
        font-size: 3vw;
    }

    .free {
        font-size: 3vw;
    }

    .area {
        font-size: 3.9vw;
        vertical-align: text-top;
    }

    .imgWid1 {
        width: 12vw;
        vertical-align: text-top;
    }

    .chat {
        font-size: 2.5vw;
    }
}

.freepartTxt {
    font-size: 13px;
}


@media screen and (min-width: 480px) {
    .imgWid1 {
        width: 70px;
        margin-top: 12px;
    }

   
 
}

.para {
    font-size: 16px;
}

@media screen and (min-width: 480px) {
    .rs {
        font-size: 3vw;
        float: right;
    }
}

.VAL {
    vertical-align: text-bottom;
}




/* sort screen */

.side-popup,
.side-search-popup,
.side-gender-popup,
.filter-side-popup,
.sort-popup {
    /* right: -200%; */
    transition: all 0.5s linear;
}

.sort-popup-hide {
    right: -200%;
}

.sort-popup-show {
    right: 0;
}

.top-0 {
    top: 0;
}

.z-index-999 {
    z-index: 999;
}

.line-height-21 {
    line-height: 21px;
}

.ft-18 {
    font-size: 18px !important;
}

.z-index-999 {
    z-index: 999;
}



.border-radius {
    border-radius: 8px !important;
}

.border-orange {
    border-color: var(--main-theme-color) !important;
}

.text-orange {
    color: var(--main-theme-color);
}

.gr-medium {
    font-family: 'gothamroundedmedium' !important;
}

.sort-popup a {
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
}

.custom-control-label-sort {
    width: 85%;
}

.custom-control-label-sort:before,
.custom-control-label-sort:after {
    right: -1.5rem;
    left: unset;
    border-radius: 0 !important;
    color: #000 !important;
    border-color: transparent !important;
    background-color: transparent !important;
}

.custom-checkbox .custom-control-input-sort:checked~.custom-control-label-sort::after {
    background-image: url(https://www.roomph.pk/mobile/images/checked.svg);
    background-size: 100%;
}

.custom-control-label-sort:before,
.custom-control-label-sort:after {
    right: -1.5rem;
    left: unset;
    border-radius: 0 !important;
    color: #000 !important;
    border-color: transparent !important;
    background-color: transparent !important;
}
.range-slider{
    width: 100%;
}
.cityStar {
    font-size: 15px;
}

@media screen and (min-width: 480px) {
    .cityStar {
        font-size: 15px;
    }

    .chkbox1r {
        margin-left: -3px !important;
        margin-bottom: -5px !important;
    }
}

.priceRs{
    display: flex;
    /* flex-direction: row-reverse; */
    /* display: flex; */
    /* flex-direction: row-reverse; */
    align-items: flex-end;
    justify-content: flex-end;
    /* font-weight: 700; */

}
.priceRs1 {
    color: #FF334F;
}

.changeDatebtn {
    background-size: 13px;
    /* background-repeat: no-repeat; */
    font-size: 8px;
    border: 1px solid #b3b3b3;
    border-radius: 10px;
    padding: 5px;
    width: 100px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    text-align: center;
    display: block;
    flex-direction: column;
    background-color: rgba(230, 230, 230, 0.5);
    -webkit-border-radius: 10px;
    align-items: center;
    -moz-border-radius: 10px;

}

.changeDatebtn img{
    width: 20px;
}

@media screen and (min-width: 480px) {
    
    .changeDatebtn {
        font-size: 12px;
    }

}

.property_container {
    margin: auto;
    display: flex;
}

.destination-sidebar {
    box-shadow: 0px 3px 12px #00000021;
    border: 1px solid #DCDCDC;
    border-radius: 9px;
    padding-left: 21px;
    padding-right: 21px;
}

.ft-25 {
    font-size: 0.9rem !important;
}

.text-gray {
    color: #666666;
}

.star-rating {
    margin-bottom: 28px !important;
}

.mt-55 {
    margin-top: 55px;
}

.ft-21 {
    font-size: 1.2 !important;
}

.text-gray {
    color: #666666;
}




[type="checkbox"]:not(:checked),
[type="checkbox"]:checked {
    position: absolute;
    left: -9999px;
    opacity: 0;
}



.btn-flat {
    background: transparent !important;
    color: #111111 !important;
    border: 1px solid #D6D6D6;
}
.btn-orange {
    background: var(--main-theme-color) !important;
    color: #ffff !important;
    border: 1px solid var(--main-theme-color) !important;
    color: #000;
    position: relative;
    cursor: pointer;
    overflow: hidden;
    padding-top: 10px !important;
    -webkit-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    z-index: 1;
    font-size: 0.9rem !important;
    transition: .3s ease-out;
    padding: 0;
    margin: 2px;
    text-align: center;
    border-radius: 10px !important;
    padding-top: 8px;
    width: 44px;
    height: 39px;
    -webkit-border-radius: 10px !important;
    -moz-border-radius: 10px !important;
    -ms-border-radius: 10px !important;
    -o-border-radius: 10px !important;
}
/* .waves-effect {
    background: var(--main-theme-color);
    color: #fff;
    position: relative;
    cursor: pointer;
    display: inline-block;
    overflow: hidden;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    vertical-align: middle;
    z-index: 1;
    transition: .3s ease-out;
    width: 44px;
    height: 39px;
    border: 1px solid var(--main-theme-color);
    border-radius: 9px;
    padding: 0;
    line-height: 40px;
} */
.mb-28{
    margin-bottom: 28px !important;
}
.br-theme-bars-square .br-widget {
    height: 15px;
    white-space: nowrap;
}

.addui-slider-range {
    left: 0;
    width: 100%;
}

.f-13 {
    font-size: 13px;
}

.addui-slider.addui-slider-horizontal {
    height: 50px;
    width: calc(100% - 0px);
    margin: 0 auto 33px;
}

.addui-slider * {
    box-sizing: border-box;
}

.addui-slider.addui-slider-horizontal .addui-slider-track {
    height: 4px;
    width: 100%;
    top: 23px;
    left: 0;
}

.addui-slider .addui-slider-track {
    display: block;
    position: relative;
    background: #eee;
    border-radius: 4px;
}

.addui-slider * {
    box-sizing: border-box;
}

.addui-slider.addui-slider-horizontal .addui-slider-track .addui-slider-range {
    width: auto;
    height: 4px;
}

.addui-slider .addui-slider-track .addui-slider-range {
    display: block;
    background: var(--main-theme-color-opacity);
    border-radius: 4px;
    position: absolute;
}

.addui-slider.addui-slider-horizontal .addui-slider-track .addui-slider-handle {
    left: auto;
    top: -24px;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
}

.addui-slider .addui-slider-track .addui-slider-handle {
    display: block;
    width: 50px;
    height: 50px;
    background: rgba(63, 81, 181, 0);
    box-shadow: 0 0 5px rgb(63 81 181 / 0%);
    position: absolute;
    border-radius: 50px;
    cursor: pointer;
    transition: background 1s, box-shadow 1s;
    z-index: 0;
}

.addui-slider .addui-slider-track .addui-slider-handle .addui-slider-value {
    opacity: 1;
    margin-top: 20px;
}

.addui-slider .addui-slider-track .addui-slider-handle .addui-slider-value {
    display: block;
    float: right;
    border-radius: 50%;
    margin-top: 0px;
    color: #666666;
    pointer-events: none;
    position: absolute;
    z-index: 1;
}

.addui-slider * {
    box-sizing: border-box;
}

.addui-slider .addui-slider-track .addui-slider-handle:after {
    display: block;
    content: " ";
    width: 17px;
    height: 17px;
    background: var(--main-theme-color);
    border-radius: 25px;
    position: relative;
    top: 17px;
    left: 13px;
}

.addui-slider .addui-slider-track .addui-slider-handle .addui-slider-value span {
    display: block;
    width: 100%;
    height: 100%;
    text-align: center;
    line-height: 50px;
    position: absolute;
    font-family: 'roboto-light' !important;
    z-index: 1;
    color: #666666;
    margin-left: 13px;
}

.addui-slider * {
    box-sizing: border-box;
}

.addui-slider .addui-slider-track .addui-slider-handle .addui-slider-value span:after {
    content: 'k';
    position: relative;
    left: 0;
    top: 0;
    right: 0;
    margin: 0 auto;
    font-size: 13px;
}

.addui-slider.addui-slider-horizontal .addui-slider-track .addui-slider-handle {
    left: auto;
    top: -24px;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
}

.addui-slider .addui-slider-track .addui-slider-handle {
    display: block;
    width: 50px;
    height: 50px;
    background: rgba(63, 81, 181, 0);
    box-shadow: 0 0 5px rgb(63 81 181 / 0%);
    position: absolute;
    border-radius: 50px;
    cursor: pointer;
    transition: background 1s, box-shadow 1s;
    z-index: 0;
}

.addui-slider .addui-slider-track .addui-slider-handle .addui-slider-value {
    opacity: 1;
    margin-top: 20px;
}

.addui-slider .addui-slider-track .addui-slider-handle .addui-slider-value {
    display: block;
    float: right;
    border-radius: 50%;
    margin-top: 0px;
    color: #666666;
    pointer-events: none;
    position: absolute;
    z-index: 1;
}

.addui-slider * {
    box-sizing: border-box;
}

.addui-slider .addui-slider-track .addui-slider-handle:after {
    display: block;
    content: " ";
    width: 17px;
    height: 17px;
    background: var(--main-theme-color);
    border-radius: 25px;
    position: relative;
    top: 17px;
    left: 13px;
}

.addui-slider {
    display: block;
    box-sizing: border-box;
}

.br-theme-bars-square .br-widget a {
    display: block;
    float: left;
    background-color: white;
    margin: 2px;
    text-decoration: none;
    text-align: center;
    background: transparent !important;
    color: #111111 !important;
    border: 1px solid #D6D6D6 !important;
    border-radius: 10px;
    padding-top: 8px;
    width: 44px;
    height: 39px;
    font-size: 0.9rem !important;
}

a,
a:hover {
    text-decoration: none !important;
    color: inherit;
}

[type="checkbox"]:not(:checked),
[type="checkbox"]:checked {
    position: absolute;
    left: -9999px;
    opacity: 0;
}

.all-room-check:before {
    border: 1px dashed var(--main-theme-color);
    border-radius: 10px;
    padding: 0;
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: 0;
}

.w-h-50 {
    width: 55px;
    height: 55px;
}

.custom-control-label {
    padding-left: 10px;
}

.custom-control-label {
    margin-bottom: 0.5rem;
    padding-top: 0px;
}

.custom-control-label {
    padding-left: 5px;
}

.custom-control-label {
    position: relative;
    margin-bottom: 0;
    vertical-align: top;
}

label {
    display: inline-block;
    margin-bottom: 0.5rem;
}

label {
    cursor: default;
}

.custom-checkbox .custom-control-label::before {
    border-radius: 0.25rem;
}

.custom-control-label::before {
    width: 19px;
    height: 19px;
    border: var(--main-theme-color) solid 1px !important;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
    transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.ft-17 {
    font-size: 0.8625rem !important;
}

.bold {
    font-weight: 600 !important;
}

.custom-control-label::before {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #fff;
    border: #adb5bd solid 1px;
}

*,
::after,
::before {
    box-sizing: border-box;
}

.custom-control-label::after {
    width: 19px;
    height: 19px;
}

.custom-control-label::before {
    width: 19px;
    height: 19px;
    border: var(--main-theme-color) solid 1px !important;
}

.custom-control-label::before {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #fff;
    border: #adb5bd solid 1px;
}

.btn-flat {
    background: transparent !important;
    color: #111111 !important;
    border: 1px solid #D6D6D6;
}

.f-18 {
    font-size: 18px !important;
}

.m-1px {
    margin: 1px !important;
}

.waves-effect {
    color: #000;
    position: relative;
    cursor: pointer;
    overflow: hidden;
    padding-top: 10px !important;
    -webkit-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    z-index: 1;
    font-size: 0.9rem !important;
    transition: .3s ease-out;
    padding: 0;
    margin: 2px;
    text-align: center;
    background: transparent !important;
    color: #111111 !important;
    border: 1px solid #D6D6D6 !important;
    border-radius: 10px !important;
    padding-top: 8px;
    width: 44px;
    height: 39px;
    -webkit-border-radius: 10px !important;
    -moz-border-radius: 10px !important;
    -ms-border-radius: 10px !important;
    -o-border-radius: 10px !important;
}

@media screen and (min-width: 900px) {
    .sortFilterLg {
        height: 50%;
        box-shadow: 0px 3px 12px #00000021;
        border: 1px solid #DCDCDC;
        border-radius: 9px;
        max-height: 30rem;
        -webkit-border-radius: 9px;
        -moz-border-radius: 9px;
        -ms-border-radius: 9px;
        -o-border-radius: 9px;
    }
}

.property_listing_container {
    display: flex;
}



.chkBoxTextN {
    margin-left: 10px !important;
}

.dottedLineResult {
    margin-bottom: 1.5px;
}

.pkr1 {
    border: 2px solid #ff3d00;
    border-radius: 10px;
    width: 80px;
    height: 30px;
    font-size: 12px;
    box-shadow: 1px 1px 1px rgb(205, 206, 206);
}

.priceRangeLP {
    margin-left: 20px;
}

.owl-carousel .owl-nav button.owl-prev,
.owl-carousel .owl-nav button.owl-next,
.owl-carousel button.owl-dot {
    margin-top: 30px;
}

.owl-theme-property-listing .owl-next {
    margin-top: 45px !important;
    width: 25px !important;
    height: 26px !important;
}

.owl-theme-property-listing .owl-prev {
    margin-top: 45px !important;
    width: 25px !important;
    height: 26px !important;
}
.bookingIdText {
    font-size: 12px;
}

@media screen and (min-width: 900px) {
    .bookingIdText {
        margin-top: 20px;
        font-size: 17px;
    }

    .bookingContainerTY {
        margin-left: 100px;
    }

    .TYCont {
        width: 86% !important;
        padding-top: 20px !important;
        padding-left: 20px !important;
        margin-bottom: 30px;
    }
}
.profileHeading {
    margin-bottom: 0;
    font-size: 18px;
}

.profileText {
    font-size: 14px;
    margin-bottom: 5px;
}

.hrLine {
    color: #A9A8AD;
    background-color: #A9A8AD;
    border-color: #A9A8AD;
    margin-top: 5px;
}

.profileIconP {
    color: #BBBBBE;
    font-size: 70px;
    margin-top: 10px;
}

.verified {
    font-size: 14px;
    margin-left: 10px;
    margin-bottom: 10px;
    padding: 2px 5px;
    color: #fff;
    background-color: #007234;
    border: none;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease 0s;
    cursor: pointer;
    outline: none;
}
/* ****************************************************************************
Common Css Elements
**************************************************************************** */
/* @font-face {
    font-family: Poppins;
    src: url(./Assests/gotham-rounded-book/Gotham\ Rounded\ Book.otf);
} */

@font-face {
    font-family: Poppins;
    src: url(/static/media/Poppins-Regular.8b6af8e5.ttf);
}

/* 
@font-face {
    font-family: PoppinsBold;
    src: url(./Assests/gotham-rounded-book/Gotham\ Bold.otf);
} */
@font-face {
    font-family: PoppinsBold;
    src: url(/static/media/Poppins-Bold.a3e0b5f4.ttf);
}

/* @font-face {
    font-family: PoppinssemiBold;
    src: url(./Assests/gotham-rounded-book/Gotham\ Rounded\ Medium.otf);
} */
@font-face {
    font-family: PoppinssemiBold;
    src: url(/static/media/Poppins-SemiBold.4cdacb8f.ttf);
}

/* ****************************************************************************
fonts size Css Elements
**************************************************************************** */
.max {
    font-family: "Poppins" !important;
    text-transform: capitalize;
}


.f-12 {
    font-size: 0.95rem !important;
}

.f-13 {
    font-size: 1.2rem !important;
}

.f-14 {
    font-size: 1.1rem !important;
}

.f-15 {
    font-size: 1.6rem !important;
}

.f-30 {
    font-size: 2.3rem !important;
}

.f-25 {
    font-size: 2rem !important;
}

.underliene {
    position: relative;
}

.underliene::after {
    content: '';
    width: 8rem;
    border-bottom: 1px solid #FF8500;
    border-bottom: 1px solid var(--main-theme-color);
    display: block;
    margin: 0.3em auto 0;
}

.offer-slider-corner {
    position: absolute;
    width: 8.5rem;
    height: 8.5rem;
    padding: 50px !important;
    background-color: #FCFCFC;
    background-color: var(--white);
    top: -18px;
    left: calc(74% / 2);
    transform: rotate(45deg) !important;
    border-radius: 50% 50% 0% 50%;
    -webkit-transform: rotate(45deg) !important;
    -moz-transform: rotate(45deg) !important;
    -ms-transform: rotate(45deg) !important;
    -o-transform: rotate(45deg) !important;
    -webkit-border-radius: 50% 50% 0% 50%;
    -moz-border-radius: 50% 50% 0% 50%;
    -ms-border-radius: 50% 50% 0% 50%;
    -o-border-radius: 50% 50% 0% 50%;
}

.offer-per {
    width: 7.5rem;
    height: 7.5rem;
    position: absolute;
    top: -12px;
    left: calc(77% / 2);
    text-align: center;
    border-radius: 50%;
    background-color: #FF8500;
    background-color: var(--main-theme-color);
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}

.offer-per-inner {
    font-size: 2.4rem;
    font-family: PoppinssemiBold !important;
    text-shadow: 2px 2px rgba(0, 0, 0, 0.5);

}

.offer-per-inner2 {
    font-size: 1.5rem;
    font-family: Poppins !important;
    line-height: 0;
}

.searchheaderBox {
    box-shadow: 0px 1px 2px 1px #cccccc;
    background-color: #fff;
    position: relative;
    z-index: 2;
}

a.heading {
    color: black !important;
}

.cardprice {
    margin-top: 20% !important;
}

.App {
    position: relative;
    background-color: #EFECEC;
    background-color: var(--secondary-color);
    /* background-image:url("Assests/backdrop.png"); */
    z-index: 1;

}

.backdrop {
    background-image: url(/static/media/backdrop.f816e480.png);

}


.z-index-0 {
    z-index: 0 !important;
}

.z-index-1 {
    z-index: 1 !important;
}

.z-index-2 {
    z-index: 2 !important;
}

.backimage {
    height: 100%;
    width: 100%;
    position: absolute;
}

:root {
    --primary-color: #FF8500;
    --secondary-color: #EFECEC;
    --hover-color: #fca527;
    /* --text-color: #a0a0a0; */
    --text-color: #3b3b3b;
    --hero-image-overlay: #052355;
    --main-theme-color: #FF8500;
    --main-theme-color-opacity: rgb(239 78 34 / 0.7);
    --main-theme-color-opacity-2: rgb(238 77 32 / 0.2);
    --whats-app: rgb(126, 211, 79);
    --checkbox-size: 18px;
    --white: #FCFCFC;
    --policy-free: #44B55B;
    --policy-non-refundable: #EB4D21;
}

.terms-container ul li {
    color: #3b3b3b !important;
    color: var(--text-color) !important;
}

/*  */
/* .rbt-menu>.dropdown-item {
    color: rgb(147, 148, 149) !important;
    font-family: "Poppins" !important;
} */
.roomname {
    background-color: #FF8500;
    background-color: var(--main-theme-color);
    color: #fff;
    font-size: 1rem !important;
    position: absolute;
    z-index: 2;
    border-radius: 20px 0px 0px 20px;
    padding: 0.3rem 1rem;
    box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, .25);
    top: 50px;
    right: 0px;
    -webkit-border-radius: 20px 0px 0px 20px;
    -moz-border-radius: 20px 0px 0px 20px;
    -ms-border-radius: 20px 0px 0px 20px;
    -o-border-radius: 20px 0px 0px 20px;
}



.policy-span {
    background-color: #EB4D21;
    background-color: var(--policy-non-refundable);
    padding-left: 0.6rem;
    padding-right: 0.6rem;
    font-size: 0.7rem !important;
    font-family: "Poppins" !important;
    padding-top: 0.1rem;
    padding-bottom: 0.1rem;
    cursor: pointer;
    color: #fff;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}

.policy-span-free {
    background-color: #44B55B;
    background-color: var(--policy-free);
    padding-left: 0.6rem;
    padding-right: 0.6rem;
    font-size: 0.7rem !important;
    font-family: "Poppins" !important;
    padding-top: 0.1rem;
    padding-bottom: 0.1rem;
    cursor: pointer;
    color: #fff;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}

.css-qupgtr {
    top: 35% !important;
}

.form-control {
    color: rgb(147, 148, 149) !important;
    font-family: "Poppins" !important;
}

body {
    font-family: 'Poppins';
    width: 100%;
    min-height: 100%;
    overflow-x: hidden;
}

.mtt-3 {
    margin-top: 2rem !important;
}

.mbb-3 {
    margin-bottom: 2rem !important;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Poppins";
}

.hotel {
    font-size: 20px;
    font-family: "PoppinsBold" !important;
    line-height: 1.4;
}

.container {
    zoom: 90% !important;
}

.position-relative {
    position: relative !important;
}

.icon-bar {
    background-color: #21CD49;
    width: 3.9rem;
    height: 3.9rem;
    color: #fff;
    font-size: 2.3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}

.searchcity {

    z-index: 0;
    padding: 0px !important;
    width: 0% !important;

}

.searchcityname {
    font-size: 25px;
    padding-top: 10px;
}

.icon-bar {
    position: fixed;
    bottom: 5%;
    right: 3%;
    z-index: 100;


}

.icon-bar a {
    display: block;
    text-align: center;
    padding: 10px;
    transition: all 0.3s ease;
    color: white;
    font-size: 20px;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
}

.icon-bar a:hover {
    background-color: none;
}

.whatsapp {
    color: white;
    width: 100px;
}


.searchtext {
    font-size: 0.9rem;
    font-family: "PoppinssemiBold" !important;
    padding: 0px !important;
    color: #000;
}

.searchicon {
    color: #EA7F08;
    margin-right: 13px;

}

.searchbox {
    width: 80%;
}

.searchcard {
    margin-left: 15px;
}

/* .searchin {
    margin-left: 10px;
    line-height: 16px;
} */

.search {
    display: flex;
    flex-wrap: wrap;
    width: 100% !important;
    padding-top: 10px;
    padding-bottom: 10px;
    align-items: center;

}

.searchguest {
    display: flex;
    margin: 10px 10px 10px 11px;
    width: 100% !important;
}

.container-3 {
    width: 60% !important;
    margin: 0 auto !important;
}

.display-flex {
    display: flex;
}

.j-between {
    justify-content: space-between;
}

.item-center {
    align-items: center;
}

.btn {
    font-size: 0.85rem !important;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out !important;
    -webkit-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out !important;
    -moz-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out !important;
    -ms-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out !important;
    -o-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out !important;
}

.back-primary {
    background-color: #FF8500 !important;
    background-color: var(--primary-color) !important;
}

.btn-topbar-primary {
    padding: 1.3px 0px !important;
    width: 6.4rem !important;
    height: 2.3rem !important;
    background-color: #FF8500 !important;
    background-color: var(--primary-color) !important;
    border: 1px solid #FF8500 !important;
    border: 1px solid var(--primary-color) !important;
    border-radius: 45px !important;
    font-family: Poppins !important;
    color: #EFECEC !important;
    color: var(--secondary-color) !important;
    outline: none !important;
    box-shadow: 0px 1px 2px 1px #cccccc !important
}

.btn-terms {
    padding: 1.3px 15px !important;
    width: 7.4rem !important;
    margin-bottom: 10px;
    height: 2.3rem !important;
    background-color: #FF8500 !important;
    background-color: var(--primary-color) !important;
    border: 1px solid #FF8500 !important;
    border: 1px solid var(--primary-color) !important;
    border-radius: 45px !important;
    font-family: Poppins !important;
    color: #EFECEC !important;
    color: var(--secondary-color) !important;
    outline: none !important;
    box-shadow: 0px 1px 2px 1px #cccccc !important
}

.btn-calltoaction {
    padding: 10px 30px 10px 30px !important;
    background-color: #FF8500 !important;
    background-color: var(--primary-color) !important;
    border: 1px solid #FF8500 !important;
    border: 1px solid var(--primary-color) !important;
    border-radius: 45px !important;
    font-weight: 900;
    margin-top: 12px;
    width: 30% !important;
    font-family: Poppins !important;
    color: #fff !important;
    outline: none !important;
}

.calltoaction-text {
    margin-top: 12px;
}

.btn-topBar-secondary:hover,
.btn-topbar-primary:hover,
.btn-topbar-primary-outline:hover {
    background-color: #fca527 !important;
    background-color: var(--hover-color) !important;
    border: 1px solid #fca527 !important;
    border: 1px solid var(--hover-color) !important;
    border-radius: 45px !important;
    font-family: Poppins !important;
    color: #EFECEC !important;
    color: var(--secondary-color) !important;

}

.btn-topbar-primary-outline {
    padding: 1.3px 0px !important;
    width: 6.4rem !important;
    height: 2.3rem !important;
    background-color: #EFECEC !important;
    background-color: var(--secondary-color) !important;
    border: 1px solid #FF8500 !important;
    border: 1px solid var(--primary-color) !important;
    border-radius: 45px !important;
    font-family: Poppins !important;
    color: #FF8500 !important;
    color: var(--primary-color) !important;
    outline: none !important;
}

.w-60 {
    width: 55%;
}

.btn-primary-2 {
    padding: 10px 10px !important;
    background-color: #FF8500 !important;
    background-color: var(--primary-color) !important;
    border: 1px solid #FF8500 !important;
    border: 1px solid var(--primary-color) !important;
    border-radius: 45px !important;
    font-family: Poppins !important;
    color: #fff !important;
    font-weight: lighter !important;
    outline: none !important;
    text-transform: uppercase;
    -webkit-border-radius: 45px !important;
    -moz-border-radius: 45px !important;
    -ms-border-radius: 45px !important;
    -o-border-radius: 45px !important;
}

.btn-primary-3 {
    padding: 7px 15px !important;
    background-color: #FF8500 !important;
    background-color: var(--primary-color) !important;
    border: 1px solid #FF8500 !important;
    border: 1px solid var(--primary-color) !important;
    border-radius: 45px !important;
    font-family: Poppins !important;
    color: #fff !important;
    font-weight: lighter !important;
    outline: none !important;
    text-transform: uppercase;
    -webkit-border-radius: 45px !important;
    -moz-border-radius: 45px !important;
    -ms-border-radius: 45px !important;
    -o-border-radius: 45px !important;
}


.chat-button {
    padding: 1.3px 9px !important;
    background-color: rgb(126, 211, 79) !important;
    background-color: var(--whats-app) !important;
    border: 1px solid rgb(126, 211, 79) !important;
    border: 1px solid var(--whats-app) !important;
    border-radius: 45px !important;
    font-family: Poppins !important;
    color: #EFECEC !important;
    color: var(--secondary-color) !important;
    outline: none !important;
    font-size: 1rem;
}

.contact-whats {
    width: 100%;
}

.btn-topBar-secondary {
    padding: 1.3px 0px !important;
    width: 6.4rem !important;
    height: 2.3rem !important;
    background-color: #EFECEC !important;
    background-color: var(--secondary-color) !important;
    border: 1px solid #EFECEC !important;
    border: 1px solid var(--secondary-color) !important;
    border-radius: 45px !important;
    font-family: Poppins !important;
    color: black !important;
    outline: none !important;
    box-shadow: 0px 1px 2px 1px #cccccc !important
}

.pHieT .ellipsis {
    text-align: left !important;
}

.btn-medium {
    width: 6rem;
    height: 2.2rem;
}

.cursor {
    cursor: pointer;
}

.featuredImg {
    height: 400px !important;
    width: 100vw;
    position: relative !important;
    background-attachment: fixed;
    background-size: cover;
}

.btn-white {
    padding: 1.3px 18px !important;
    background-color: #EFECEC !important;
    background-color: var(--secondary-color) !important;
    border: 1px solid #EFECEC !important;
    border: 1px solid var(--secondary-color) !important;
    border-radius: 45px !important;
    font-weight: 600;
    font-family: Poppins !important;
    color: #FF8500 !important;
    color: var(--primary-color) !important;
}

.btn-white:hover {
    background-color: #FF8500 !important;
    background-color: var(--primary-color) !important;
    border: 1px solid #FF8500 !important;
    border: 1px solid var(--primary-color) !important;
    border-radius: 45px !important;
    font-family: Poppins !important;
    color: #EFECEC !important;
    color: var(--secondary-color) !important;
    -webkit-border-radius: 45px !important;
    -moz-border-radius: 45px !important;
    -ms-border-radius: 45px !important;
    -o-border-radius: 45px !important;
}

.btn-primary:hover,
.btn-primary-2:hover {
    background-color: #fca527 !important;
    background-color: var(--hover-color) !important;
    border: 1px solid #fca527 !important;
    border: 1px solid var(--hover-color) !important;
    border-radius: 45px !important;
    font-family: Poppins !important;
    color: #FCFCFC !important;
    color: var(--white) !important;
}

.percentage-container {
    background-color: #EFECEC;
    background-color: var(--secondary-color);
    border-radius: 50%;
    width: 6rem;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 6rem;
    color: #000;
    font-size: 20px;
    color: #FF8500;
    color: var(--main-theme-color);
    font-weight: 900;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}

.btn-primary-seach {
    background-color: #FF8500 !important;
    background-color: var(--primary-color) !important;
    border: 1px solid #FF8500 !important;
    border: 1px solid var(--primary-color) !important;
    border-radius: 45px !important;
    box-shadow: 0px 0px 6px 1px #cfd1d2 !important;
    color: #fff !important;
    padding: 10px 15px !important;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out !important;

}

.btn-bookin-seach {
    background-color: #FF8500 !important;
    background-color: var(--primary-color) !important;
    border: 1px solid #FF8500 !important;
    border: 1px solid var(--primary-color) !important;
    border-radius: 45px !important;
    font-family: PoppinssemiBold !important;
    color: #EFECEC !important;
    color: var(--secondary-color) !important;
    padding: 10px 117px !important;
    font-size: 20px !important;
    font-weight: 900;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out !important;

}

.btn-form-seach {
    background-color: #FF8500 !important;
    background-color: var(--primary-color) !important;
    border: 1px solid #FF8500 !important;
    border: 1px solid var(--primary-color) !important;
    border-radius: 45px !important;
    font-family: PoppinssemiBold !important;
    color: #EFECEC !important;
    color: var(--secondary-color) !important;
    padding: 10px 95px !important;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out !important;

}

.btn-cancel {
    background-color: #fff;
    border-radius: 45px !important;
    font-family: PoppinssemiBold !important;
    color: #000;
    padding-left: 22px !important;
    padding-right: 22px !important;
    box-shadow: 1px 1px 1px 2px #e4e4e4;
    margin-left: 12px;
    text-align: end;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out !important;

}

.booking-progress {
    background-color: #828181;
    border-radius: 0px 0px 35px 35px;
    border-bottom: 1px solid rgb(205, 206, 206);
}

.confirm-section {
    text-align: end;
}

.btn-confirm {
    background-color: #F58D3C !important;
    border: 1px solid #FF8500 !important;
    border: 1px solid var(--primary-color) !important;
    border-radius: 45px !important;
    font-family: PoppinssemiBold !important;
    color: #fff !important;
    padding-left: 40px !important;
    padding-right: 40px !important;
    margin-left: 10px;
    text-align: end;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out !important;

}

.revieicon {
    color: #FF8500;
    color: var(--main-theme-color);
    padding-left: 7px;
    line-height: 0.8;
}

.Primary-color {
    color: #FF8500 !important;
    color: var(--primary-color) !important;
}

.black-color {
    color: #000;
}

.btn-primary {
    background-color: #FF8500 !important;
    background-color: var(--primary-color) !important;
    border: 1px solid #FF8500 !important;
    border: 1px solid var(--primary-color) !important;
    border-radius: 45px !important;
    font-family: PoppinssemiBold !important;
    color: #FCFCFC !important;
    color: var(--white) !important;
    padding: 5px 40px !important;
    font-weight: lighter !important;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out !important;
}

.btn-primary-seach:hover {
    background-color: #fca527 !important;
    background-color: var(--hover-color) !important;
    border: 1px solid #fca527 !important;
    border: 1px solid var(--hover-color) !important;
    border-radius: 45px !important;
    font-family: Poppins !important;
}

.btn-secondary:hover {
    background-color: #fca527 !important;
    background-color: var(--hover-color) !important;
    border: 1px solid #fca527 !important;
    border: 1px solid var(--hover-color) !important;
    border-radius: 45px !important;
    font-family: Poppins !important;
    color: #EFECEC !important;
    color: var(--secondary-color) !important;

}

.swal2-styled.swal2-confirm {
    border: 0;
    border-radius: 45px !important;
    background: initial;
    background-color: #FF8500 !important;
    background-color: var(--primary-color) !important;
    color: #fff;
    font-size: 1em;
    padding: 8px 25px !important;

}

.swal2-styled.swal2-confirm:focus {
    box-shadow: 0 0 0 3px rgb(252, 165, 39/ 50%) !important;
}

.btn-secondary {
    background-color: #EFECEC !important;
    background-color: var(--secondary-color) !important;
    border: 1px solid #EFECEC !important;
    border: 1px solid var(--secondary-color) !important;
    border-radius: 45px !important;
    font-family: Poppins !important;
    color: #FF8500 !important;
    color: var(--primary-color) !important;
    padding: 1.3px 15px !important;

}

.f-10 {
    font-size: 0.95rem !important;
}

.f-9 {
    font-size: 0.79rem !important;
}

.f-20 {
    font-size: 20px;
}

.f-20 {
    font-size: 22px;
}

.f-25 {
    font-size: 25px;
}

.bg-dark {
    background-color: #000;
}

.main-heading {
    -webkit-animation: fadein 1s;
            animation: fadein 1s;
    font-size: 1.4rem;
    margin-bottom: 4px;
    font-family: "PoppinsBold" !important;
    margin-top: 0;
    overflow: hidden;
}

.text-capitalize {
    text-transform: capitalize;
}

.aligned a,
.cities a {
    color: black;
    font-family: PoppinsBold !important;
    text-transform: capitalize;
    font-size: 17px;
}

hr {
    color: #A9A8AD;
    border-color: #A9A8AD;
}

.propert-text {
    color: #000 !important;
}

.sub-heading {
    font-size: 1.2rem;
    margin-top: 0;
    color: #000;
    font-family: PoppinsBold !important;
}

.fouth-heading {
    font-family: PoppinsBold !important;

}

.box-shadow-2 {
    /* box-shadow: 0px 0px 16px #3333333d; */
    box-shadow: 1px 1px 5px 2px #d3d1d1;
}

.box-shadow-1 {
    box-shadow: -1px 1px 2px 1px rgba(0, 0, 0, 0.4);
}

.box-shadow-3 {
    box-shadow: rgb(205 206 206) 1px 1px 1px 1px;

}

.box-shadow-4 {
    box-shadow: rgb(205 206 206) -1px 1px 1px 1px;

}

.border-bottom-form {
    border-bottom: 1px solid #919191 !important;

}

.form-control-sm {
    border-radius: 20px !important;
    border: 1px solid #919191 !important;
    padding: 20px !important;
    color: black !important;
    -webkit-border-radius: 10px !important;
    -moz-border-radius: 10px !important;
    -ms-border-radius: 10px !important;
    -o-border-radius: 10px !important;
}

.form-select {
    border-radius: 20px !important;
    border: 1px solid #919191 !important;
    padding: 12px !important;
    color: #788088 !important;
    -webkit-border-radius: 10px !important;
    -moz-border-radius: 10px !important;
    -ms-border-radius: 10px !important;
    -o-border-radius: 10px !important;
    font-size: 12px !important;


}

.form-control-sm::-webkit-input-placeholder {
    color: #788088 !important;
    font-size: 12px;
    padding-left: 0px !important;

}

.form-control-sm::placeholder {
    color: #788088 !important;
    font-size: 12px;
    padding-left: 0px !important;

}

.form-select::-webkit-input-placeholder {
    color: #788088 !important;
    font-size: 12px;
    padding-left: 20px !important;

}

.form-select::placeholder {
    color: #788088 !important;
    font-size: 12px;
    padding-left: 20px !important;

}

.form-control-md {
    border-radius: 20px !important;
    border: 1px solid rgb(203, 203, 203) !important;
    box-shadow: rgb(205 206 206) 1px 1px 1px 1px !important;
    color: black !important;
    -webkit-border-radius: 20px !important;
    -moz-border-radius: 20px !important;
    -ms-border-radius: 20px !important;
    -o-border-radius: 20px !important;
}

.form-control-md::-webkit-input-placeholder {
    color: grey !important;
}

.form-control-md::placeholder {
    color: grey !important;
}


/*animations*/
.explore-back {
    background-image: url(/static/media/Png.3c41e37a.png);
    background-attachment: fixed;
}


/* .explore-back{
    background-image:url("Assests/backdrop.png");

}
.backdrop{
    background-image:url("Assests/11.png");
}*/
.site-footer {
    font-size: 15px;
    line-height: 24px;
    z-index: 0;
}

/******************
* Bounce in right *
*******************/


.animated {
    -webkit-animation-duration: 0.3s;
    animation-duration: 0.3s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

.slow {
    -webkit-animation-duration: 1.5s;
    animation-duration: 1.5s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

.owl-carousel {
    z-index: 0 !important;
}

.searchbox-backdrop {
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 2 !important;
    display: none;
    transition: all 0.5s linear;
}

.w-75 {
    width: 75% !important;
}


.qty input:disabled {
    background-color: white;
}

.qty input {
    border: 0;
    width: 2%;
}

.qty input {
    color: #000;
    display: inline-block;
    vertical-align: top;
    line-height: 25px;
    padding: 0px 10px;
    min-width: 55px;
    text-align: center;
}


.plus,
.child-plus,
.room-plus {
    cursor: pointer;
    display: inline-block;
    width: 20px;
    height: 20px;
    text-align: center;
    line-height: 20px;
    color: #FB8509;
    border: 1px solid;
    border-radius: 100px;
}

.minus,
.child-minus,
.room-minus {
    cursor: pointer;
    display: inline-block;
    width: 20px;
    height: 20px;
    text-align: center;
    line-height: 20px;
    color: #FB8509;
    border: 1px solid;
    border-radius: 100px;
}

.slower {
    -webkit-animation-duration: 2s;
    animation-duration: 2s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

.slowest {
    -webkit-animation-duration: 4s;
    animation-duration: 4s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

.bounceInRight,
.bounceInLeft,
.bounceInUp,
.bounceInDown {
    opacity: 0;
    -webkit-transform: translateX(50px);
    transform: translateX(50px);
}

.fadeInRight,
.fadeInLeft,
.fadeInUp,
.fadeInDown {
    opacity: 0;
    -webkit-transform: translateX(50px);
    transform: translateX(50px);
}

.flipInX,
.flipInY,
.rotateIn,
.rotateInUpLeft,
.rotateInUpRight,
.rotateInDownLeft,
.rotateDownUpRight,
.rollIn {
    opacity: 0;
}

.lightSpeedInRight,
.lightSpeedInLeft {
    opacity: 0;
    -webkit-transform: translateX(50px);
    transform: translateX(50px);
}

/***********
* bounceIn *
************/
@-webkit-keyframes bounceIn {
    0% {
        opacity: 0;
        -webkit-transform: scale(.3);
    }

    50% {
        opacity: 1;
        -webkit-transform: scale(1.05);
    }

    70% {
        -webkit-transform: scale(.9);
    }

    100% {
        -webkit-transform: scale(1);
    }
}

@keyframes bounceIn {
    0% {
        opacity: 0;
        -webkit-transform: scale(.3);
                transform: scale(.3);
    }

    50% {
        opacity: 1;
        -webkit-transform: scale(1.05);
                transform: scale(1.05);
    }

    70% {
        -webkit-transform: scale(.9);
                transform: scale(.9);
    }

    100% {
        -webkit-transform: scale(1);
                transform: scale(1);
    }
}

.bounceIn.go {
    -webkit-animation-name: bounceIn;
    animation-name: bounceIn;
}

/****************
* bounceInRight *
****************/

@-webkit-keyframes bounceInRight {
    0% {
        opacity: 0;

        -webkit-transform: translateX(50px);
    }

    60% {

        -webkit-transform: translateX(-30px);
    }

    80% {
        -webkit-transform: translateX(10px);
    }

    100% {
        opacity: 1;

        -webkit-transform: translateX(0);
    }
}

@keyframes bounceInRight {
    0% {
        opacity: 0;

        -webkit-transform: translateX(50px);

                transform: translateX(50px);
    }

    60% {

        -webkit-transform: translateX(-30px);

                transform: translateX(-30px);
    }

    80% {
        -webkit-transform: translateX(10px);
                transform: translateX(10px);
    }

    100% {
        opacity: 1;

        -webkit-transform: translateX(0);

                transform: translateX(0);
    }
}


.bounceInRight.go {
    -webkit-animation-name: bounceInRight;
    animation-name: bounceInRight;
}

/******************
* Bounce in left *
*******************/

@-webkit-keyframes bounceInLeft {
    0% {
        opacity: 0;

        -webkit-transform: translateX(-50px);
    }

    60% {

        -webkit-transform: translateX(30px);
    }

    80% {
        -webkit-transform: translateX(-10px);
    }

    100% {
        opacity: 1;

        -webkit-transform: translateX(0);
    }
}

@keyframes bounceInLeft {
    0% {
        opacity: 0;

        -webkit-transform: translateX(-50px);

                transform: translateX(-50px);
    }

    60% {

        -webkit-transform: translateX(30px);

                transform: translateX(30px);
    }

    80% {
        -webkit-transform: translateX(-10px);
                transform: translateX(-10px);
    }

    100% {
        opacity: 1;

        -webkit-transform: translateX(0);

                transform: translateX(0);
    }
}

.bounceInLeft.go {
    -webkit-animation-name: bounceInLeft;
    animation-name: bounceInLeft;
}

/******************
* Bounce in up *
*******************/

@-webkit-keyframes bounceInUp {
    0% {
        opacity: 0;

        -webkit-transform: translateY(50px);
    }

    60% {

        -webkit-transform: translateY(-30px);
    }

    80% {
        -webkit-transform: translateY(10px);
    }

    100% {
        opacity: 1;

        -webkit-transform: translateY(0);
    }
}

@keyframes bounceInUp {
    0% {
        opacity: 0;

        -webkit-transform: translateY(50px);

                transform: translateY(50px);
    }

    60% {

        -webkit-transform: translateY(-30px);

                transform: translateY(-30px);
    }

    80% {
        -webkit-transform: translateY(10px);
                transform: translateY(10px);
    }

    100% {
        opacity: 1;

        -webkit-transform: translateY(0);

                transform: translateY(0);
    }
}

.bounceInUp.go {
    -webkit-animation-name: bounceInUp;
    animation-name: bounceInUp;
}


/******************
* Bounce in down *
*******************/

@-webkit-keyframes bounceInDown {
    0% {
        opacity: 0;

        -webkit-transform: translateY(-50px);
    }

    60% {

        -webkit-transform: translateY(30px);
    }

    80% {
        -webkit-transform: translateY(-10px);
    }

    100% {
        opacity: 1;

        -webkit-transform: translateY(0);
    }
}

@keyframes bounceInDown {
    0% {
        opacity: 0;

        -webkit-transform: translateY(-50px);

                transform: translateY(-50px);
    }

    60% {

        -webkit-transform: translateY(30px);

                transform: translateY(30px);
    }

    80% {
        -webkit-transform: translateY(-10px);
                transform: translateY(-10px);
    }

    100% {
        opacity: 1;

        -webkit-transform: translateY(0);

                transform: translateY(0);
    }
}

.bounceInDown.go {
    -webkit-animation-name: bounceInDown;
    animation-name: bounceInDown;
}


/**********
* Fade In *
**********/
@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
        display: block;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
        display: block;
    }
}

.fadeIn {
    opacity: 0;
}

.fadeIn.go {
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
}

/**********
* Grow in *
***********/

@-webkit-keyframes growIn {
    0% {
        -webkit-transform: scale(0.2);
        opacity: 0;
    }

    50% {
        -webkit-transform: scale(1.2);

    }

    100% {
        -webkit-transform: scale(1);
        opacity: 1;
    }
}

@keyframes growIn {
    0% {
        -webkit-transform: scale(0.2);
                transform: scale(0.2);
        opacity: 0;
    }

    50% {
        -webkit-transform: scale(1.2);
                transform: scale(1.2);

    }

    100% {
        -webkit-transform: scale(1);
                transform: scale(1);
        opacity: 1;
    }
}

.growIn {

    -webkit-transform: scale(0.2);
    transform: scale(0.2);
    opacity: 0;
}

.growIn.go {
    -webkit-animation-name: growIn;
    animation-name: growIn;
}

/********
* Shake *
********/
@-webkit-keyframes shake {

    0%,
    100% {
        -webkit-transform: translateX(0);
    }

    10%,
    30%,
    50%,
    70%,
    90% {
        -webkit-transform: translateX(-10px);
    }

    20%,
    40%,
    60%,
    80% {
        -webkit-transform: translateX(10px);
    }
}

@keyframes shake {

    0%,
    100% {
        -webkit-transform: translateX(0);
                transform: translateX(0);
    }

    10%,
    30%,
    50%,
    70%,
    90% {
        -webkit-transform: translateX(-10px);
                transform: translateX(-10px);
    }

    20%,
    40%,
    60%,
    80% {
        -webkit-transform: translateX(10px);
                transform: translateX(10px);
    }
}

.shake.go {
    -webkit-animation-name: shake;
    animation-name: shake;
}

/********
* ShakeUp *
********/
@-webkit-keyframes shakeUp {

    0%,
    100% {
        -webkit-transform: translateX(0);
    }

    10%,
    30%,
    50%,
    70%,
    90% {
        -webkit-transform: translateY(-10px);
    }

    20%,
    40%,
    60%,
    80% {
        -webkit-transform: translateY(10px);
    }
}

@keyframes shakeUp {

    0%,
    100% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }

    10%,
    30%,
    50%,
    70%,
    90% {
        -webkit-transform: translateY(-10px);
                transform: translateY(-10px);
    }

    20%,
    40%,
    60%,
    80% {
        -webkit-transform: translateY(10px);
                transform: translateY(10px);
    }
}

.shakeUp.go {
    -webkit-animation-name: shakeUp;
    animation-name: shakeUp;
}

/*************
* FadeInLeft *
*************/

@-webkit-keyframes fadeInLeft {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-50px);
    }

    50% {
        opacity: 0.3;
    }

    100% {
        opacity: 1;
        -webkit-transform: translateX(0);
    }
}

@keyframes fadeInLeft {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-50px);
                transform: translateX(-50px);
    }

    50% {
        opacity: 0.3;
    }

    100% {
        opacity: 1;
        -webkit-transform: translateX(0);
                transform: translateX(0);
    }
}

.fadeInLeft {
    opacity: 0;
    -webkit-transform: translateX(-50px);
    transform: translateX(-50px);
}

.fadeInLeft.go {
    -webkit-animation-name: fadeInLeft;
    animation-name: fadeInLeft;
}


/*************
* FadeInRight *
*************/

@-webkit-keyframes fadeInRight {
    0% {
        opacity: 0;
        -webkit-transform: translateX(50px);
    }

    50% {
        opacity: 0.3;
    }

    100% {
        opacity: 1;
        -webkit-transform: translateX(0);
    }
}

@keyframes fadeInRight {
    0% {
        opacity: 0;
        -webkit-transform: translateX(50px);
                transform: translateX(50px);
    }

    50% {
        opacity: 0.3;
    }

    100% {
        opacity: 1;
        -webkit-transform: translateX(0);
                transform: translateX(0);
    }
}

.fadeInRight {
    opacity: 0;
    -webkit-transform: translateX(50px);
    transform: translateX(50px);
}

.fadeInRight.go {
    -webkit-animation-name: fadeInRight;
    animation-name: fadeInRight;
}

/*************
* FadeInUp *
*************/

@-webkit-keyframes fadeInUp {
    0% {
        opacity: 0;
        -webkit-transform: translateY(50px);
    }

    /* 50% {
        opacity: 0.3;
    } */

    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
    }
}

@keyframes fadeInUp {
    0% {
        opacity: 0;
        -webkit-transform: translateY(50px);
                transform: translateY(50px);
    }

    50% {
        opacity: 0.3;
    }

    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }
}

.fadeInUp {
    opacity: 0;
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
}

.fadeInUp.go {
    -webkit-animation-name: fadeInUp;
    animation-name: fadeInUp;
}

/*************
* FadeInDown *
*************/

@-webkit-keyframes fadeInDown {
    0% {
        opacity: 0;
        -webkit-transform: translateY(-50px);
    }

    50% {
        opacity: 0.3;
    }

    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
    }
}

@keyframes fadeInDown {
    0% {
        opacity: 0;
        -webkit-transform: translateY(-50px);
                transform: translateY(-50px);
    }

    50% {
        opacity: 0;
    }

    70% {
        opacity: 0.4;
    }

    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }
}

.fadeInDown {
    opacity: 0;
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
}

.fadeInDown.go {
    -webkit-animation-name: fadeInDown;
    animation-name: fadeInDown;
}

/*****************
* rotateIn *
*****************/
@-webkit-keyframes rotateIn {
    0% {
        -webkit-transform-origin: center center;
        -webkit-transform: rotate(-200deg);
        opacity: 0;
    }

    100% {
        -webkit-transform-origin: center center;
        -webkit-transform: rotate(0);
        opacity: 1;
    }
}

@keyframes rotateIn {
    0% {
        -webkit-transform-origin: center center;
                transform-origin: center center;
        -webkit-transform: rotate(-200deg);
                transform: rotate(-200deg);
        opacity: 0;
    }

    100% {
        -webkit-transform-origin: center center;
                transform-origin: center center;
        -webkit-transform: rotate(0);
                transform: rotate(0);
        opacity: 1;
    }
}

.rotateIn.go {
    -webkit-animation-name: rotateIn;
    animation-name: rotateIn;
}

/*****************
* rotateInUpLeft *
*****************/

@-webkit-keyframes rotateInUpLeft {
    0% {
        -webkit-transform-origin: left bottom;
        -webkit-transform: rotate(90deg);
        opacity: 0;
    }

    100% {
        -webkit-transform-origin: left bottom;
        -webkit-transform: rotate(0);
        opacity: 1;
    }
}

@keyframes rotateInUpLeft {
    0% {
        -webkit-transform-origin: left bottom;
                transform-origin: left bottom;
        -webkit-transform: rotate(90deg);
                transform: rotate(90deg);
        opacity: 0;
    }

    100% {
        -webkit-transform-origin: left bottom;
                transform-origin: left bottom;
        -webkit-transform: rotate(0);
                transform: rotate(0);
        opacity: 1;
    }
}

.rotateInUpLeft.go {
    -webkit-animation-name: rotateInUpLeft;
    animation-name: rotateInUpLeft;
}

/*******************
* rotateInDownLeft *
*******************/
@-webkit-keyframes rotateInDownLeft {
    0% {
        -webkit-transform-origin: left bottom;
        -webkit-transform: rotate(-90deg);
        opacity: 0;
    }

    100% {
        -webkit-transform-origin: left bottom;
        -webkit-transform: rotate(0);
        opacity: 1;
    }
}

@keyframes rotateInDownLeft {
    0% {
        -webkit-transform-origin: left bottom;
                transform-origin: left bottom;
        -webkit-transform: rotate(-90deg);
                transform: rotate(-90deg);
        opacity: 0;
    }

    100% {
        -webkit-transform-origin: left bottom;
                transform-origin: left bottom;
        -webkit-transform: rotate(0);
                transform: rotate(0);
        opacity: 1;
    }
}

.rotateInDownLeft.go {
    -webkit-animation-name: rotateInDownLeft;
    animation-name: rotateInDownLeft;
}

/******************
* rotateInUpRight *
*******************/

@-webkit-keyframes rotateInUpRight {
    0% {
        -webkit-transform-origin: right bottom;
        -webkit-transform: rotate(-90deg);
        opacity: 0;
    }

    100% {
        -webkit-transform-origin: right bottom;
        -webkit-transform: rotate(0);
        opacity: 1;
    }
}

@keyframes rotateInUpRight {
    0% {
        -webkit-transform-origin: right bottom;
                transform-origin: right bottom;
        -webkit-transform: rotate(-90deg);
                transform: rotate(-90deg);
        opacity: 0;
    }

    100% {
        -webkit-transform-origin: right bottom;
                transform-origin: right bottom;
        -webkit-transform: rotate(0);
                transform: rotate(0);
        opacity: 1;
    }
}

.rotateInUpRight.go {
    -webkit-animation-name: rotateInUpRight;
    animation-name: rotateInUpRight;
}

/********************
* rotateInDownRight *
********************/

@-webkit-keyframes rotateInDownRight {
    0% {
        -webkit-transform-origin: right bottom;
        -webkit-transform: rotate(90deg);
        opacity: 0;
    }

    100% {
        -webkit-transform-origin: right bottom;
        -webkit-transform: rotate(0);
        opacity: 1;
    }
}

@keyframes rotateInDownRight {
    0% {
        -webkit-transform-origin: right bottom;
                transform-origin: right bottom;
        -webkit-transform: rotate(90deg);
                transform: rotate(90deg);
        opacity: 0;
    }

    100% {
        -webkit-transform-origin: right bottom;
                transform-origin: right bottom;
        -webkit-transform: rotate(0);
                transform: rotate(0);
        opacity: 1;
    }
}

.rotateInDownRight.go {
    -webkit-animation-name: rotateInDownRight;
    animation-name: rotateInDownRight;
}

/*********
* rollIn *
**********/

@-webkit-keyframes rollIn {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-100%) rotate(-120deg);
    }

    100% {
        opacity: 1;
        -webkit-transform: translateX(0px) rotate(0deg);
    }
}

@keyframes rollIn {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-100%) rotate(-120deg);
                transform: translateX(-100%) rotate(-120deg);
    }

    100% {
        opacity: 1;
        -webkit-transform: translateX(0px) rotate(0deg);
                transform: translateX(0px) rotate(0deg);
    }
}

.rollIn.go {
    -webkit-animation-name: rollIn;
    animation-name: rollIn;
}

/*********
* wiggle *
**********/

@-webkit-keyframes wiggle {
    0% {
        -webkit-transform: skewX(9deg);
    }

    10% {
        -webkit-transform: skewX(-8deg);
    }

    20% {
        -webkit-transform: skewX(7deg);
    }

    30% {
        -webkit-transform: skewX(-6deg);
    }

    40% {
        -webkit-transform: skewX(5deg);
    }

    50% {
        -webkit-transform: skewX(-4deg);
    }

    60% {
        -webkit-transform: skewX(3deg);
    }

    70% {
        -webkit-transform: skewX(-2deg);
    }

    80% {
        -webkit-transform: skewX(1deg);
    }

    90% {
        -webkit-transform: skewX(0deg);
    }

    100% {
        -webkit-transform: skewX(0deg);
    }
}

@keyframes wiggle {
    0% {
        -webkit-transform: skewX(9deg);
                transform: skewX(9deg);
    }

    10% {
        -webkit-transform: skewX(-8deg);
                transform: skewX(-8deg);
    }

    20% {
        -webkit-transform: skewX(7deg);
                transform: skewX(7deg);
    }

    30% {
        -webkit-transform: skewX(-6deg);
                transform: skewX(-6deg);
    }

    40% {
        -webkit-transform: skewX(5deg);
                transform: skewX(5deg);
    }

    50% {
        -webkit-transform: skewX(-4deg);
                transform: skewX(-4deg);
    }

    60% {
        -webkit-transform: skewX(3deg);
                transform: skewX(3deg);
    }

    70% {
        -webkit-transform: skewX(-2deg);
                transform: skewX(-2deg);
    }

    80% {
        -webkit-transform: skewX(1deg);
                transform: skewX(1deg);
    }

    90% {
        -webkit-transform: skewX(0deg);
                transform: skewX(0deg);
    }

    100% {
        -webkit-transform: skewX(0deg);
                transform: skewX(0deg);
    }
}

.wiggle.go {
    -webkit-animation-name: wiggle;
    animation-name: wiggle;
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
}

/********
* swing *
*********/

@-webkit-keyframes swing {

    20%,
    40%,
    60%,
    80%,
    100% {
        -webkit-transform-origin: top center;
    }

    20% {
        -webkit-transform: rotate(15deg);
    }

    40% {
        -webkit-transform: rotate(-10deg);
    }

    60% {
        -webkit-transform: rotate(5deg);
    }

    80% {
        -webkit-transform: rotate(-5deg);
    }

    100% {
        -webkit-transform: rotate(0deg);
    }
}

@keyframes swing {
    20% {
        -webkit-transform: rotate(15deg);
                transform: rotate(15deg);
    }

    40% {
        -webkit-transform: rotate(-10deg);
                transform: rotate(-10deg);
    }

    60% {
        -webkit-transform: rotate(5deg);
                transform: rotate(5deg);
    }

    80% {
        -webkit-transform: rotate(-5deg);
                transform: rotate(-5deg);
    }

    100% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
}

.swing.go {
    -webkit-transform-origin: top center;
    transform-origin: top center;
    -webkit-animation-name: swing;
    animation-name: swing;
}

/*******
* tada *
********/

@-webkit-keyframes tada {
    0% {
        -webkit-transform: scale(1);
    }

    10%,
    20% {
        -webkit-transform: scale(0.9) rotate(-3deg);
    }

    30%,
    50%,
    70%,
    90% {
        -webkit-transform: scale(1.1) rotate(3deg);
    }

    40%,
    60%,
    80% {
        -webkit-transform: scale(1.1) rotate(-3deg);
    }

    100% {
        -webkit-transform: scale(1) rotate(0);
    }
}

@keyframes tada {
    0% {
        -webkit-transform: scale(1);
                transform: scale(1);
    }

    10%,
    20% {
        -webkit-transform: scale(0.9) rotate(-3deg);
                transform: scale(0.9) rotate(-3deg);
    }

    30%,
    50%,
    70%,
    90% {
        -webkit-transform: scale(1.1) rotate(3deg);
                transform: scale(1.1) rotate(3deg);
    }

    40%,
    60%,
    80% {
        -webkit-transform: scale(1.1) rotate(-3deg);
                transform: scale(1.1) rotate(-3deg);
    }

    100% {
        -webkit-transform: scale(1) rotate(0);
                transform: scale(1) rotate(0);
    }
}

.tada.go {
    -webkit-animation-name: tada;
    animation-name: tada;
}

/*********
* wobble *
**********/

@-webkit-keyframes wobble {
    0% {
        -webkit-transform: translateX(0%);
    }

    15% {
        -webkit-transform: translateX(-25%) rotate(-5deg);
    }

    30% {
        -webkit-transform: translateX(20%) rotate(3deg);
    }

    45% {
        -webkit-transform: translateX(-15%) rotate(-3deg);
    }

    60% {
        -webkit-transform: translateX(10%) rotate(2deg);
    }

    75% {
        -webkit-transform: translateX(-5%) rotate(-1deg);
    }

    100% {
        -webkit-transform: translateX(0%);
    }
}

@keyframes wobble {
    0% {
        -webkit-transform: translateX(0%);
                transform: translateX(0%);
    }

    15% {
        -webkit-transform: translateX(-25%) rotate(-5deg);
                transform: translateX(-25%) rotate(-5deg);
    }

    30% {
        -webkit-transform: translateX(20%) rotate(3deg);
                transform: translateX(20%) rotate(3deg);
    }

    45% {
        -webkit-transform: translateX(-15%) rotate(-3deg);
                transform: translateX(-15%) rotate(-3deg);
    }

    60% {
        -webkit-transform: translateX(10%) rotate(2deg);
                transform: translateX(10%) rotate(2deg);
    }

    75% {
        -webkit-transform: translateX(-5%) rotate(-1deg);
                transform: translateX(-5%) rotate(-1deg);
    }

    100% {
        -webkit-transform: translateX(0%);
                transform: translateX(0%);
    }
}

.wobble.go {
    -webkit-animation-name: wobble;
    animation-name: wobble;
}

/********
* pulse *
*********/

@-webkit-keyframes pulse {
    0% {
        -webkit-transform: scale(1);
    }

    50% {
        -webkit-transform: scale(1.1);
    }

    100% {
        -webkit-transform: scale(1);
    }
}

@keyframes pulse {
    0% {
        -webkit-transform: scale(1);
                transform: scale(1);
    }

    50% {
        -webkit-transform: scale(1.1);
                transform: scale(1.1);
    }

    100% {
        -webkit-transform: scale(1);
                transform: scale(1);
    }
}

.pulse.go {
    -webkit-animation-name: pulse;
    animation-name: pulse;
}

/***************
* lightSpeedInRight *
****************/
@-webkit-keyframes lightSpeedInRight {
    0% {
        -webkit-transform: translateX(100%) skewX(-30deg);
        opacity: 0;
    }

    60% {
        -webkit-transform: translateX(-20%) skewX(30deg);
        opacity: 1;
    }

    80% {
        -webkit-transform: translateX(0%) skewX(-15deg);
        opacity: 1;
    }

    100% {
        -webkit-transform: translateX(0%) skewX(0deg);
        opacity: 1;
    }
}

@keyframes lightSpeedInRight {
    0% {
        -webkit-transform: translateX(100%) skewX(-30deg);
                transform: translateX(100%) skewX(-30deg);
        opacity: 0;
    }

    60% {
        -webkit-transform: translateX(-20%) skewX(30deg);
                transform: translateX(-20%) skewX(30deg);
        opacity: 1;
    }

    80% {
        -webkit-transform: translateX(0%) skewX(-15deg);
                transform: translateX(0%) skewX(-15deg);
        opacity: 1;
    }

    100% {
        -webkit-transform: translateX(0%) skewX(0deg);
                transform: translateX(0%) skewX(0deg);
        opacity: 1;
    }
}

.lightSpeedInRight.go {
    -webkit-animation-name: lightSpeedInRight;
    animation-name: lightSpeedInRight;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
}

/***************
* lightSpeedInLeft *
****************/
@-webkit-keyframes lightSpeedInLeft {
    0% {
        -webkit-transform: translateX(-100%) skewX(30deg);
        opacity: 0;
    }

    60% {
        -webkit-transform: translateX(20%) skewX(-30deg);
        opacity: 1;
    }

    80% {
        -webkit-transform: translateX(0%) skewX(15deg);
        opacity: 1;
    }

    100% {
        -webkit-transform: translateX(0%) skewX(0deg);
        opacity: 1;
    }
}

@keyframes lightSpeedInLeft {
    0% {
        -webkit-transform: translateX(-100%) skewX(30deg);
                transform: translateX(-100%) skewX(30deg);
        opacity: 0;
    }

    60% {
        -webkit-transform: translateX(20%) skewX(-30deg);
                transform: translateX(20%) skewX(-30deg);
        opacity: 1;
    }

    80% {
        -webkit-transform: translateX(0%) skewX(15deg);
                transform: translateX(0%) skewX(15deg);
        opacity: 1;
    }

    100% {
        -webkit-transform: translateX(0%) skewX(0deg);
                transform: translateX(0%) skewX(0deg);
        opacity: 1;
    }
}

.lightSpeedInLeft.go {
    -webkit-animation-name: lightSpeedInLeft;
    animation-name: lightSpeedInLeft;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
}


/*******
* Flip *
*******/
@-webkit-keyframes flip {
    0% {
        -webkit-transform: perspective(50px) rotateY(0);
        -webkit-animation-timing-function: ease-out;
    }

    40% {
        -webkit-transform: perspective(50px) translateZ(150px) rotateY(170deg);
        -webkit-animation-timing-function: ease-out;
    }

    50% {
        -webkit-transform: perspective(50px) translateZ(150px) rotateY(190deg) scale(1);
        -webkit-animation-timing-function: ease-in;
    }

    80% {
        -webkit-transform: perspective(50px) rotateY(360deg) scale(.95);
        -webkit-animation-timing-function: ease-in;
    }

    100% {
        -webkit-transform: perspective(50px) scale(1);
        -webkit-animation-timing-function: ease-in;
    }
}

@keyframes flip {
    0% {
        -webkit-transform: perspective(50px) rotateY(0);
                transform: perspective(50px) rotateY(0);
        -webkit-animation-timing-function: ease-out;
                animation-timing-function: ease-out;
    }

    40% {
        -webkit-transform: perspective(50px) translateZ(150px) rotateY(170deg);
                transform: perspective(50px) translateZ(150px) rotateY(170deg);
        -webkit-animation-timing-function: ease-out;
                animation-timing-function: ease-out;
    }

    50% {
        -webkit-transform: perspective(50px) translateZ(150px) rotateY(190deg) scale(1);
                transform: perspective(50px) translateZ(150px) rotateY(190deg) scale(1);
        -webkit-animation-timing-function: ease-in;
                animation-timing-function: ease-in;
    }

    80% {
        -webkit-transform: perspective(50px) rotateY(360deg) scale(.95);
                transform: perspective(50px) rotateY(360deg) scale(.95);
        -webkit-animation-timing-function: ease-in;
                animation-timing-function: ease-in;
    }

    100% {
        -webkit-transform: perspective(50px) scale(1);
                transform: perspective(50px) scale(1);
        -webkit-animation-timing-function: ease-in;
                animation-timing-function: ease-in;
    }
}

.flip.go {
    -webkit-backface-visibility: visible !important;
    -webkit-animation-name: flip;
    backface-visibility: visible !important;
    animation-name: flip;
}

/**********
* flipInX *
**********/
@-webkit-keyframes flipInX {
    0% {
        -webkit-transform: perspective(50px) rotateX(90deg);
        opacity: 0;
    }

    40% {
        -webkit-transform: perspective(50px) rotateX(-10deg);
    }

    70% {
        -webkit-transform: perspective(50px) rotateX(10deg);
    }

    100% {
        -webkit-transform: perspective(50px) rotateX(0deg);
        opacity: 1;
    }
}

@keyframes flipInX {
    0% {
        -webkit-transform: perspective(50px) rotateX(90deg);
                transform: perspective(50px) rotateX(90deg);
        opacity: 0;
    }

    40% {
        -webkit-transform: perspective(50px) rotateX(-10deg);
                transform: perspective(50px) rotateX(-10deg);
    }

    70% {
        -webkit-transform: perspective(50px) rotateX(10deg);
                transform: perspective(50px) rotateX(10deg);
    }

    100% {
        -webkit-transform: perspective(50px) rotateX(0deg);
                transform: perspective(50px) rotateX(0deg);
        opacity: 1;
    }
}

.flipInX.go {
    -webkit-backface-visibility: visible !important;
    -webkit-animation-name: flipInX;
    backface-visibility: visible !important;
    animation-name: flipInX;
}

/**********
* flipInY *
**********/

@-webkit-keyframes flipInY {
    0% {
        -webkit-transform: perspective(50px) rotateY(90deg);
        opacity: 0;
    }

    40% {
        -webkit-transform: perspective(50px) rotateY(-10deg);
    }

    70% {
        -webkit-transform: perspective(50px) rotateY(10deg);
    }

    100% {
        -webkit-transform: perspective(50px) rotateY(0deg);
        opacity: 1;
    }
}

@keyframes flipInY {
    0% {
        -webkit-transform: perspective(50px) rotateY(90deg);
                transform: perspective(50px) rotateY(90deg);
        opacity: 0;
    }

    40% {
        -webkit-transform: perspective(50px) rotateY(-10deg);
                transform: perspective(50px) rotateY(-10deg);
    }

    70% {
        -webkit-transform: perspective(50px) rotateY(10deg);
                transform: perspective(50px) rotateY(10deg);
    }

    100% {
        -webkit-transform: perspective(50px) rotateY(0deg);
                transform: perspective(50px) rotateY(0deg);
        opacity: 1;
    }
}

.flipInY.go {
    -webkit-backface-visibility: visible !important;
    -webkit-animation-name: flipInY;
    backface-visibility: visible !important;
    animation-name: flipInY;
}

/*****************
* Out animations *
*****************/


/************
* bounceOut *
*************/
@-webkit-keyframes bounceOut {
    0% {
        -webkit-transform: scale(1);
    }

    25% {
        -webkit-transform: scale(.95);
    }

    50% {
        opacity: 1;
        -webkit-transform: scale(1.1);
    }

    100% {
        opacity: 0;
        -webkit-transform: scale(.3);
    }
}

@keyframes bounceOut {
    0% {
        -webkit-transform: scale(1);
                transform: scale(1);
    }

    25% {
        -webkit-transform: scale(.95);
                transform: scale(.95);
    }

    50% {
        opacity: 1;
        -webkit-transform: scale(1.1);
                transform: scale(1.1);
    }

    100% {
        opacity: 0;
        -webkit-transform: scale(.3);
                transform: scale(.3);
    }
}

.bounceOut.goAway {
    -webkit-animation-name: bounceOut;
    animation-name: bounceOut;
}

/************
* bounceOutUp *
*************/
@-webkit-keyframes bounceOutUp {
    0% {
        -webkit-transform: translateY(0);
    }

    20% {
        opacity: 1;
        -webkit-transform: translateY(20px);
    }

    100% {
        opacity: 0;
        -webkit-transform: translateY(-2000px);
    }
}

@keyframes bounceOutUp {
    0% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }

    20% {
        opacity: 1;
        -webkit-transform: translateY(20px);
                transform: translateY(20px);
    }

    100% {
        opacity: 0;
        -webkit-transform: translateY(-2000px);
                transform: translateY(-2000px);
    }
}

.bounceOutUp.goAway {
    -webkit-animation-name: bounceOutUp;
    animation-name: bounceOutUp;
}

/************
* bounceOutDown *
*************/
@-webkit-keyframes bounceOutDown {
    0% {
        -webkit-transform: translateY(0);
    }

    20% {
        opacity: 1;
        -webkit-transform: translateY(-20px);
    }

    100% {
        opacity: 0;
        -webkit-transform: translateY(2000px);
    }
}

@keyframes bounceOutDown {
    0% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }

    20% {
        opacity: 1;
        -webkit-transform: translateY(-20px);
                transform: translateY(-20px);
    }

    100% {
        opacity: 0;
        -webkit-transform: translateY(2000px);
                transform: translateY(2000px);
    }
}

.bounceOutDown.goAway {
    -webkit-animation-name: bounceOutDown;
    animation-name: bounceOutDown;
}


/************
* bounceOutLeft *
*************/
@-webkit-keyframes bounceOutLeft {
    0% {
        -webkit-transform: translateX(0);
    }

    20% {
        opacity: 1;
        -webkit-transform: translateX(20px);
    }

    100% {
        opacity: 0;
        -webkit-transform: translateX(-2000px);
    }
}

@keyframes bounceOutLeft {
    0% {
        -webkit-transform: translateX(0);
                transform: translateX(0);
    }

    20% {
        opacity: 1;
        -webkit-transform: translateX(20px);
                transform: translateX(20px);
    }

    100% {
        opacity: 0;
        -webkit-transform: translateX(-2000px);
                transform: translateX(-2000px);
    }
}

.bounceOutLeft.goAway {
    -webkit-animation-name: bounceOutLeft;
    animation-name: bounceOutLeft;
}

/************
* bounceOutRight *
*************/
@-webkit-keyframes bounceOutRight {
    0% {
        -webkit-transform: translateX(0);
    }

    20% {
        opacity: 1;
        -webkit-transform: translateX(-20px);
    }

    100% {
        opacity: 0;
        -webkit-transform: translateX(2000px);
    }
}

@keyframes bounceOutRight {
    0% {
        -webkit-transform: translateX(0);
                transform: translateX(0);
    }

    20% {
        opacity: 1;
        -webkit-transform: translateX(-20px);
                transform: translateX(-20px);
    }

    100% {
        opacity: 0;
        -webkit-transform: translateX(2000px);
                transform: translateX(2000px);
    }
}

.bounceOutRight.goAway {
    -webkit-animation-name: bounceOutRight;
    animation-name: bounceOutRight;
}

/************
* fadeOut *
*************/
@-webkit-keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.fadeOut.goAway {
    -webkit-animation-name: fadeOut;
    animation-name: fadeOut;
}

/************
* fadeOutUp *
*************/
@-webkit-keyframes fadeOutUp {
    0% {
        opacity: 1;
        -webkit-transform: translateY(0);
    }

    100% {
        opacity: 0;
        -webkit-transform: translateY(-2000px);
    }
}

@keyframes fadeOutUp {
    0% {
        opacity: 1;
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }

    100% {
        opacity: 0;
        -webkit-transform: translateY(-2000px);
                transform: translateY(-2000px);
    }
}

.fadeOutUp.goAway {
    -webkit-animation-name: fadeOutUp;
    animation-name: fadeOutUp;
}

/************
* fadeOutDown *
*************/
@-webkit-keyframes fadeOutDown {
    0% {
        opacity: 1;
        -webkit-transform: translateY(0);
    }

    100% {
        opacity: 0;
        -webkit-transform: translateY(2000px);
    }
}

@keyframes fadeOutDown {
    0% {
        opacity: 1;
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }

    100% {
        opacity: 0;
        -webkit-transform: translateY(2000px);
                transform: translateY(2000px);
    }
}

.fadeOutDown.goAway {
    -webkit-animation-name: fadeOutDown;
    animation-name: fadeOutDown;
}

/************
* fadeOutLeft *
*************/
@-webkit-keyframes fadeOutLeft {
    0% {
        opacity: 1;
        -webkit-transform: translateX(0);
    }

    100% {
        opacity: 0;
        -webkit-transform: translateX(-2000px);
    }
}

@keyframes fadeOutLeft {
    0% {
        opacity: 1;
        -webkit-transform: translateX(0);
                transform: translateX(0);
    }

    100% {
        opacity: 0;
        -webkit-transform: translateX(-2000px);
                transform: translateX(-2000px);
    }
}

.fadeOutLeft.goAway {
    -webkit-animation-name: fadeOutLeft;
    animation-name: fadeOutLeft;
}

/************
* fadeOutRight *
*************/
@-webkit-keyframes fadeOutRight {
    0% {
        opacity: 1;
        -webkit-transform: translateX(0);
    }

    100% {
        opacity: 0;
        -webkit-transform: translateX(2000px);
    }
}

@keyframes fadeOutRight {
    0% {
        opacity: 1;
        -webkit-transform: translateX(0);
                transform: translateX(0);
    }

    100% {
        opacity: 0;
        -webkit-transform: translateX(2000px);
                transform: translateX(2000px);
    }
}

.fadeOutRight.goAway {
    -webkit-animation-name: fadeOutRight;
    animation-name: fadeOutRight;
}

/************
* flipOutX *
*************/
@-webkit-keyframes flipOutX {
    0% {
        -webkit-transform: perspective(50px) rotateX(0deg);
        opacity: 1;
    }

    100% {
        -webkit-transform: perspective(50px) rotateX(90deg);
        opacity: 0;
    }
}

@keyframes flipOutX {
    0% {
        -webkit-transform: perspective(50px) rotateX(0deg);
                transform: perspective(50px) rotateX(0deg);
        opacity: 1;
    }

    100% {
        -webkit-transform: perspective(50px) rotateX(90deg);
                transform: perspective(50px) rotateX(90deg);
        opacity: 0;
    }
}

.flipOutX.goAway {
    -webkit-animation-name: flipOutX;
    -webkit-backface-visibility: visible !important;
    animation-name: flipOutX;
    backface-visibility: visible !important;
}

/************
* flipOutY *
*************/
@-webkit-keyframes flipOutY {
    0% {
        -webkit-transform: perspective(50px) rotateY(0deg);
        opacity: 1;
    }

    100% {
        -webkit-transform: perspective(50px) rotateY(90deg);
        opacity: 0;
    }
}

@keyframes flipOutY {
    0% {
        -webkit-transform: perspective(50px) rotateY(0deg);
                transform: perspective(50px) rotateY(0deg);
        opacity: 1;
    }

    100% {
        -webkit-transform: perspective(50px) rotateY(90deg);
                transform: perspective(50px) rotateY(90deg);
        opacity: 0;
    }
}

.flipOutY {
    -webkit-backface-visibility: visible !important;
    -webkit-animation-name: flipOutY;
    backface-visibility: visible !important;
    animation-name: flipOutY;
}

/************
* lightSpeedOutRight *
*************/
@-webkit-keyframes lightSpeedOutRight {
    0% {
        -webkit-transform: translateX(0%) skewX(0deg);
        opacity: 1;
    }

    100% {
        -webkit-transform: translateX(100%) skewX(-30deg);
        opacity: 0;
    }
}

@keyframes lightSpeedOutRight {
    0% {
        -webkit-transform: translateX(0%) skewX(0deg);
                transform: translateX(0%) skewX(0deg);
        opacity: 1;
    }

    100% {
        -webkit-transform: translateX(100%) skewX(-30deg);
                transform: translateX(100%) skewX(-30deg);
        opacity: 0;
    }
}

.lightSpeedOutRight.goAway {
    -webkit-animation-name: lightSpeedOutRight;
    animation-name: lightSpeedOutRight;
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
}


/************
* lightSpeedOutLeft *
*************/
@-webkit-keyframes lightSpeedOutLeft {
    0% {
        -webkit-transform: translateX(0%) skewX(0deg);
        opacity: 1;
    }

    100% {
        -webkit-transform: translateX(-100%) skewX(30deg);
        opacity: 0;
    }
}

@keyframes lightSpeedOutLeft {
    0% {
        -webkit-transform: translateX(0%) skewX(0deg);
                transform: translateX(0%) skewX(0deg);
        opacity: 1;
    }

    100% {
        -webkit-transform: translateX(-100%) skewX(30deg);
                transform: translateX(-100%) skewX(30deg);
        opacity: 0;
    }
}

.lightSpeedOutLeft.goAway {
    -webkit-animation-name: lightSpeedOutLeft;
    animation-name: lightSpeedOutLeft;
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;

}

/************
* rotateOut *
*************/
@-webkit-keyframes rotateOut {
    0% {
        -webkit-transform-origin: center center;
        -webkit-transform: rotate(0);
        opacity: 1;
    }

    100% {
        -webkit-transform-origin: center center;
        -webkit-transform: rotate(200deg);
        opacity: 0;
    }
}

@keyframes rotateOut {
    0% {
        -webkit-transform-origin: center center;
                transform-origin: center center;
        -webkit-transform: rotate(0);
                transform: rotate(0);
        opacity: 1;
    }

    100% {
        -webkit-transform-origin: center center;
                transform-origin: center center;
        -webkit-transform: rotate(200deg);
                transform: rotate(200deg);
        opacity: 0;
    }
}

.rotateOut.goAway {
    -webkit-animation-name: rotateOut;
    animation-name: rotateOut;
}


/************
* rotateOutUpLeft *
*************/
@-webkit-keyframes rotateOutUpLeft {
    0% {
        -webkit-transform-origin: left bottom;
        -webkit-transform: rotate(0);
        opacity: 1;
    }

    100% {
        -webkit-transform-origin: left bottom;
        -webkit-transform: rotate(-90deg);
        opacity: 0;
    }
}

@keyframes rotateOutUpLeft {
    0% {
        -webkit-transform-origin: left bottom;
                transform-origin: left bottom;
        -webkit-transform: rotate(0);
                transform: rotate(0);
        opacity: 1;
    }

    100% {
        -transform-origin: left bottom;
        -transform: rotate(-90deg);
        opacity: 0;
    }
}

.rotateOutUpLeft.goAway {
    -webkit-animation-name: rotateOutUpLeft;
    animation-name: rotateOutUpLeft;
}

/************
* rotateOutDownLeft *
*************/

@-webkit-keyframes rotateOutDownLeft {
    0% {
        -webkit-transform-origin: left bottom;
        -webkit-transform: rotate(0);
        opacity: 1;
    }

    100% {
        -webkit-transform-origin: left bottom;
        -webkit-transform: rotate(90deg);
        opacity: 0;
    }
}

@keyframes rotateOutDownLeft {
    0% {
        -webkit-transform-origin: left bottom;
                transform-origin: left bottom;
        -webkit-transform: rotate(0);
                transform: rotate(0);
        opacity: 1;
    }

    100% {
        -webkit-transform-origin: left bottom;
                transform-origin: left bottom;
        -webkit-transform: rotate(90deg);
                transform: rotate(90deg);
        opacity: 0;
    }
}

.rotateOutDownLeft.goAway {
    -webkit-animation-name: rotateOutDownLeft;
    animation-name: rotateOutDownLeft;
}

/************
* rotateOutUpRight *
*************/

@-webkit-keyframes rotateOutUpRight {
    0% {
        -webkit-transform-origin: right bottom;
        -webkit-transform: rotate(0);
        opacity: 1;
    }

    100% {
        -webkit-transform-origin: right bottom;
        -webkit-transform: rotate(90deg);
        opacity: 0;
    }
}

@keyframes rotateOutUpRight {
    0% {
        -webkit-transform-origin: right bottom;
                transform-origin: right bottom;
        -webkit-transform: rotate(0);
                transform: rotate(0);
        opacity: 1;
    }

    100% {
        -webkit-transform-origin: right bottom;
                transform-origin: right bottom;
        -webkit-transform: rotate(90deg);
                transform: rotate(90deg);
        opacity: 0;
    }
}

.rotateOutUpRight.goAway {
    -webkit-animation-name: rotateOutUpRight;
    animation-name: rotateOutUpRight;
}

/************
* rollOut *
*************/
@-webkit-keyframes rollOut {
    0% {
        opacity: 1;
        -webkit-transform: translateX(0px) rotate(0deg);
    }

    100% {
        opacity: 0;
        -webkit-transform: translateX(100%) rotate(120deg);
    }
}

@keyframes rollOut {
    0% {
        opacity: 1;
        -webkit-transform: translateX(0px) rotate(0deg);
                transform: translateX(0px) rotate(0deg);
    }

    100% {
        opacity: 0;
        -webkit-transform: translateX(100%) rotate(120deg);
                transform: translateX(100%) rotate(120deg);
    }
}

.rollOut.goAway {
    -webkit-animation-name: rollOut;
    animation-name: rollOut;
}

.w-30 {
    width: 25rem;
}

/* ****************************************************************************
Common Css Elements ended
**************************************************************************** */

/* ****************************************************************************
Topbar css started
**************************************************************************** */
.roomph-logo img {
    width: 5.5rem;
}

.container-2 {
    width: 90%;
    margin: 0 auto;
}

.sign-in {
    cursor: pointer;
    font-size: 0.85rem !important;
}

/* ****************************************************************************
Topbar css started ended
**************************************************************************** */
.home-bar {
    background-color: #000;
    color: #fff;
    padding: 10px;
    position: relative;
    z-index: 1;
}

.orange-bar {
    background-color: #000;
    padding: 5px;
    color: #EFECEC;
    color: var(--secondary-color);
}

.elementToFadeInAndOut {
    opacity: 1;
    -webkit-animation: fade 2s linear;
            animation: fade 2s linear;
}

.heart {
    color: red;
    position: absolute;
    top: 10px;
    right: 10px;
}


@-webkit-keyframes fade {

    0%,
    100% {
        opacity: 0
    }

    50% {
        opacity: 1
    }
}


@keyframes fade {

    0%,
    100% {
        opacity: 0
    }

    50% {
        opacity: 1
    }
}

.blog-content img {
    width: 100%;
}

.swal-icon--warning {
    border-color: #FF8500 !important;
    border-color: var(--main-theme-color) !important;
}

.swal-icon--warning__body,
.swal-icon--warning__dot {
    background-color: #FF8500 !important;
    background-color: var(--main-theme-color) !important;
}

.swal-icon--warning__body {
    height: 30px;
}

.swal-icon {
    width: 60px;
    height: 60px;

}

/* .swal-modal {
    width: 450px;
} */
.swal-text {
    text-align: center;
}

.swal-footer {
    display: flex;
    align-items: center;
    justify-content: center;
}

.swal-modal {
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
}

/* ****************************************************************************
herosection css started started
**************************************************************************** */
.inputspan {
    font-size: 12px;
    padding: 3px;
    color: red;
    display: none;
}

input:invalid[focused="true"] {
    border: 1px solid red !important;
}

input:invalid[focused="true"]~span {
    display: block !important;
}

.bg-gray {
    background-color: #F5F5F5 !important;
}

.hero_section {
    border-radius: 0px 0px 50px 50px;
    height: 28rem;
    position: relative;
    z-index: 1;
    background-position-x: center;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    /* background-image: linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(https://roomph.ascendant.travel/../v1/images/roomph-banner-2.jpeg); */
}

.hero_section_terms {
    border-radius: 0px 0px 30px 30px;
    height: 27rem;
    position: relative;
    z-index: 1;
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(/static/media/aboutUs.bd40a97c.jpg);
}
.hero_section_terms1 {
    border-radius: 0px 0px 30px 30px;
    height: 27rem;
    position: relative;
    z-index: 1;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(/static/media/termsandcondition.4117dea4.jpg);
}

.z-0 {
    z-index: 0 !important;
}

.center-cal {
    top: 50px !important;
    left: 50% !important;
    right: auto !important;
    transform: translate(-50%, 0) !important;
    -webkit-transform: translate(-50%, 0) !important;
    -moz-transform: translate(-50%, 0) !important;
    -ms-transform: translate(-50%, 0) !important;
    -o-transform: translate(-50%, 0) !important;

}

.border-right1 {
    border: 1px solid #D8D8D8;
}

.confirm-box {
    box-shadow: 0px 1px 2px 2px #E1E1E1;
}

.border-b {
    border-bottom: 2px solid #A5A5A5 !important;
    margin-left: 11px;
    margin-top: 40px !important;
    width: 93% !important;

}




.info-heading {
    text-align: initial;
    font-family: PoppinsBold !important;
}

.search-box {
    background-color: #ffff;
    border-radius: 40px;
    border: 1px solid #EFECEC;
    border: 1px solid var(--secondary-color);
    width: 20rem;
    position: relative;
    z-index: 3;
    padding: 10px 14px;
    /* display: flex;
    flex-direction: column;
    align-items: center; */
}

.btn-search {
    outline: none;
    background: none;
    font-size: 0.75rem;
    border: none;
    text-align: left !important;
    color: #7F7F7F;
}

.hero_inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #929394;
}

.padding-15 {
    width: 100%;
    padding: 6px 0px 6px 35px !important;
    background-color: transparent !important;
    overflow: hidden !important;

}

.overflow-hidden {
    overflow: hidden !important;
}

.cal-1 {
    font-size: 1rem;
    position: absolute;
    z-index: 1;
    left: 0px;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
}



.nosubmit {
    width: 89%;
    position: relative;

}

/* .search-box {
    width: 36%
} */

.bi-search {
    color: rgb(147, 148, 149) !important;
    /*font-size: 1.25rem;
    */
    font-size: 30px;
    position: absolute;
    z-index: 100;
    left: 0px;
    top: 55%;
    transform: translateY(-60%);
    -webkit-transform: translateY(-60%);
    -moz-transform: translateY(-60%);
    -ms-transform: translateY(-60%);
    -o-transform: translateY(-60%);
}

.nosubmit input {
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    border-radius: 0px !important;
    /* border-bottom: 1px solid !important; */
    outline: none !important;
    border-color: #3b3b3b !important;
    border-color: var(--text-color) !important;
    display: block;
    padding: 0px 0px 0px 30px;
    /* background: transparent url("./Assests/search.svg") no-repeat 2px center ; */
}


.nosubmit input:focus {
    border-radius: 0px !important;
    outline: none !important;
    border-color: #3b3b3b !important;
    border-color: var(--text-color) !important;
}

/* .nosubmit {
     */
/* width: 100%;
  border: none; */
/* border-bottom: 1px solid rgb(147, 148, 149); */
/* background: transparent url("./Assests/search.svg") no-repeat 2px center;
} */

/* ****************************************************************************
herosection css  ended
**************************************************************************** */


/* ****************************************************************************
Slider css started
**************************************************************************** */
.slide_item {
    /* background: linear-gradient(to right, #FF3D00, #FF4E4C, #FF9C30) !important; */
    height: 14.5rem;
    border-radius: 40px;
    /* display: flex;
    justify-content: center;
    align-items: center; */
    background-repeat: no-repeat;
    background-size: cover !important;
    background: center;
}

.contact-whats {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 15px;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    position: relative;
}

.image-containeer {
    display: flex;
    align-items: center;
    justify-content: center;
}

.image-containeer img {
    width: 40px;
    vertical-align: middle;
    margin-right: 8px;
    margin-top: 10px;

}

.slide_item p {
    color: #EFECEC;
    color: var(--secondary-color);
    font-size: 24px;
}

.oo_outer {
    position: relative;
    /* background-color:black; */
}



/* ****************************************************************************
Slider css ended
**************************************************************************** */



/* ****************************************************************************
Review started
**************************************************************************** */
.reviews-container {
    padding: 10px 3px;
}

.review-container-inner {
    background: #EFECEC;
    background: var(--secondary-color);
    border: 1px solid #EFECEC;
    border: 1px solid var(--secondary-color);
    width: 100%;
    padding: 10px 10px;
    border-radius: 20px;
    box-shadow: 1px 1px 3px 2px rgba(0, 0, 0, 0.2);

    height: 16.5rem;
    position: relative;
}

/* .search-auto-box.wrapper {
    z-index: 90 !important;
} */
.border-radius {
    border-radius: 14px !important;
    -webkit-border-radius: 14px !important;
    -moz-border-radius: 14px !important;
    -ms-border-radius: 14px !important;
    -o-border-radius: 14px !important;
}

.header-container {
    display: flex;
    align-items: center;
    width: 100%;
}

.header-container img {
    width: 4.5rem !important;
    height: 4.5rem !important;
    border: 1px solid black;
    border-radius: 50%;
    background-position: center;
    background-size: cover;
    box-shadow: -1px -1px 0px 2px rgba(0, 0, 0, 0.4);
}

.desc {
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
    margin: 8px 10px 15px 10px;
}

.desc q {
    font-size: 1rem !important;
}

.desc q {
    font-size: 1rem !important;
}

.header-container h4 {
    font-size: 18px;
    padding: 0px 15px;
    font-family: PoppinssemiBold !important;

    /* padding-right: 3rem; */
}

.footer span {
    padding: 0px 10px;
    font-size: 0.8rem;
    font-style: italic;
    position: absolute;
    bottom: 15px;
}

.Ful1 {
    margin-left: 20px;
}

.reviewsHeading {
    margin-bottom: 15px;
}

.TR {
    font-family: Montserrat Bold;
    font-size: 22px;
}

.revHotelPic {
    display: block;
    width: 100%;
    height: auto;
    margin-top: 5px;
    object-fit: cover;
}

.hotelName {
    font-family: Montserrat Bold;
    font-size: 18px;
}


.reviewText {
    font-size: 16px;
}

/* ****************************************************************************
Review Ended 
**************************************************************************** */

/* ****************************************************************************
Blogs started 
**************************************************************************** */
.blogs-outer {
    padding-top: 10px;
    padding-bottom: 10px;

}

.blogs-outer1 {
    padding: 10px 10px;
}

.blog-img {
    border-radius: 20px 20px 0px 0px;
    display: block;
    width: 100%;
    height: 235px;
    -webkit-border-radius: 20px 20px 0px 0px;
    -moz-border-radius: 20px 20px 0px 0px;
    -ms-border-radius: 20px 20px 0px 0px;
    -o-border-radius: 20px 20px 0px 0px;
}

.blog-card {
    background-color: #fff;
    border-radius: 22px;
    -webkit-border-radius: 22px;
    -moz-border-radius: 22px;
    -ms-border-radius: 22px;
    -o-border-radius: 22px;
    display: inline-block;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    color: #000 !important;
    text-decoration: none !important;
    font-size: 18px;
}

.blog-card-inner .link {
    color: #000 !important;
    font-weight: bold;
    /* display: -webkit-box; */
    -webkit-line-clamp: 2;
    overflow: hidden;
    font-size: 1.1rem;

}

.blog-discription {
    color: #3b3b3b !important;
    color: var(--text-color) !important;
    font-size: 0.85rem;
    /* display: -webkit-box; */
    -webkit-line-clamp: 1;
    overflow: hidden;
    line-height: 0;
    text-overflow: ellipsis;
}

.blog-discription:hover {
    text-decoration: underline !important;
}

/* ****************************************************************************
Blogs ended 
**************************************************************************** */


/* ****************************************************************************
destination css started
**************************************************************************** */

.mpD {
    font-family: Montserrat Bold;
    font-size: 22px;
    white-space: nowrap;
}

.star-home {
    font-size: 1rem;
    vertical-align: middle;
}

.RP {
    font-family: Montserrat Thin;
}

.viewAllBtn a {
    color: black;
    font-size: 15px;
}

.aligned {
    display: flex;
    align-items: center;
    border-radius: 10px;
    height: 50px;
    margin-bottom: 10px;
    border: 1px solid #b3b3b3;
    box-shadow: 0px 2px 0px 0px #b3b3b3;
}

.alig {
    display: flex;
}

.aligned span {
    width: 500px;
}

.cityPic {
    width: 100%;
    height: 111%;
    margin: 0;
    padding: 0;
    padding-left: 3px;
    margin-right: -4px;
    border-radius: 20px;
}

.destinationtex {
    margin-left: 10px;
}

.destination {
    background-color: #72BEF0;
}


.cityA {
    float: left;
    margin-left: 20px;
    padding-top: 4px;
}

.cityC {
    float: left;
}

.cityNameCountt {
    height: 55px;
    width: 100px;
    color: #000 !important;
}

.des_item img {
    box-shadow: 0 2px 9px 2px rgb(0 0 0 / 15%);
    border-color: #dddfe2;
    border-radius: 50%;
    border-style: solid;
    border-width: 1px;
    height: 185px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    width: 190px !important;
    border: 0;
    vertical-align: middle !important;
    background-color: transparent;
    background-size: cover !important;
    text-decoration: none;
    font-size: 14px;
    zoom: 100%;
    line-height: 20px;
    font-weight: 400;
}



.plan-name {
    font-weight: 900;
}

.destination-star {
    margin-right: 5px !important;
    margin-top: -3px !important;
}



.owl-next {
    width: 30px;
    height: 30px;
    padding-left: 10px !important;
    padding-bottom: 10px !important;
    z-index: 1000 !important;
    border-radius: 50% !important;
    color: #FF8500 !important;
    color: var(--primary-color) !important;
    background-color: #FCFCFC !important;
    background-color: var(--white) !important;
    justify-content: center !important;
    align-items: center !important;
    flex-direction: column;
    text-decoration: none;
    position: absolute;
    visibility: visible;
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.4);
    font-size: 33px !important;
    font-weight: 400 !important;
    line-height: 0.74 !important;
    text-align: left;
}




.owl-prev {
    width: 30px;
    height: 30px;
    padding-left: 10px !important;
    padding-bottom: 10px !important;
    z-index: 1000 !important;
    border-radius: 50% !important;
    color: #FF8500 !important;
    color: var(--primary-color) !important;
    background-color: #FCFCFC !important;
    background-color: var(--white) !important;
    justify-content: center !important;
    align-items: center !important;
    flex-direction: column;
    text-decoration: none;
    position: absolute;
    visibility: visible;
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.4);
    font-size: 33px !important;
    font-weight: 400 !important;
    line-height: 0.74 !important;
    text-align: left;
}


.f-18 {
    font-size: 18px !important;
}

.main-color {
    color: #000;
}

.person {
    font-size: 1.6rem;
    vertical-align: middle;
}

/* .owl-theme .owl-prev {
    left: -10px;
    top: 30%;
    display: none !important;
    transform: translate(0, -30%);
    -webkit-transform: translate(0, -30%);
    -moz-transform: translate(0, -30%);
    -ms-transform: translate(0, -30%);
    -o-transform: translate(0, -30%);
} */



.swiper-slide {
    text-align: center;
    font-size: 18px;
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
}

.swiper-slide img {
    display: block;
    width: 100%;
    border-radius: 1rem;
    height: 100%;
    object-fit: cover;
    -webkit-border-radius: 1rem;
    -moz-border-radius: 1rem;
    -ms-border-radius: 1rem;
    -o-border-radius: 1rem;
}


/* .swiper {
    width: 600px;
  height: 300px;
  } */

.swiper-slide {
    background-size: cover;
    background-position: center;
}



.mySwiper2 {
    width: 100%;
    height: 100%;
    box-shadow: rgb(0 0 0 / 32%) 1px 6px 10px;
    height: calc(70vh - 65px);
    border-radius: 2.8rem !important;
    -webkit-border-radius: 2.8rem !important;
    -moz-border-radius: 2.8rem !important;
    -ms-border-radius: 2.8rem !important;
    -o-border-radius: 2.8rem !important;
}

/* .mobile-btn{
    position: absolute;
} */

.mySwiper2_skelton {
    width: 100%;
    height: 100%;
    height: calc(40vh - 64px);
    border-radius: 2rem !important;
    -webkit-border-radius: 2rem !important;
    -moz-border-radius: 2rem !important;
    -ms-border-radius: 2rem !important;
    -o-border-radius: 2rem !important;
}

.room_mySwiper2 {
    width: 100%;
    height: 100%;
    box-shadow: rgb(0 0 0 / 32%) 1px 6px 10px;
    height: calc(45vh - 64px);
    border-radius: 2.8rem !important;
    -webkit-border-radius: 2.8rem !important;
    -moz-border-radius: 2.8rem !important;
    -ms-border-radius: 2.8rem !important;
    -o-border-radius: 2.8rem !important;
}

.swiper-button-next {
    background-color: #FF8500 !important;
    background-color: var(--main-theme-color) !important;
    right: -15px !important;
    transform: translateY(-20%);
    position: absolute;
    -webkit-transform: translateY(-20%);
    -moz-transform: translateY(-20%);
    -ms-transform: translateY(-20%);
    -o-transform: translateY(-20%);
}

.swiper {
    position: unset !important;
}

.swiper-button-prev {
    background-color: #FF8500 !important;
    background-color: var(--main-theme-color) !important;
    left: -15px !important;
    transform: translateY(-20%);
    position: absolute;
    -webkit-transform: translateY(-20%);
    -moz-transform: translateY(-20%);
    -ms-transform: translateY(-20%);
    -o-transform: translateY(-20%);

}

.swiper-container {
    padding-left: 50px !important;
    padding-right: 50px !important;
}


.swiper-button-next,
.swiper-button-prev {
    background-color: #FF8500 !important;
    background-color: var(--main-theme-color) !important;
    width: 2.5rem;
    height: 2.5rem;
    padding: 10px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    position: absolute !important;
    overflow: visible !important;
    box-shadow: -1px 1px 2px 1px rgba(0, 0, 0, .45) !important;
}

.swiper-button-next::after {
    content: '\F135' !important;
    font-size: 2.8rem;
    font-family: 'bootstrap-icons' !important;
}

.swiper-button-prev::after {
    content: '\F12C' !important;
    font-size: 2.8rem;
    font-family: 'bootstrap-icons' !important;
}

.mySwiper {
    height: 15rem;
    box-sizing: border-box;
    padding: 25px 0px;
}

/* .swiper-slide img {
    border-radius: 2rem;
    -webkit-border-radius: 2rem;
    -moz-border-radius: 2rem;
    -ms-border-radius: 2rem;
    -o-border-radius: 2rem;
}


.swiper-slide {
    border-radius: 2.8rem;
    -webkit-border-radius: 2.8rem;
    -moz-border-radius: 2.8rem;
    -ms-border-radius: 2.8rem;
    -o-border-radius: 2.8rem;
} */

.mySwiper .swiper-slide {
    width: 25%;
    height: 100%;
}

.mySwiper .swiper-slide-thumb-active {
    opacity: 1;
}

.imgWid5 {
    width: 15px;
}

.owl-theme {
    position: relative;

}

.owl-theme-des {
    position: relative;
}

.owl-theme-explore .owl-next {
    right: -8px;
    bottom: 55%;
}

.owl-theme-explore .owl-prev {
    left: -8px;
    bottom: 55%;
}

.destination-caroisal .owl-next {
    right: -8px;
    bottom: 55%;
}

.des_p_name {
    text-decoration: none;
    color: #000 !important;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical !important;
    display: -webkit-box !important;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 14px;
    line-height: 30px;
    overflow: hidden;
    position: relative;
    width: 95% !important;
    font-family: "PoppinssemiBold" !important;
    position: relative;
    align-items: center;
    padding-right: 10px;
    display: flex;
    visibility: visible;
    text-transform: capitalize;
}

.cards-des_p_name {
    text-decoration: none;
    color: #000 !important;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical !important;
    display: -webkit-box !important;
    text-overflow: ellipsis;
    overflow: hidden;
    position: relative;
    width: 95% !important;
    font-family: PoppinssemiBold !important;
    position: relative;
    align-items: center;
    padding-right: 10px;
    visibility: visible;
    text-transform: capitalize;
}

.max-image {
    width: 3rem;
}

.cards-des_p_name_hotels {
    text-decoration: none;
    color: #000 !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical !important;
    display: -webkit-box !important;
    text-overflow: ellipsis;
    overflow: hidden;
    position: relative;
    line-height: 1.1;
    width: 95% !important;
    position: relative;
    align-items: center;
    padding-right: 10px;
    visibility: visible;
    text-transform: uppercase;
}

.destination-caroisal .owl-prev {
    left: -8px;
    bottom: 55%;
}

/* .owl-theme .owl-next {
    right: -10px;
    display: none !important;
    top: 30%;
    transform: translate(0, -30%);
    -webkit-transform: translate(0, -30%);
    -moz-transform: translate(0, -30%);
    -ms-transform: translate(0, -30%);
    -o-transform: translate(0, -30%);
} */

.owl-theme-des .owl-prev {
    left: -20px;
    top: 38%;
    transform: translate(0, -40%);
    -webkit-transform: translate(0, -40%);
    -moz-transform: translate(0, -40%);
    -ms-transform: translate(0, -40%);
    -o-transform: translate(0, -40%);
}


.owl-theme-des .owl-next {
    right: -18px;
    top: 38%;
    transform: translate(0, -40%);
    -webkit-transform: translate(0, -40%);
    -moz-transform: translate(0, -40%);
    -ms-transform: translate(0, -40%);
    -o-transform: translate(0, -40%);
}


/* .gradient-right{
    background: linear-gradient(to right, rgba(255, 255, 255, 0), rgb(255, 255, 255));
    width: 40px;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0px;
    z-index: 1;
    visibility: visible;
} */
.des_item img:hover {
    box-shadow: 0 2px 9px 2px rgb(0 0 0 / 15%);
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}

.on {
    position: relative;
    cursor: pointer;
    overflow: hidden;
    -webkit-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    z-index: 1;
    font-size: 0.9rem !important;
    transition: .3s ease-out;
    margin: 2px;
    text-align: center;
    background: #FF8500 !important;
    background: var(--main-theme-color) !important;
    color: #EFECEC !important;
    color: var(--secondary-color) !important;
    border: 1px solid #FF8500 !important;
    border: 1px solid var(--main-theme-color) !important;
    border-radius: 10px !important;
    width: 44px;
    height: 39px;
    -webkit-border-radius: 10px !important;

}

.off {
    position: relative;
    cursor: pointer;
    overflow: hidden;
    -webkit-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    z-index: 1;
    font-size: 0.9rem !important;
    transition: .3s ease-out;
    padding: 0;
    margin: 2px;
    text-align: center;
    background: transparent !important;
    color: #111111 !important;
    border: 1px solid #D6D6D6 !important;
    border-radius: 10px !important;
    width: 44px;
    height: 39px;
    -webkit-border-radius: 10px !important;
}

.des_item {
    /* background-color: #000; */
    -webkit-box-align: center;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    visibility: visible;
    padding: 10px 0px;
}


.destination-pp {
    text-decoration: none;
    font-size: 0.75rem;
    line-height: 16px;
    text-align: center;
    font-weight: 400;
    color: rgb(115, 115, 115);
    padding-top: 4px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
}

.destination-pp:hover {
    color: rgb(115, 115, 115);
}

.des_item span p {
    text-decoration: none;
    color: #000 !important;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    display: -webkit-box !important;
    font-size: 16px;
    line-height: 34px;
    line-height: 22px;
    max-height: 34px;
    overflow: hidden;
    padding-top: 12px;
    position: relative;
    text-align: center;
    width: 100%;
    font-family: PoppinssemiBold !important;
    position: relative;
    align-items: center;
    display: flex;
    overflow: hidden;
    visibility: visible;
    text-transform: capitalize;
}

.text-black {
    color: #000 !important;
    border-radius: 20px;
    padding-bottom: 15px !important;
    padding-left: 5px !important;
    padding-top: 5px !important;
    margin-bottom: 20px !important;
    height: 7%;
    margin-bottom: 10px;
    border: 2px solid #E5E4E4 !important;
    background-color: #fff;
    justify-content: start;
    margin-bottom: 40px;
}

.destinationtext {
    margin-left: -20px;
}

.destinationheading {
    font-size: 30px;
}

.destination-paragraph {
    font-size: 16px;
}

/* ****************************************************************************
destination css  ended
**************************************************************************** */

/* ****************************************************************************
facilities css  started
**************************************************************************** */
.facilities-container-outer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}

.buttonApp {
    display: none;
}

.star-container img {
    width: 1.7rem;
    margin: 1px;
}



p {
    margin-bottom: 0rem !important;
}

.facilities-container_inner:hover {

    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
}


.facilities-container_inner img {
    width: 2rem;
}

.facilities-container_inner p {
    font-size: 1rem !important;
    font-weight: bold;
    margin-bottom: 0px !important;
}

.facilities-container_inner {
    padding: 0.9rem 0.8rem;
    margin: 0px 0px 0px 20px;
    border-radius: 22px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #EFECEC;
    background-color: var(--secondary-color);
    box-shadow: 1px 1px 3px 2px rgba(0, 0, 0, 0.2);
    -webkit-border-radius: 22px;
    -moz-border-radius: 22px;
    -ms-border-radius: 22px;
    -o-border-radius: 22px;
}

/* ****************************************************************************
facilities css  ended
**************************************************************************** */
.start-container img {
    width: 23px;
    margin-right: 1px;
}

.ff-desc {
    display: flex;
    align-items: center;
}

.ff-desc p {
    margin-bottom: 0px !important;
    font-size: 1rem !important;
}

a,
a:hover {
    text-decoration: none !important;
    color: inherit;
}

.explore-row-container {
    display: flex;
    align-items: center;
    justify-content: space-between;

}



input[type="range"]::-webkit-slider-thumb {
    background: #FF8500 !important;
    background: var(--primary-color) !important;
}

/* All the same stuff for Firefox */
input[type="range"]::-moz-range-thumb {
    background: #FF8500 !important;
    background: var(--primary-color) !important;
}

/* All the same stuff for IE */
input[type="range"]::-ms-thumb {
    background: #FF8500 !important;
    background: var(--primary-color) !important;
}

.exploreCityImage {

    border-radius: 25px;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    -ms-border-radius: 25px;
    -o-border-radius: 25px;
    background-size: contain;
    background-position: center;
    height: 13rem !important;
    background-attachment: fixed;
    filter: brightness(0.9);
    -webkit-filter: brightness(0.9);
    transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    -webkit-transition: all 0.5s ease-in-out;
}

.exploreCityImage:hover {
    transform: scale(0.9);
    -webkit-transform: scale(0.9);
    -moz-transform: scale(0.9);
    -ms-transform: scale(0.9);
    -o-transform: scale(0.9);
}

.explore-row-left {
    width: 10%
}

.explore-row-right {
    width: 85%;
    display: flex;
    align-items: center;
}

.explore-desc h6 {
    font-size: 18px !important;
    line-height: 1.1;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
    height: 36px;

    /* margin-bottom: 0.2rem !important; */
}

.erow {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.explore-desc span {
    font-size: 15px !important;
}

/* .fXXHLR {
    position: inherit !important;
    height: 22px !important;
} */

/* .fXXHLR .wrapper {
    width: 75% !important;
    position: fixed;
    display: flex;
    flex-direction: column;
    border-radius: 0px;
    background-color: #F3F2F2;
    color: #818181 !important;
    font-size: 12px !important;
    font-family: Arial;
    z-index: 90;
} */

/* .hvguXJ {
    min-height: 22px !important;
}

.hvguXJ>input {
    font-size: 12px !important;
    background-color: #F3F2F2 !important;
    color: #818181 !important;
} */

.explore-row-left .top {
    font-size: 1.4rem;
    color: #FCFCFC;
    color: var(--white);

}

.explore-row-left .cc {
    font-size: 1.9rem;
    font-family: PoppinsBold !important;
    line-height: 1.5;
    color: #000;
}

.explore-row-left .notton {
    font-size: 1.4rem;
    color: #FCFCFC;
    color: var(--white);
    line-height: 0.5;


}

.erow img {
    /* display: flex; */
    width: 15px !important;
    margin-right: 2px !important;
    padding: 0px;
    width: 100px;
}

/*
/* ****************************************************************************
download App started started
**************************************************************************** */
.download {
    /* width: 190px; */
    height: 70px;
    padding: 0px 9px;
    border: 1px solid #cccccc;
    background: #ffff;
    border-radius: 45px;
    color: #000;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    /* box-shadow: 3px 2px 16px #3333333d; */
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out !important;

}

.lowerText {
    margin-bottom: 0px !important;
}

.download:hover {
    background-color: #FF8500;
    background-color: var(--primary-color);
    border: 1px solid #FF8500;
    border: 1px solid var(--primary-color);
    color: #EFECEC;
    color: var(--secondary-color);
    box-shadow: inset 0 0 0 0;
}

.download:hover .color-p {
    color: #EFECEC;
    color: var(--secondary-color);
}


.color-p {
    color: #FF8500;
    color: var(--primary-color);
}

.f-3 {
    font-size: 3em;
}

.p1 {
    font-size: 14px;
    margin-bottom: 0 !important;
    padding-right: 10px;
}

.p2 {
    font-size: 13px;
    font-weight: bolder;
    margin-bottom: 0 !important;
}

.footer-inner {
    background-color: #F0EDED;
    border-radius: 30px;
    padding: 30px 25px !important;
}

.footerlink {
    color: #000 !important;
    font-size: 16px !important;
}

.footer-heading {
    font-size: 25px;
    font-weight: 900;
    text-decoration: none;
}

.copy-r {
    position: absolute;
    bottom: 5px;
    padding: 0px 10px;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    background-color: #575757;
    font-size: 0.8rem;
}

.MuiSlider-root {
    position: absolute !important;
    top: 0 !important;
    right: 0 !important;
}

.reviewname {
    color: #72BEF0;
}

/* ****************************************************************************
search-header started 
**************************************************************************** */


.roomphLogoH {
    width: 80%;
}


.arrowBtn {
    border: none;
    background: none;
}

.Deals {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: left;
}

.dropdown-contentTopbar {
    background-color: #ffff !important;
    z-index: 100;
    position: absolute;
    width: 12rem;
    box-shadow: 1px 1px 3px 1px #ccc;
    top: 2.5rem;
    right: -10px;
    padding: 1rem;
    border-radius: 12px;
    list-style: none !important;
    text-decoration: none !important;
}

.dropdown-contentTopbar hr {
    color: #dfe1e5 !important;
    opacity: 1 !important;

}

.dropdown-contentTopbar .nav-item-drop {
    color: #FF8500;
    color: var(--main-theme-color);
    width: 100%;
}

.dropdown-contentTopbar .nav-item-drop a {
    color: #FF8500;
    color: var(--main-theme-color);
    width: 100%;
}

.dropdown-contentTopbar .nav-item-drop a:hover {
    color: #fca527;
    color: var(--hover-color);
}

/* .sign-in {
    color: var(--main-theme-color) !important;
  } */
.dropdownTopbar {
    position: relative;
    display: inline-block;
}

@media screen and (min-width: 480px) {
    .dropdown-contentTopbar {
        margin-left: 7px;
    }

    .btn-primary-seach {
        margin-left: 62px !important;
    }
}

.dropdownTopbar:hover .dropdown-contentTopbar {
    display: block;
}

.wrap {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: right;
}

.headerContainer {
    margin: 0;
    padding: 0;
    margin-left: 10px;
}

@media screen and (min-width: 480px) {
    .headerContainer {
        margin-left: 80px;
    }

    .star-reviews {
        padding-left: 15px !important;
        color: #FB8501;

    }

    .cardprice {
        margin-top: 25% !important;
    }
}

.dealsBtn {
    width: 80px;
    height: 21px;
    font-size: 12px;
    font-family: Roboto Light;
    letter-spacing: 1px;
    font-weight: 600;
    color: #fff;
    background-color: #EF4E22;
    border: none;
    border-radius: 45px;
    box-shadow: 1px 1px 1px 1px rgb(205, 206, 206);
    transition: all 0.3s ease 0s;
    cursor: pointer;
    outline: none;
}

.dealsBtn:focus {
    outline: none;
}

.dealsBtn:hover {
    background-color: #fca527;
    outline: none;
    /* transform: translateY(-1px); */
}

@media screen and (min-width: 480px) {
    .dealsBtn {
        padding-top: 3px;
        width: 100px;
        height: 25px;
        margin-right: 15px;
        margin-top: 5px;
    }

    .cardprice {
        margin-top: 25% !important;
    }

    .topBreak {
        content: "";
        margin: -2px;
        display: block;
        font-size: -10%;
    }
}

.signinBtn {
    font-weight: 600;
    font-size: 12px;
    border: none;
    color: #EF4E22;
    background-color: white;
    border-color: white;
    white-space: nowrap;
}

.signinBtn:focus,
.signinBtn:hover {
    outline: none;
}

@media screen and (min-width: 570px) {
    .signinBtn {
        font-size: 14px;
    }

    .cardprice {
        margin-top: 20% !important;
    }

}

.buttonApp {
    width: 80px;
    height: 22px;
    font-size: 11px;
    font-family: JTMarnie Light;
    letter-spacing: 1px;
    font-weight: 600;
    color: #EF4E22;
    background-color: #fff;
    border: none;
    border-radius: 45px;
    box-shadow: 1px 1px 1px 1px rgb(205, 206, 206);
    transition: all 0.3s ease 0s;
    cursor: pointer;
    outline: none;
}

.wrap a:link,
.wrap a:visited {
    background-color: #fff;
    color: #EF4E22;
    font-size: 12px;
    letter-spacing: 0.5px;
    text-align: right;
    text-decoration: none;
    display: inline-block;
}

.wrap a:hover,
.wrap a:active {
    background-color: #fca527;
}


.profileBtn1 {
    width: 80px;
    height: 21px;
    font-size: 12px;
    font-family: Roboto Light;
    letter-spacing: 1px;
    font-weight: 600;
    color: #EF4E22;
    background-color: #fff;
    border: none;
    border-bottom: 1px solid #b3b3b3;
    transition: all 0.3s ease 0s;
    cursor: pointer;
    outline: none;
}



.profileBtn1:focus {
    outline: none;
}

.profileBtn1:hover {
    background-color: #fca527;
}

.otherBtns1 {
    width: 80px;
    height: 21px;
    font-size: 12px;
    padding-top: 3px;
    padding-right: 20px;
    font-family: Roboto Light;
    letter-spacing: 1px;
    font-weight: 600;
    border: none;
    border-bottom: 2px solid #A9A8AD;
    transition: all 0.3s ease 0s;
    cursor: pointer;
    outline: none;
}

.otherBtns1:focus {
    outline: none;
}

.otherBtns1:hover {
    background-color: #fca527;
}


.destinationtext {
    margin-left: 20px;
}

.MuiSlider-thumb {
    background-color: #FF8500 !important;
    background-color: var(--main-theme-color) !important;
}

.MuiSlider-track {
    background-color: #FF8500 !important;
    background-color: var(--main-theme-color) !important;
}

.MuiSlider-rail {
    background-color: #C7C7C7 !important;
}

.input-div {
    padding-left: 40px;
    padding-right: 40px;

}

.search-input-container {
    text-align: center;
    min-width: 348px;
    background-color: #fff;
    border-radius: 50px;
    z-index: 90;
    position: relative !important;
    box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.2);
}


.line-height {
    line-height: 0.7 !important;
}

.signUpCapsule {
    background: linear-gradient(to right, #FF3D00, #FF4E4C, #FF9C30) !important;
    color: #EFECEC;
    color: var(--secondary-color);
    padding: 0.8rem 0.5rem;
    font-weight: bold;
    font-size: 18px;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}

.pp-3 {
    padding: 0.8rem 0rem !important
}

.d-none {
    display: none;
}

ww-50 {
    width: 50px;
}

.mt-60 {
    margin-top: 60px;
}

.location {
    flex-grow: 1;
    flex-basis: 0;
    min-width: 0;
    text-transform: capitalize;

}

.date-time {
    flex-grow: 1.5;
    flex-basis: 0;
    min-width: 0;
    border-right: 1px solid #cccccc !important;
}

.alert-warning {
    background-color: rgba(255, 133, 0, 0.8) !important;
    color: #fff !important;
}

.search-icon1 {
    width: 25px;
    height: 25px;
    /* padding: 15px; */
    border-radius: 50%;
    color: #EFECEC;
    color: var(--secondary-color);
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    position: absolute;
    z-index: 1000;
    left: 5px;
    top: 6px;
}

.search-icon2 {
    width: 27px;
    height: 27px;
    /* padding: 15px; */
    border-radius: 50%;
    color: #EFECEC;
    color: var(--secondary-color);
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    position: absolute;
    z-index: 1000;
    right: 15px;
    top: 5px;
}

.search-icon {
    width: 20px;
    height: 20px;
    margin: 5px !important;
}

.jMcrRR {
    min-height: 40px !important;
}

.clear-icon {
    margin: 0px !important;
}

.search-icon1 .searc-i {
    color: rgba(192, 192, 192, 1) !important;
    font-size: 1.45rem;
    text-align: center;
    position: inherit !important;
    left: 50% !important;
    top: 48% !important;
    transform: translate(50%, -50%) !important;
    -webkit-transform: translate(-50%, -50%) !important;
    -moz-transform: translate(50%, -50%) !important;
    -ms-transform: translate(50%, -50%) !important;
    -o-transform: translate(50%, -50%) !important;
}

.container-fluid {
    padding: 0 !important;
    margin: 0 !important;
}

.home-auto .wrapper {
    border-bottom: 0px solid #dfe1e5 !important;
    border-top: 0px !important;
    border-right: 0px !important;
    border-left: 0px !important;
    position: relative !important;
}

.wrapper {
    border-bottom: 0px solid #dfe1e5 !important;
    border-top: 0px !important;
    border-right: 0px !important;
    border-left: 0px !important;
}

.home-auto .wrapper ul {
    position: absolute;
    background-color: #ffff;
    width: 100%;
    margin-top: 10px !important;
}

.home-auto .wrapper .line {
    display: none;
}

/* .wrapper input {
    padding-left: 35px !important;
} */

/* .search-auto-box.wrapper:hover {
    box-shadow: none !important;

}

.search-auto-box.wrapper:focus-within {
    box-shadow: none !important;
} */

.ss-bb {
    -webkit-appearance: none;
            appearance: none;
    border: 0;
    display: inline-flex;
    justify-content: center;
    font-family: 'Poppins';
    font-size: 1rem;
    margin: 0;
    outline: 0;
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 35px;
    overflow: visible;
    outline: none;
    align-items: center;
    z-index: 1;
    cursor: pointer;
}

.search-section {
    --bs-gutter-x: 3.5rem !important;

}

.propertyheading {
    text-align: center;
    background-color: #FB8501;
    color: #000;
    border-radius: 6px;
    padding: 9px 0px;
    font-weight: 900;
    font-size: 1rem;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 8px;
}

.location::-webkit-input-placeholder {
    color: #3b3b3b;
    color: var(--text-color);
    font-size: 1rem !important;

}

.location::placeholder {
    color: #3b3b3b;
    color: var(--text-color);
    font-size: 1rem !important;

}

.location {
    color: #3b3b3b;
    color: var(--text-color);

}

ss-bb span {
    border: 0;
    padding: 0;
    clip: rect(0 0 0 0);
    -webkit-clip-path: inset(100%);
            clip-path: inset(100%);
    height: 1px;
    overflow: hidden;
    overflow: clip;
    position: absolute;
    white-space: nowrap;
    width: 1px;
}

/* ****************************************************************************
search-header Ended 
**************************************************************************** */


/* ****************************************************************************
Sign-up modal started 
**************************************************************************** */
.sign-in-container {
    border-radius: 15px 15px 15px 15px;
    border: 1px solid rgb(203, 203, 203);
    background-color: #EFECEC !important;
    background-color: var(--secondary-color) !important;
    margin: 10% auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1.5rem 1.3rem;
    width: 29rem;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    -webkit-border-radius: 15px 15px 15px 15px;
    -moz-border-radius: 15px 15px 15px 15px;
    -ms-border-radius: 15px 15px 15px 15px;
    -o-border-radius: 15px 15px 15px 15px;
}

.css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop {
    background-color: rgba(0, 0, 0, 0.65) !important;

}


.css-3uo0vg {
    height: -webkit-fit-content !important;
    height: -moz-fit-content !important;
    height: fit-content !important;
    width: -webkit-fit-content !important;
    width: -moz-fit-content !important;
    width: fit-content !important;
    display: contents !important;

}

.text-center p {
    margin-bottom: 0px !important;
}

.fb {
    color: rgba(6, 116, 225) !important;
}

.white-btn {
    width: 100%;
    height: 30px;
    font-size: 15px;
    background-color: #EFECEC;
    background-color: var(--secondary-color);
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 45px;
    box-shadow: 0px 8px 15px rgb(0 0 0 / 10%);
    transition: all 0.3s ease 0s;
    cursor: pointer;
    outline: none;
}

/* ****************************************************************************
Sign-up modal started 
**************************************************************************** */
.lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}

.lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid #FF8500;
    border: 8px solid var(--primary-color);
    border-radius: 50%;
    -webkit-animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
            animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #FF8500 transparent transparent transparent;
    border-color: var(--primary-color) transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
    -webkit-animation-delay: -0.45s;
            animation-delay: -0.45s;
}

.loading-div {
    width: 100%;
    height: 100vh;

}

.inner-div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
}

.lds-ring div:nth-child(2) {
    -webkit-animation-delay: -0.3s;
            animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
    -webkit-animation-delay: -0.15s;
            animation-delay: -0.15s;
}

@-webkit-keyframes lds-ring {
    0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

@keyframes lds-ring {
    0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

section {
    display: block;
}

#map_canvas {
    position: relative;
    overflow: hidden;
}

#desktop-map {
    position: relative;
    overflow: hidden;
}

div#map_canvas {
    height: calc(100vh - 51.61px);
}

div#desktop-map {
    height: 100vh;
}

.ft-16 {
    font-size: 1rem !important;
}

.text-orange {
    color: #FF8500;
    color: var(--main-theme-color);
}

.text-black {
    color: #111111 !important;
    height: 7%;
    padding-bottom: 42px !important;
    padding-left: 5px !important;
    padding-top: 5px !important;
    margin-bottom: 20px !important;

}

.text-capitalize {
    text-transform: capitalize !important;
}

.mb-0,
.my-0 {
    margin-bottom: 0 !important;
}

.z-index {
    z-index: 1;
}

.feature-hotel-description-desk {
    padding: 0px 10px;
}

.feature-hotel-title-desk {
    width: 75%;
}

.feature-hotel-star-rating-desk {
    width: 25%;
    text-align: right;
}

.feature-hotel-box-desk {
    border-bottom: 1px solid #DBDBDB;
}

.feature-hotel-img-desk img {
    height: 60px;
    object-fit: cover;
    border-radius: 10px 10px 0 0;
}

.w-100 {
    width: 100% !important;
}

.z-index-999 {
    z-index: 99999 !important;
}

.has-search .form-control-feedback {
    position: absolute;
    display: block;
    pointer-events: none;
    color: var(--title-theme-blue);
    top: 35px;
    left: 31px;
}

.right-0 {
    right: 0;
}

.left-0 {
    left: 0;
}

.feature-hotel-box {
    border-bottom: 1px solid #EBEBEB;
    padding-bottom: 5px;
}

.feature-hotel-img {
    width: 28px;
}

a {
    text-decoration: none !important;
    color: initial;
}


.imgWidD {
    width: 24px;
    padding: 2px;
}

.scrollbar {
    overflow-y: auto;
    height: calc(90vh - 50px);
}

#scroll-4::-webkit-scrollbar {
    width: 7px;
    background-color: #666666;
}

#scroll-4::-webkit-scrollbar-thumb {
    background-color: #C4C4C4;
    border: 1px solid #666666;
}

#scroll-4::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
    background-color: #666666;
}

.w-300 {
    width: 350px;
}

.roboto-light {
    color: #111111;
}

.border-radius-10 {
    border-radius: 10px;
}

.bg-white {
    background-color: whitesmoke !important;
}

.color-primary {
    color: #FF8500;
    color: var(--main-theme-color);
}

.feature-hotel-description.float-left {
    width: calc(100% - 38px);
    margin-left: 10px;
}

.feature-hotel-title {
    width: calc(100% - 70px);
}

.ft-12 {
    font-size: 12px !important;
}

.gr-medium {
    font-family: 'gothamroundedmedium' !important;
}

.feature-hotel-img img {
    width: 28px;
    height: 28px;
    border-radius: 6px;
    object-fit: cover;
}


.text-orange:hover {
    color: #FF8500;
    color: var(--primary-color);
}


.ft-10 {
    font-size: 10px !important;
}

.ft-11 {
    font-size: 12px !important;
}

.ft-12 {
    font-size: 12px !important;
}

.feature-hotel-star-rating {
    width: 70px;
}

.bottom-0 {
    bottom: 0;
}


.slider {
    position: relative;
}

.slider__track,
.slider__range,
.slider__left-value,
.slider__right-value {
    position: absolute;
}

.slider__track,
.slider__range {
    border-radius: 3px;
    height: 5px;
}

.slider__track {
    background-color: #ced4da;
    width: 100%;
    z-index: 1;
}

.slider__range {
    background-color: #FF8500;
    background-color: var(--primary-color);
    z-index: 2;
}

.slider__left-value,
.slider__right-value {
    color: #3b3b3b;
    color: var(--text-color);
    font-size: 12px;
    margin-top: 20px;
}

.slider__left-value {
    left: 0;
}

.slider__right-value {
    right: 0;
}

/* Removing the default appearance */
.thumb,
.thumb::-webkit-slider-thumb {
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
}

.thumb {
    pointer-events: none;
    position: absolute;
    height: 0;
    width: 100%;
    outline: none;
}

.thumb--left {
    z-index: 3;
    left: 0;
}

.thumb--right {
    z-index: 4;
    right: 0;
}

/* For Chrome browsers */
.thumb::-webkit-slider-thumb {
    background-color: #f1f5f7;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    height: 18px;
    width: 18px;
    pointer-events: all;
    position: relative;
}

/* For Firefox browsers */
.thumb::-moz-range-thumb {
    background-color: #f1f5f7;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    height: 18px;
    width: 18px;
    pointer-events: all;
    position: relative;
}


/* ****************************************************************************
Property Details css started 
**************************************************************************** */
.Property-images-row {
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
}

.is-primary {
    margin: 0px !important;
    text-align: center !important;
    text-decoration: none !important;
    width: auto !important;
    border: none;
    box-shadow: 1px 1px 2px 1px #ccc;
    outline: none !important;
    padding: 7px 15px !important;
    transition: box-shadow 0.2s ease 0s, -webkit-transform 0.1s ease 0s, transform 0.1s ease 0s !important;
    -webkit-tap-highlight-color: transparent !important;
    color: #fff;
    border-radius: 20px;
    background-color: #FB8501;
    display: inline-block !important;
    text-align: center !important;
    text-decoration: none !important;

}

.reviewCount1 {
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 0;
    width: 42px;
    border: 1px solid #fff;
    border-radius: 5px 5px 5px 5px;
    box-shadow: inset 1px 1px 0px 2px #C7C7C7;
    height: 42px;
    text-align: center;
    background-size: cover;
    font-size: 17px;
    color: #ff3d00;

    font-weight: 700;
    -webkit-border-radius: 50% 50% 0px 50%;
    -moz-border-radius: 50% 50% 0px 50%;
    -ms-border-radius: 50% 50% 0px 50%;
    -o-border-radius: 50% 50% 0px 50%;
}

.react_lightgallery_item {
    width: 100% !important;
    height: 100% !important;
}

.h-390 {
    max-height: calc(60vh - 64px) !important;
}

.h-195 {
    height: calc((60vh - 64px)/2) !important;
}

.v-good {
    line-height: 2;
    font-weight: 900;
}

.hotel-price {
    color: #FF8500 !important;
    color: var(--primary-color) !important
}

.missed-heading {
    font-size: 13px;
    font-weight: 900;
}

.avg {
    font-size: 10px;
    line-height: 0.5;
    color: #838383 !important;
}

.accomdationimg {
    width: 24px;
    padding: 2px;
}

.sticky-right-contrainer {
    max-width: 1120px;
    border: 1px px solid rgb(221, 221, 221);
    border-radius: 12px;
    padding: 24px;
    position: -webkit-sticky;
    position: sticky;
    top: 3%;
    box-shadow: rgb(0 0 0 / 12%) 0px 6px 16px;
}

.position-sticky-btn {
    position: -webkit-sticky !important;
    position: sticky !important;
    top: 2rem;
    z-index: 1000;
}



.map-icon {
    width: 1.5rem !important;
}

.third-heading {
    font-size: 18px;
    font-weight: 900;
}

.hotel-reviews-container {
    padding: 10px 3px;
}

.hotel-review-container-inner {
    background: #FCFCFC;
    background: var(--white);
    border: 1px solid #EFECEC;
    border: 1px solid var(--secondary-color);
    width: 100%;
    padding: 15px 20px;
    border-radius: 20px;
    height: 183px;
    position: relative;
}

.hotel-header-container {
    display: flex;
    align-items: center;
    width: 100%;
}

.hotel-header-container img {
    width: 3.3rem !important;
    height: 3.3rem !important;
    border-radius: 50%;
    background-position: center;
    background-size: cover;
    box-shadow: 1px 1px 2px 1px #d3d1d1;
}

.hotel-desc {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 0.95rem;
    margin: 8px 0px 15px 0px;
}




.hotel-header-container h4 {
    font-size: 1.1rem;
    padding: 0px 10px;
    text-transform: capitalize;
    /* padding-right: 3rem; */
}

.hotel-footer .r-name {
    position: absolute;
    bottom: 15px;
    display: flex;
    align-items: flex-end;
}

.hotel-footer .r-name-city {
    font-size: 0.8rem;
    font-style: italic;
}

.review-score {
    float: right;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 0;
    width: 40px;
    border: 1px solid #fff;
    border-radius: 50% 50% 0 50%;
    box-shadow: inset 1px 1px 0px 2px #C7C7C7;
    height: 40px;
    text-align: center;
    background-size: cover;
    font-size: 15px;
    color: #ff3d00;
    font-weight: 700;
    -webkit-border-radius: 50% 50% 0 50%;
    -moz-border-radius: 50% 50% 0 50%;
    -ms-border-radius: 50% 50% 0 50%;
    -o-border-radius: 50% 50% 0 50%;
}

.room-container {
    border-radius: 2.5rem;
    box-shadow: rgb(0 0 0 / 12%) 0px 6px 16px;
    padding: 30px 25px;
    margin-bottom: 20px;
    -webkit-border-radius: 2.5rem;
    -moz-border-radius: 2.5rem;
    -ms-border-radius: 2.5rem;
    -o-border-radius: 2.5rem;
}

.hotel-general-desc {
    text-align: justify;
}

.room-image-owl-theme .owl-prev {
    left: -16px;
    top: 35%;
    width: 30px;
    height: 30px;
}

.room-image-owl-theme .owl-next {
    right: -16px;
    top: 35%;
    width: 30px;
    height: 30px;
}

.book-now {
    width: 300px !important;
    padding: 7px !important;
    z-index: 100;
}

.position-relative {
    position: relative !important;
}

.thankew-btn {
    border-radius: 45px !important;
    color: #EFECEC !important;
    color: var(--secondary-color) !important;
    box-shadow: rgb(205 206 206) 1px 1px 1px 1px !important;
    background-color: rgb(126, 211, 79) !important;
    white-space: nowrap;
    -webkit-border-radius: 45px !important;
    -moz-border-radius: 45px !important;
    -ms-border-radius: 45px !important;
    -o-border-radius: 45px !important;
}

.line-1 {
    line-height: 1;
}

.step1 {
    border: none;
    border-radius: 50%;
    text-align: center;
    width: 2.5rem;
    height: 2.5rem;
    font-weight: 700;
    font-size: 1rem;
    display: table-cell;
    vertical-align: middle;
    border: none;
    border-radius: 50%;
    color: #fff;
    background-color: #FF8500;
    background-color: var(--main-theme-color);
    text-align: center;
    font-weight: bolder;
    display: inline-flex;
    vertical-align: middle;
    justify-content: center;
    align-items: center;
}

.bookinf-text {
    color: #fff;
}

.baar {
    width: 89%;
    border: 1px solid #fff;
}

.step2 {
    border: none;
    border-radius: 50%;
    text-align: center;
    width: 2.5rem;
    height: 2.5rem;
    font-weight: 700;
    font-size: 1rem;
    display: table-cell;
    vertical-align: middle;
    border: none;
    border-radius: 50%;
    color: #000;
    background-color: whitesmoke;
    text-align: center;
    font-weight: bolder;
    display: inline-flex;
    vertical-align: middle;
    justify-content: center;
    align-items: center;
}


.hotelImgCI {
    width: 100%;
    height: 8rem;
}

.BButton {
    color: #fff !important;
    background-color: #856ac5 !important;
    border: 1px solid #856ac5 !important;

}

.checkOut-information {
    border-radius: 8px;
    background-color: #EFECEC;
    background-color: var(--secondary-color);
    color: rgb(34, 34, 34) !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 20px !important;
    position: relative;
}

.checkin-check-out {
    position: relative !important;
    display: flex !important;
    width: 100% !important;
    margin: 0px !important;
    background-color: transparent !important;
    box-shadow: none !important;
    cursor: pointer !important;
    padding: 0px !important;
    text-align: left !important;
    border-radius: 8px 8px 0px 0px;
    border: 1px solid #cccc;
}

.anchor-down {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
}

.addults-combo {
    position: relative !important;
    display: flex !important;
    width: 100% !important;
    margin: 0px !important;
    color: black !important;
    background-color: transparent !important;
    box-shadow: none !important;
    cursor: pointer !important;
    padding: 0px !important;
    border-top: none !important;
    text-align: left !important;
    border-radius: 0px 0px 8px 8px;
    border: 1px solid #cccc;
    -webkit-border-radius: 0px 0px 8px 8px;
    -moz-border-radius: 0px 0px 8px 8px;
    -ms-border-radius: 0px 0px 8px 8px;
    -o-border-radius: 0px 0px 8px 8px;
}

.addults-combo:focus {
    border-radius: 8px !important;
    border: 3px solid black !important;
    -webkit-border-radius: 8px !important;
    -moz-border-radius: 8px !important;
    -ms-border-radius: 8px !important;
    -o-border-radius: 8px !important;
}

.top-txt {
    position: absolute !important;
    top: 12px !important;
    left: 12px !important;
    right: 12px !important;
    margin: 0px !important;
    padding: 0px !important;
    pointer-events: none !important;
    font-size: 10px !important;
    line-height: 12px !important;
    color: #000 !important;
    text-transform: uppercase !important;
    font-weight: 900 !important;
    max-width: 100% !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    cursor: pointer;
}

.input-txt {
    min-height: 56px !important;
    width: 100% !important;
    border: none !important;
    outline: none !important;
    margin: 0px !important;
    color: rgb(113, 113, 113) !important;
    padding: 26px 12px 10px !important;
    background-color: transparent !important;
    font-family: inherit !important;
    font-size: 15px !important;
    font-weight: inherit !important;
    -webkit-appearance: none !important;
            appearance: none !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    cursor: pointer;
}

.chheckii {
    position: relative !important;
    flex: 1 1 !important;
    padding: 0px !important;
    overflow: hidden !important;
}

.chheckii:focus {
    border: 2px solid black !important;
}

.checkout-inner {
    display: flex !important;
    width: 100% !important;
    flex: 1 1;
    position: relative !important;
    background: #EFECEC;
    background: var(--secondary-color, none);
    border-radius: 8px 8px 0px 0px;
    inset: 0px 0px -1px;
    cursor: pointer;
}

.checkin-check-out:focus {
    border-radius: 8px !important;
    border: 3px solid black !important;
    -webkit-border-radius: 8px !important;
    -moz-border-radius: 8px !important;
    -ms-border-radius: 8px !important;
    -o-border-radius: 8px !important;
}

.CustomerInfo {
    position: relative;
    z-index: 0;
    background-color: #828181;
    border-radius: 0px 0px 2.5rem 2.5rem;
    -webkit-border-radius: 0px 0px 2.5rem 2.5rem;
    -moz-border-radius: 0px 0px 2.5rem 2.5rem;
    -ms-border-radius: 0px 0px 2.5rem 2.5rem;
    -o-border-radius: 0px 0px 2.5rem 2.5rem;
}

.border-20 {
    border-radius: 2rem;
    -webkit-border-radius: 2rem;
    -moz-border-radius: 2rem;
    -ms-border-radius: 2rem;
    -o-border-radius: 2rem;
}

.customer-drop {
    width: 170px;
    padding: 5px 15px;
    position: relative;
    background-color: whitesmoke;
    text-align: left;
    border-radius: 45px !important;
    border: 1px solid rgba(205, 206, 206, 0.7) !important;
    box-shadow: inset rgba(205, 206, 206, 0.5) 1px 1px 4px 1px !important;
    color: black !important;
    -webkit-border-radius: 45px !important;
    -moz-border-radius: 45px !important;
    -ms-border-radius: 45px !important;
    -o-border-radius: 45px !important;
}

.customer-drop:hover {
    cursor: pointer;
}

.select-option {
    padding: 2px;
    font-size: 13px;
}

ul.options {
    border: 1px solid #fff;
    display: none;
    list-style: none;
    padding: 4px 0;
    border-radius: 10px !important;
    margin-top: 0px;
    background: #fff;
    box-shadow: 2px 2px 5px 2px #cccc;
    width: 95%;
    z-index: 1000;
    -webkit-border-radius: 10px !important;
    -moz-border-radius: 10px !important;
    -ms-border-radius: 10px !important;
    -o-border-radius: 10px !important;
}

ul.show {
    display: block;
    width: -webkit-max-content;
    width: max-content;
    height: 15rem;
    position: absolute;
    overflow-y: scroll;
    left: 50% !important;
    right: auto !important;
    -webkit-transform: translate(-50%, 0) !important;
            transform: translate(-50%, 0) !important;
}

.destination-sidebar {
    position: -webkit-sticky !important;
    position: sticky !important;
    top: 1% !important;
    background-color: #fff;
}

ul.options li {
    padding: 6px 10px;
}

ul.options li:active,
ul.options li:focus,
ul.options li:hover,
ul.options li[aria-selected="true"] {
    background: #FF8500;
    background: var(--main-theme-color);
    opacity: 0.8;
    color: #fff;
    cursor: pointer;
}

.wrapper {
    position: relative;
}

.up-price,
.down-price {

    background-color: #FF8500 !important;

    background-color: var(--primary-color) !important;
    border: 1px solid #FF8500 !important;
    border: 1px solid var(--primary-color) !important;
    color: #EFECEC !important;
    color: var(--secondary-color) !important;

}


.Buttons {
    font-size: 1rem;
    color: #000;
    width: 7.9rem;
    height: 2.6rem;
    background-color: #C0C0C0;
    border: none;
    border-radius: 45px;
    box-shadow: 1px 1px 5px 2px #d3d1d1;
    transition: all 0.3s ease 0s;
    cursor: pointer;
    outline: none;
}
.bg-orange {
    background-color: #FF8500;
    background-color: var(--main-theme-color);
}
.ft-1rem{
    font-size: 0.9rem;
}
.Buttons:hover {
    background-color: #fca527;
}

.up-price:after {
    content: '\2191';
    /* width: 4px;
    height: 23px; */
    position: absolute;
    right: 15px;
    color: #EFECEC;
    color: var(--secondary-color);
}

.asc {

    background-color: #EFECEC !important;

    background-color: var(--secondary-color) !important;
    border: 1px solid #EFECEC !important;
    border: 1px solid var(--secondary-color) !important;
    color: black !important;

}

.up-price:hover:after {
    color: #EFECEC;
    color: var(--secondary-color);
}

.omRender:hover::after {
    content: '\2191';
    /* width: 4px;
    height: 23px; */
    position: absolute;
    right: 15px;
    color: #EFECEC;
    color: var(--secondary-color);
}

.down-price:after {
    content: '\2193';
    /* width: 4px;
    height: 23px; */
    position: absolute;
    right: 15px;
    color: #EFECEC;
    color: var(--secondary-color);
}

.down-price:hover::after {

    color: #EFECEC;

    color: var(--secondary-color);
}

.shadow {
    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15) !important;
}

.search-detail ul li,
.ui-menu-item {
    padding: 8px 10px;
}

.br-theme-bars-square .br-widget a.br-active,
.br-theme-bars-square .br-widget a.br-selected {
    color: #fff !important;
    background: #FF8500 !important;
    background: var(--main-theme-color) !important;
    border: 1px solid #FF8500;
    border: 1px solid var(--main-theme-color);
}

/* .search-detail:before, .ui-autocomplete:before {
    position: absolute;
    top: -19px;
    left: 48px;
    content: '';
    border-width: 10px;
    border-color: transparent transparent var(--main-theme-color);
    border-style: solid;
} */
.autocomplete {
    position: fixed !important;
    width: 80% !important;
}

#searchData {
    top: 55px;
    min-width: -webkit-max-content;
    min-width: max-content;
    /* height: 270px;
    overflow-y: scroll; */
    left: -10px;
    border-radius: 20px;
    box-shadow: 2px 2px 4px 1px #cccc;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
}

#searchData1 {
    min-width: -webkit-max-content;
    min-width: max-content;
}

/* .dEVViv {
    position: inherit !important;
    width: 80% !important;
    height: 22px !important;
    background-color: #F3F2F2 !important;
}

.cmyEXk {
    min-height: 0px !important;
    background-color: #F5F4F4 !important;
} */

.wrapper input {
    padding-left: 0px !important;
    font-family: 'Poppins';
    font-size: 0.75rem;
    line-height: 0;
    width: 100%;
}

.wrapper input::-webkit-input-placeholder {
    font-family: 'Poppins';
    font-size: 0.75rem;
}

.wrapper input::placeholder {
    font-family: 'Poppins';
    font-size: 0.75rem;
}

/* .dEVViv>.wrapper {
    width: 79% !important;
} */

.property-destination {
    margin-bottom: 15px;
    padding: 10px;
    height: auto;
    position: relative;
    z-index: 0;
    background-color: #fff !important;
    border-radius: 20px;
}

.cards-property-destination {
    margin-bottom: 15px;
    padding: 13px 15px;
    height: auto;
    position: relative;
    z-index: 0;
    background-color: #fff !important;
    border-radius: 20px;
}

.cards-property-destination-skeleton {
    margin-bottom: 15px;
    padding: 20px 10px;
    height: auto;
    position: relative;
    z-index: 0;
    background-color: #fff !important;
    border-radius: 20px;
}




.premium-card {
    position: absolute;
    top: 0px;
    width: 6rem;
    height: 2rem;
    border: 1px solid rgba(0, 0, 0, 0.2);
    padding: 2px 15px 2px 10px;
    box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.4);
    left: 0;
    background-color: #ffff;
    border-radius: 0px 50px 50px 0px;
    z-index: 1;
    -webkit-border-radius: 0px 50px 50px 0px;
    -moz-border-radius: 0px 50px 50px 0px;
    -ms-border-radius: 0px 50px 50px 0px;
    -o-border-radius: 0px 50px 50px 0px;
}

.premium-card img {
    width: 100%;
    height: 100%;

}

.nodata {
    padding: 0.3rem;
    text-align: center;
    color: grey;
    opacity: 0.5;
}

.singal-blog-image {
    height: 50vh;
    width: 100%;
    object-fit: fill;
    border-radius: 45px;
    -webkit-border-radius: 45px;
    -moz-border-radius: 45px;
    -ms-border-radius: 45px;
    -o-border-radius: 45px;
}

.room-icon {
    font-size: x-small;
}

.travelTipsBtn {
    width: 80px;
    height: 20px;
    font-size: 9px;
    font-weight: 500;
    color: #fff;
    background-color: rgb(239, 78, 34);
    border: none;
    border-radius: 45px;
    box-shadow: 1px 1px 1px 1px rgb(205 206 206);
    transition: all 0.3s ease 0s;
    cursor: pointer;
    outline: none;
}


.im {
    width: 9rem;
}

.links {
    opacity: 1 !important;
    width: 30px;
    height: 30px;
    display: flex;
    font-size: 15px;
    justify-content: center;
    align-items: center;
    margin: 0px 4px;
    border-radius: 100% !important;
    text-align: center !important;
    background-color: #EFECEC;
    background-color: var(--secondary-color);
}

.links:hover {
    background-color: #FF8500;
    background-color: var(--primary-color);
}

.links:hover .icons {
    color: #EFECEC;
    color: var(--secondary-color);
}

.social-li {
    padding: 0px 4px;
}

.icons {
    color: #FF8500;
    color: var(--main-theme-color);
}

/* 
.footer-right {
    width: 25rem;
} */

.footer-text {
    font-size: 13px;
    text-align: justify;
    margin: 0px;
    padding: 0px;
}

/* .owl-theme-explore .owl-item {
    padding-left: 10px;
    padding-right: 10px;

} */

.final-booking li {
    margin-top: 1rem;
    list-style: none;
    position: relative;
}

.final-booking li::before {
    content: "\2022";
    color: #FF8500;
    color: var(--main-theme-color);
    font-weight: bold;
    display: inline-block;
    width: 1.2em;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    left: -20px;
}

.custom-checkbox-1 input {
    display: none;
}

.custom-checkbox-1 span {
    border: 3px solid #FF8500;
    border: 3px solid var(--main-theme-color);
    float: right;
    height: 20px;
    width: 20px;
    border-radius: 5px;
    cursor: pointer;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.custom-checkbox-1:hover span,
.custom-checkbox-1 input:checked+span {
    border: 3px solid #FF8500;
    border: 3px solid var(--main-theme-color);
}

.custom-checkbox-1 input:checked+span:before {
    content: "\2714";
}

.no-deals {
    font-size: 30px;
    color: #FF8500;
    color: var(--main-theme-color);
}

.vh-100 {
    min-height: 100vh !important;
}

.vh-50 {
    min-height: 70vh !important;
}

/* ****************************************************************************
Property Details css ended
**************************************************************************** */


/* ****************************************************************************
About us css started
**************************************************************************** */


/* My Bookin Start */
.Accomodation {
    font-size: '18px';
    font-family: 'Gotham Rounded Bold';
    margin-left: '15px';

}

.bookingimg {
    width: 60%;
    height: 400px;
}

.bookingstar {
    width: 25px;
    height: auto;
}

.bookingtext {
    font-size: 18px;
    font-family: Poppins;

}

.btnstatus {
    width: 150px;
    height: 50px;
    font-size: 18px;
    font-weight: 900;
    color: #fff;
    background-color: rgb(239, 78, 34);
    border: none;
    border-radius: 45px;
    transition: all 0.3s ease 0s;
    cursor: pointer;
}

/* My Bookin end */

.aboutUs-second-col {
    width: 5%;
    background-color: #575757;

}

.aboutUsHeadings {
    font-size: 30px;
    font-weight: 900;
    padding-top: 60px;
    font-family: PoppinsBold;
    text-align: center;
    color: #fff;

}

.aboutusbackground {
    background-color: #EF4E22;
}

.aboutUsimage {

    width: 110%;
    height: auto;


}

.AboutusText {
    margin-top: 30px;
    margin-bottom: 30px;
    text-align: justify;
    font-family: Poppins;
}

.imageText {
    position: absolute;
    bottom: 40%;
    width: 100%;
    font-weight: 900;
    font-size: 25px;
    padding-left: 100px;
    font-family: PoppinssemiBold;
    text-align: center;
    color: #ffffff;
}

/* ****************************************************************************
About us css started ended
**************************************************************************** */

/* ****************************************************************************
Terms and Condition css started
**************************************************************************** */


#more {
    display: none;
}

.Terms-second-col {
    width: 5%;
    background-color: #575757;

}

.TermsHeading {
    font-size: 30px;
    font-weight: 900;
    padding-top: 60px;
    font-family: PoppinsBold;
    text-align: center;
    color: #fff;


}

.termsbackground {
    background-color: #EF4E22;
}

.Termsimage {

    width: 105%;
    height: auto;
}

.TermsButton {
    border: 1px solid #EF4E22;
    border-radius: 10px;
    font-size: 20px;
    font-weight: 1000;
    margin-left: 5px;
    padding: 15px 40px 15px 40px;
    background: #EF4E22;
    color: #ffffff;
}

.TermsButton:hover {
    background-color: #fca527 !important;
    background-color: var(--hover-color) !important;
    border: 1px solid #fca527 !important;
    border: 1px solid var(--hover-color) !important;
    font-family: Poppins !important;
    color: #EFECEC !important;
    color: var(--secondary-color) !important;
}

.TermText {
    margin-top: 30px;
    font-weight: 600;
    font-family: Poppins;

}

/* ****************************************************************************
Terms and Condition css Ended
**************************************************************************** */
/* Add Property start */


.image {
    width: 50px;
    height: auto;
}

.PropertyText {
    font-family: Poppins;
    font-size: 12px;

}

.Address {
    font-family: PoppinssemiBold;

}

.third-headings {
    font-size: 0.9rem;
    font-family: PoppinssemiBold;
}


/* Add Property end */


.calender {
    color: #EF4E22;
    font-size: large;

}

/* ****************************************************************************
Review css Started
**************************************************************************** */

.ReviewHeading {
    font-size: 22px;
    font-family: PoppinssemiBold;
    font-weight: 900;
}



.thanksText {
    text-align: start;
    font-size: 22px;
    font-weight: 800;
}


.HeadHeading {
    font-size: 20px;
    font-weight: 900;
    font-family: PoppinssemiBold;
    text-align: start;
}

progress {

    background: crimson;
    height: 15px;
}

progress {
    color: #EF4E22;
}

progress::-moz-progress-bar {
    background: #EF4E22;
}

progress::-webkit-progress-value {
    background: greenyellow;
}

progress::-webkit-progress-bar {
    background: #ff3d00;
}

.blurred-card {
    opacity: 0.5;
    pointer-events: none;
}

.Head {
    padding: 5px;
    margin: 0 5% 0 5%;
    border-bottom: 2px solid #999999;

    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 15px;


}

.Headtext {
    font-size: 16px;
    color: #ff3d00;
}

.cardstyle {
    border-radius: 10px;
    height: 290px;
    margin: 0px;
    border: 1px solid #e7e8e9;

}

.progress {
    position: -webkit-sticky;
    position: sticky;
    background-color: #fff;


}

.sliderstyle {
    margin: 0% 0% 2% 0%;
    position: -webkit-sticky;
    position: sticky;
}



.thanks {
    color: #000;
    text-align: center;
    text-decoration: underline;
}

.Reviewimage {
    margin-top: 5px;

}

.textarea {
    font-size: 16px;
    font-weight: 600;
    border-radius: 20px;
    border: 1px solid #e7e8e9;
}

.sliderrang {
    font-size: 20px;
    font-weight: 900;
}

.overflow-texts {
    text-overflow: ellipsis !important;
    overflow: hidden !important;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
}

.sliderImgs {
    padding: 12px;
    border-radius: 19px;
}

.priceRs {
    color: #FB8501 !important;
    margin-top: 5px;

}

.card-title-section {
    margin-bottom: 35px;
}

.star-reviews {
    padding-left: 15px !important;
    color: #FB8501;
    margin-top: 29%;

}

.review-p {
    color: #000 !important;
    margin-left: 4px;
}

.hotel-card {
    background-color: #fff;
}




.text-style {
    color: #fff;
    font-weight: 100 !important;
    font-size: 0.75rem;
    text-align: center;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
}



.date-of-birth {
    padding-left: 0px !important;
}

.city-form {
    padding-right: 0px !important;
}

.destination-heading {
    font-size: 22px;
    color: #000;
    font-weight: 900;
}

.review-heading {
    color: #2F9EE9;
}

/* .w-25{
    width: 25rem;
} */
/* ****************************************************************************
Review css Ended
**************************************************************************** */
/* cards css */
.cards-propert-text {
    font-size: 0.8rem !important;
    color: #3b3b3b !important;
    color: var(--text-color) !important;
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.cards-cityimage {
    /* width: 34rem !important; */
    padding-right: 0px;
    padding-left: 0px;
    background-color: transparent;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    border-radius: 10px !important;
    box-shadow: -1px 3px 5px 1px rgb(0 0 0 / 40%);
    -webkit-border-radius: 10px !important;
    -moz-border-radius: 10px !important;
    -ms-border-radius: 10px !important;
    -o-border-radius: 10px !important;
}

.cards-cityimage img {
    border-radius: 10px !important;
    width: 8.5rem !important;
    height: 10.5rem !important;
}

.cards-stars {
    line-height: 0.9;
}

.excellent-text {
    font-size: 0.9rem;
    padding-left: 5px;
}

.excellent-container {
    display: flex;
    align-items: center;
    font-family: PoppinsBold !important;
}

.excellent-text {
    font-family: PoppinsBold !important;

}

.excellent {
    width: 35px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.95rem;
    border-radius: 5px;
    background-color: #FF8500;
    background-color: var(--main-theme-color);
    color: #FCFCFC;
    color: var(--white);
    text-align: center;
    box-shadow: -1px 2px 5px 1px rgb(0 0 0 / 20%);
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}



.excellent-price {
    font-size: 1.15rem;
    font-family: PoppinsBold !important;
    color: #FF8500;
    color: var(--main-theme-color);
}

.excellent-price_hotels {
    font-size: 1.3rem;
    font-weight: bold;
    font-family: PoppinsBold !important;
    color: #FF8500;
    color: var(--main-theme-color);
}

.pernight {
    font-size: 0.65rem;
    line-height: 0.6;
    color: #3b3b3b;
    color: var(--text-color);
}

.hotels-map {
    width: 40px;

}

.slider-text {
    position: absolute;
    bottom: 10px;
    left: 50%;
    font-size: 1.6rem;
    letter-spacing: 3px;
    font-family: 'PoppinssemiBold';
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
}

.hotel-name {
    width: 70%;
}

.hotel-orice {
    width: 40%;
}

.start-green {
    color: #FF8500;
    color: var(--main-theme-color);
}

.content-css {
    color: #3b3b3b;
    color: var(--text-color);
}

.content-css a {
    color: #FF8500;
    color: var(--main-theme-color);
}

.text-gray {
    color: #3b3b3b !important;
    color: var(--text-color) !important;
}

.explore-heading {
    color: #fff;
    font-size: 0.95rem;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
}

.pageslist li {
    list-style: none;
    text-decoration: none;
}

.footerlink {
    padding: 3px 0px;
}

.footerlink:hover {
    text-decoration: underline !important;
    color: #fca527 !important;
    color: var(--hover-color) !important;
}

/* ****************************************************************************
Medium Responsiveness started
**************************************************************************** */
@media (min-width:769px) and (max-width:1024px) {
    .mySwiper {
        height: 12rem !important;
    }

    .mySwiper2 {
        height: calc(80vh - 60px) !important;
    }

    .room_mySwiper2 {
        height: calc(70vh - 60px);

    }
}

@media (min-width:481px) and (max-width:768px) {
    .mySwiper {
        height: 12rem !important;
    }

    .mySwiper2 {
        height: calc(80vh - 60px) !important;
    }

    .room_mySwiper2 {
        height: calc(70vh - 60px) !important;

    }
}

/* ****************************************************************************
Mobile Responsiveness started
**************************************************************************** */

@media (min-width:320px) and (max-width:480px) {
    .footer-inner {
        background-color: #EFECEC;
        background-color: var(--secondary-color);
        /* height: 375px; */
    }

    html {
        font-size: 84% !important;
    }

    .center-cal {
        top: 50px !important;
        left: 50% !important;
        right: auto !important;
        transform: translate(-50%, 0) !important;
        -webkit-transform: translate(-50%, 0) !important;
        -moz-transform: translate(-50%, 0) !important;
        -ms-transform: translate(-50%, 0) !important;
        -o-transform: translate(-50%, 0) !important;

    }

    .btn-calltoaction {
        padding: 10px 30px 10px 30px !important;
        background-color: #FF8500 !important;
        background-color: var(--primary-color) !important;
        border: 1px solid #FF8500 !important;
        border: 1px solid var(--primary-color) !important;
        border-radius: 45px !important;
        font-weight: 900;
        margin-top: 12px;
        width: 100% !important;
        font-family: Poppins !important;
        color: #fff !important;
        outline: none !important;
    }

    .destination-heading {
        font-size: 16px;
        color: #000;
        font-weight: 900;
    }

    .cardprice {
        margin-top: 25% !important;
    }

    .social-li {
        padding: 0px 4px;
    }

    .freepartTxt {
        font-size: 11px;
    }



    .card-address {
        font-size: 11px;

    }

    .card-title-section {
        margin-bottom: 30px;
    }

    .SearchResultsPage {
        overflow-x: hidden;
    }

    /* .slide_item {

        height: 23vh;
        border-radius: 30px;
        background-repeat: no-repeat;
        background-position: right;
        background-size: 150% 100%;
        -webkit-border-radius: 30px;
        -moz-border-radius: 30px;
        -ms-border-radius: 30px;
        -o-border-radius: 30px;
    } */

    .mtt-3 {
        margin-top: 1rem !important;
    }


    .links {
        width: 26px;
        height: 26px;
        font-size: 12px;
        margin: 0px 4px;
    }

    .roomph-blogs .owl-item {
        margin-right: 5px !important;
        margin-left: 5px !important;

    }

    .footer-most-inner {
        flex-direction: column;
    }

    .footer-right {
        width: 100%;
        margin-top: 25px;
    }

    .footer-text {
        margin-top: 2rem;
        text-align: center;
    }

    .singal-blog-image {
        height: 30vh;
    }

    .blog-content h5 {
        font-weight: bolder;
        font-size: 20px;
    }

    .download {
        padding: 0px 3px;
        height: 3rem;
    }


    .p1 {
        font-size: 10px;
    }

    .p2 {
        font-size: 12px;
    }

    .f-3 {
        font-size: 2em;
    }

    .mx-sm-2 {
        margin: 0px 5px;
    }


    .App {
        padding: 0;
        margin: 0;
        background-color: #F0EDED;
        border: border-box;
    }

    .search-box {
        width: 72.8%;
    }

    .owl-theme-des .owl-prev {
        left: -18px;
        top: 30%;
    }

    .owl-theme-des .owl-next {
        right: -18px;
        top: 30%;
    }


    .nosubmit {
        width: 97%;
        position: relative;
    }

    .desc {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        overflow: hidden;
        margin: 8px 10px 15px 10px;
    }

    .viewall {
        background-color: transparent;
        display: flexbox;
        justify-content: center;
        align-items: center;
        width: 100%;
        text-align: center;
        font-size: 1rem;
    }

    .explore-row-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .container {
        zoom: 90% !important
    }

    .explore-row-right {
        width: 75%;
        padding: 0px;

    }




    .explore-row-left .top {
        font-size: 1.2rem;
        font-weight: 900;
    }



    .explore-row-left .notton {
        font-size: 1.2rem;
        font-weight: 900;
        line-height: 0.5;


    }

    .explore-row-left .cc {
        font-size: 1.4rem;
        line-height: 1;

    }



    /* .owl-theme-explore .owl-item {
        padding-left: 8px;
        padding-right: 8px;
        width: 110.981px;

    } */

    .review-p {
        color: #000 !important;
        margin-left: 4px;
    }

    .star-reviews {
        padding-left: 15px !important;
        color: #FB8501;
        margin-top: 0%;

    }


    .exploreCityImage {
        border-radius: 15px;
        height: 10rem !important;
        width: 100% !important;
        -webkit-border-radius: 15px;
        -moz-border-radius: 15px;
        -ms-border-radius: 15px;
        -o-border-radius: 15px;
    }

    .explore-desc h6 {
        line-height: 1.1;
        padding: 0px !important;
        margin: 0px !important;
        font-size: 14px !important;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        overflow: hidden;
        height: 30px;
        font-family: PoppinssemiBold !important;

    }

    .explore-desc span {
        font-size: 10px !important;
    }



    .mBs {
        font-size: 0.9rem;
    }

    .form-group {
        margin-bottom: 0.6rem !important
    }

    .facilities-mobile {
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-between;
        align-items: center;
    }

    .facilities-mobile-top {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .facilities-container_inner p {
        font-size: 0.7rem !important;

    }

    .facilities-container_inner img {
        width: 1.8rem;
    }

    .facilities-container_inner {
        padding: 0.8rem 0.7rem;
        margin: 0px 0px 0px 10px;
        border-radius: 20px;
        -webkit-border-radius: 20px;
        -moz-border-radius: 20px;
        -ms-border-radius: 20px;
        -o-border-radius: 20px;
    }

    .facilities-mobile-right {
        justify-content: center;
        align-items: center;
        margin: 30px 0px;
    }


    .header-container h4 {
        font-size: 13px;

    }

    .header-container img {
        width: 3.5rem !important;
        height: 3.5rem !important;

    }

    .footer span {
        font-size: 0.55rem;
        margin-right: 0px !important;
        margin-left: 0px !important;

    }

    .footer {
        margin-right: 0px !important;
        margin-left: 0px !important;
    }

    .desc q {
        font-size: 0.8rem !important;
        margin-right: 0px !important;
        margin-left: 0px !important;

    }

    .desc {
        margin-right: 0px !important;
        margin-left: 0px !important;

    }

    .p {
        font-size: 0.6rem !important;
    }

    .pb-sm-2 {
        padding-bottom: 0.5rem;
    }

    .text-mobile-center {
        text-align: center;
    }


    .imgWidr {
        width: 18px;
    }

    .very-good span {
        font-size: 12px;
        font-weight: 900;
    }

    .very-good u {
        font-size: 11px;
        font-weight: 900;
    }

    .hotel-card {
        overflow: hidden;
    }

    /* 
    .Propertylistingcontainer {
        padding-right: 30px !important;
        padding-left: 30px !important;
    } */

    .hotel-card {
        height: 10rem;
        box-sizing: border-box;
        background-color: #fff;
    }

    .sliderImgs {
        height: 10rem;
        padding: 12px;
        border-radius: 19px;
    }

    .h-45 {
        height: 10rem !important;
    }


    .priceRs1 {
        text-align: center;
    }


    .text-black {
        color: #000;
        height: 7%;
        padding-bottom: 15px !important;
        padding-left: 5px !important;
        padding-top: 5px !important;
        margin-bottom: 20px !important;
        margin-bottom: 20px;

    }

    .location {
        border-radius: 0px !important;
        -webkit-border-radius: 0px !important;
        -moz-border-radius: 0px !important;
        -ms-border-radius: 0px !important;
        -o-border-radius: 0px !important;
    }

    .getText {
        font-size: 0.65rem;

    }

    .whats-heading {
        font-size: 12px !important;
    }

    .chat-button {
        font-size: 0.65rem !important;
    }

    .image-containeer img {
        width: 40px;
        vertical-align: middle;
        margin-right: 2px !important;
        margin-top: 10px;
    }

    .h-195 {
        height: calc((35vh - 64px)/2) !important;
    }

    .Propertyll {
        box-sizing: content-box;
        overflow-x: hidden;
    }

    /* .hotel-name-stars{
    position: absolute;
    top: 0px;
    display: inline-block;
    right: 0;
} */

    .location-text {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        line-height: 1.6;
    }

    .overflow-texts {
        text-overflow: ellipsis !important;
        overflow: hidden !important;
        /* white-space: nowrap !important; */
        display: -webkit-box !important;
        -webkit-line-clamp: 3 !important;
        -webkit-box-orient: vertical !important;
        text-overflow: ellipsis !important;
    }



    .f-18 {
        font-size: 15px !important;
    }

    .mobile-book {
        margin: auto
    }

    .mobile-nav {
        position: fixed;
        z-index: 1000;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: #fff;
        padding: 20px 0px;
        box-shadow: 1px 1px 2px 1px #ccc;
    }

    .date-of-birth {
        padding-left: 0px !important;
    }

    .city-form {
        padding-right: 0px !important;
    }



    .third-heading {
        font-size: 13px;
    }

    .container-3 {
        width: 70% !important;
        margin: 0 auto !important;
    }

    .sign-in-container {
        width: -webkit-fit-content !important;
        width: -moz-fit-content !important;
        width: fit-content !important;
        /* margin-top: 40%; */
        /* margin-bottom: 40%; */
        margin-right: 20px;
        margin-left: 20px;
        position: relative;
        top: 45%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
    }

    .sort-popup .custom-checkbox .custom-control-input {
        opacity: 0 !important;
    }

    .custom-control-label::before {
        opacity: 0 !important;

    }

    #exampleCheck1 {
        opacity: 1 !important;

    }

    .MuiSlider-root {
        position: static !important;
    }

    .swal-icon--warning {
        border-color: #FF8500 !important;
        border-color: var(--main-theme-color) !important;
    }

    .swal-icon--warning__body,
    .swal-icon--warning__dot {
        background-color: #FF8500 !important;
        background-color: var(--main-theme-color) !important;
    }

    /* About Us start*/

    .aboutUs-second-col {

        width: 10%;

    }

    .AboutusText {
        font-size: 14px;
        padding-top: 20px;


    }

    .aboutUsHeadings {
        font-size: 16px;
        padding-top: 5px;
    }

    /* About Us end */

    /* Terms and Condition */

    .Terms-second-col {

        width: 10%;

    }

    .TermsHeading {
        font-size: 16px;
        padding-top: 5px;

    }

    /* Terms and Conditions */

    /* Review  start*/

    .HeadHeading {
        font-size: 15px;
        font-weight: 900;
    }

    .slider {
        margin: 0% 0% 3% 0%;
    }

    .Head {
        margin: 0 0% 0 0%;

    }



    .thanksText {
        font-size: 14px;
        text-align: start;
    }

    /* Review end */



    .bookingimg {
        width: 100%;
        height: auto;
    }

    .hotel-desc {
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;

    }

    .hotel-review-container-inner {
        height: 12rem;

    }

    .blog-card-inner .link {
        color: #000 !important;
        font-weight: bold;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        font-size: 0.93rem;
        line-height: 1.3;

    }

    .blog-discription {
        font-size: 0.65rem;
        line-height: 0;
        /* display: -webkit-box; */
        overflow: hidden;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;

    }

    .blog-img {
        border-radius: 20px 20px 0px 0px;
        display: block;
        height: 135px;

    }


    .icon-bar {
        bottom: 23%;
        right: 3%;
        z-index: 100;

    }

    .propert-text {
        font-size: 0.8rem !important;
        color: #3b3b3b !important;
        color: var(--text-color) !important;
        display: -webkit-box;
        overflow: hidden;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }

    .offer-slider-corner {
        position: absolute;
        width: 8.5rem;
        height: 8.5rem;
        padding: 50px !important;
        background-color: #FCFCFC;
        background-color: var(--white);
        top: -18px;
        left: calc(74% / 2);
        transform: rotate(45deg) !important;
        border-radius: 50% 50% 0% 50%;
        -webkit-transform: rotate(45deg) !important;
        -moz-transform: rotate(45deg) !important;
        -ms-transform: rotate(45deg) !important;
        -o-transform: rotate(45deg) !important;
        -webkit-border-radius: 50% 50% 0% 50%;
        -moz-border-radius: 50% 50% 0% 50%;
        -ms-border-radius: 50% 50% 0% 50%;
        -o-border-radius: 50% 50% 0% 50%;
    }

    .offer-per {
        width: 7.5rem;
        height: 7.5rem;
        position: absolute;
        top: -12px;
        left: calc(77% / 2);
        text-align: center;
        border-radius: 50%;
        background-color: #FF8500;
        background-color: var(--main-theme-color);
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        -o-border-radius: 50%;
    }

    .offer-per-inner {
        font-size: 2.4rem;
        font-family: PoppinssemiBold !important;
        text-shadow: 2px 2px rgba(0, 0, 0, 0.5);

    }

    .offer-per-inner2 {
        font-size: 1.5rem;
        font-family: Poppins !important;
        line-height: 0;
    }

    .slider-text {
        position: absolute;
        bottom: 10px;
        right: 0;
        width: 100%;
        text-align: center;
        letter-spacing: 3px;
        font-family: 'PoppinssemiBold';
    }

    /* .cards-propert-text {
        font-size: 0.8rem !important;
        color: var(--text-color) !important;
        display: -webkit-box;
        overflow: hidden;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
    } */

    .owl-theme-explore .owl-prev {
        display: none !important;
    }

    .desc-rightr {
        width: 75% !important;
    }

    .cityimage {
        width: 28% !important;
        height: 100px;
        padding-right: 0px !important;
        padding-left: 0px !important;
        border-radius: 10px !important;
    }


    /* cards css */



    .excellent-text {
        font-size: 0.9rem;
        padding-left: 5px;
    }

    .excellent-container {
        display: flex;
        align-items: center;
        font-family: PoppinsBold !important;
    }

    .excellent-text {
        font-family: PoppinsBold !important;

    }

    .excellent {
        width: 35px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 0.95rem;
        border-radius: 5px;
        background-color: #FF8500;
        background-color: var(--main-theme-color);
        color: #FCFCFC;
        color: var(--white);
        text-align: center;
        box-shadow: -1px 2px 5px 1px rgb(0 0 0 / 20%);
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        -ms-border-radius: 5px;
        -o-border-radius: 5px;
    }

    /* .center-cal {
        top: 60px;
        right: -10%;
    } */

    .excellent-price {
        font-size: 1.15rem;
        font-family: PoppinsBold !important;
        color: #FF8500;
        color: var(--main-theme-color);
    }


    .pernight {
        font-size: 0.65rem;
        line-height: 0.6;
        color: #3b3b3b;
        color: var(--text-color);
    }

    .mySwiper {
        height: 8.5rem;
        box-sizing: border-box;
        padding-top: 25px;
        padding-bottom: 10px;
        padding-right: 0px;
        padding-left: 0px;

    }

    .mySwiper2 {
        height: calc(40vh - 64px);
        border-radius: 2.8rem !important;
        -webkit-border-radius: 2.8rem !important;
        -moz-border-radius: 2.8rem !important;
        -ms-border-radius: 2.8rem !important;
        -o-border-radius: 2.8rem !important;
    }

    .room_mySwiper2 {
        height: calc(35vh - 40px);
        border-radius: 2.5rem !important;
        -webkit-border-radius: 2.5rem !important;
        -moz-border-radius: 2.5rem !important;
        -ms-border-radius: 2.5rem !important;
        -o-border-radius: 2.5rem !important;
    }

    .customer-drop {
        width: 155px !important;
    }
}






/* ****************************************************************************
Mobile Responsiveness ended
**************************************************************************** */
	/*
  	Flaticon icon font: Flaticon
  	Creation date: 22/04/2020 09:56
  	*/

@font-face {
  font-family: "Flaticon";
  src: url(/static/media/Flaticon.06201128.eot);
  src: url(/static/media/Flaticon.06201128.eot?#iefix) format("embedded-opentype"),
       url(/static/media/Flaticon.7f2012e6.woff2) format("woff2"),
       url(/static/media/Flaticon.bd932389.woff) format("woff"),
       url(/static/media/Flaticon.fcc40628.ttf) format("truetype"),
       url(/static/media/Flaticon.a327ccef.svg#Flaticon) format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url(/static/media/Flaticon.a327ccef.svg#Flaticon) format("svg");
  }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
  font-family: Flaticon;
font-size: 20px;
font-style: normal;
}

.flaticon-calendar:before { content: "\F100"; }
.flaticon-calendar-1:before { content: "\F101"; }
.flaticon-search:before { content: "\F102"; }
.flaticon-group:before { content: "\F103"; font-size: 1.5rem !important; }
.flaticon-home:before { content: "\F104"; }
.flaticon-office-building:before { content: "\F105"; }
.flaticon-globe:before { content: "\F106"; }
.flaticon-worldwide:before { content: "\F107"; }

    .btn{
        border: none;
        align-self: center;
      }
      
      .hh4{
        text-align: start;
        font-size: 22px;
        font-weight: 800;
      }
      .btn111{
        border: 2px solid #EF4E22;
        border-radius: 50px;
        font-size: 16px;
        font-weight: 900;
        text-align: center;
        padding: 1% 45% 1% 46%;
        background:#EF4E22;
        color: #ffffff;
    }
      .bt11{
          border: 2px solid #EF4E22;
          border-radius: 50px;
          font-size: 16px;
          font-weight: 800;
          margin-left: 5px;
          padding: 2px 40px 2px 42px;
          background:#EF4E22;
          color: #ffffff;
      }
      .bt11:hover{
        background-color: var(--secondary-color) !important;
        color: #EF4E22;
  
      }
      .bt22:hover{
        background-color: #EF4E22 !important;
        color: #ffffff;
  
      }
      .inputlast{
        padding-bottom: 2px;
        padding-left: 5px;
        padding-right: 2px;
        padding-top: 2px;
      }
      .bt22s{
        border: 2px solid #EF4E22;
        border-radius: 50px;
        margin-right: 7px;
          font-size: 16px;
          font-weight: 800;
          padding: 2px 20px 2px 20px;
          background-color: #ffffff;
          color:#EF4E22;
      }
      .bt22{
      
        border: 2px solid #EF4E22;
        border-radius: 50px;
          font-size: 16px;
          font-weight: 800;
          padding: 2px 20px 2px 20px;
          background-color: #ffffff;
          color:#EF4E22;
      }
     
      .li{
          color: #ffffff;
          font-size: 16px;
          font-weight: 900;
          text-decoration: none;
      
      }
      .headt{
        font-size: 30px;
        font-weight: 900;
        color: #000;
        text-align: center;

      }
      .last{
        background-color: #57585a;
        color: #ffffff;

      }
      .lasts{
        background-color: #57585a;
        color: #ffffff;
      }
      .ltext1{
        text-decoration: underline;
        font-size: 25px;
        color: #000;
      }
      .ltext2{
        font-size: 25px;
        color: #000;
        text-align: end;
        text-decoration:underline;
      }
      .circle1{
        width: 35%;
        border-radius: 50%;
        background-color: #ffffff;
        line-height: 0;
        float: center;
        margin-top: 10px;
        margin-left: 50%;
    
      }
      .circle1::after {
        content: '';
        display: block;
        padding-bottom: 100%;
              }
     
              .circles2{
                width: 60%;
                border-radius: 50%;
                background-color: #ffffff;
                line-height: 0;
                position: relative;
                margin: 0px;
              }
              .circles2::after {
                content: '';
                display: block;
                padding-bottom: 100%;
                      }
      .circles{
        width: 50%;
        border-radius: 50%;
        background-color: #ffffff;
        line-height: 0;
        position: relative;
      }
      .circles::after {
content: '';
display: block;
padding-bottom: 100%;
      }
      .textc{
        position: absolute;
        bottom: 50%;
        width: 100%;
        font-weight: 900;
        font-size: 30px;
        text-align: center;
        color: #EF4E22;
      }
      
      .txt2{
        margin-top: 15px;
        font-weight: 900;
        font-size: 22px;
        text-align: center;

      }
     
    .he{
      font-size: 22px;
      color: #000;
      align-items: center;
      justify-content: center;
      text-align: center;
    }
    .h4{
      font-size: 16px;
      padding-bottom: 5px;
      margin-top: 15px;
      font-weight: 900;
      color: #000;
      align-items: center;
      justify-content: center;
      text-align: center;
    }
      .inn{
          border-radius: 30px;
          border: 1px solid #e7e8e9;
          font-size: 16px;
          margin: 5px;
  
      }
      .innl{
        border-radius: 40px;
          border: 1px solid #e7e8e9;
          font-size: 16px;
          
          margin: 5px;
      }
      progress {
          
          background: crimson;
        }
        
        progress {
          color: #EF4E22;
        }
        
        progress::-moz-progress-bar {
          background: #EF4E22;
        }
        
        progress::-webkit-progress-value {
          background: #93d162;
        }
        
        progress::-webkit-progress-bar {
          background:#EF4E22;
        }
       
        .blurred-card {
          opacity: 0.5;
          pointer-events: none;
        }
        .headtitle{
          padding: 5px;
          margin: 0 5% 0 5%;
          border-bottom: 2px solid #999999;

          border-bottom-right-radius: 15px;
          border-bottom-left-radius: 15px;
  
        
        }
        .cardse{
          box-sizing: border-box;
          
  
  }
        .cards{
          margin: 0% 0% 6% 0%;
          position:-webkit-sticky;
          position:sticky;
        
  
        }
        .thanks{
          color: #000;
        }
        .progre{
          height: 10px;
          
          margin-top: 15px;
          padding-top: 0px;
        }
        .mimage{
          margin-top: 3px;
        }
        .pproo{
            font-size: 35px;

            font-weight: 200;
        }
        .h5{
            font-size: 35px;
            font-weight: 900;
            padding-top: 8px;
            margin: 0px;
        }
        .circl{
          margin-top: 30px;
          margin-bottom: 100px;
          float: right;

        }
        .textcircle{
          margin-top: 10px;
          
        }
       
        .circlfloat
        {
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;

        }
       
         @media (min-width:1100px) and (max-width:1280px)   {
            .cards{
              margin: 0% 0% 11% 0%;
              position:-webkit-sticky;
              position:sticky;
            }
            .he{
              font-size: 17px;
            }
            
         
        .txt2{
          font-size: 23px;
          font-weight: 900;
        }
        
        .btn111{
          font-size: 21px;
          font-weight: 900;
          padding: 1% 45% 1% 45%;

      }
      .circles{
        width: 60%;          
        }
        .circles2{
          width: 60%;
          
        }   
           
          }
          @media (min-width:451px) and (max-width:1100px)   {
           
            .txt2{
              font-size: 16px;
              padding: 0px;
              font-weight: bold;
            }
            .textc{
              font-size: 30px;
            }
            .btn111{
              font-size: 21px;
              font-weight: 900;
              padding: 1% 43% 1% 43%;

          }
          .txt2{
            font-size: 25px;
            font-weight: bold;
          }
          
            .circle1{
             
width: 50%;          
            }
           
           
          }
          .pproo{
            font-size: 25px;
            width: 500px;
        }
        .h5{
          width: 500px;
          font-size: 30px;
        }
          .inputlast{
            padding-bottom: 3px;
            padding-left: 3px;
            padding-right: 2px;
            padding-top: 2px;
          }
          .circles{
              width: 60%;          
              }
              .circles2{
                width: 60%;
                
              }
         
        
        @media (max-width:450px)   {
         
            .pproo{
                font-size: 14px;
                width: 260px;
                font-weight: 100;
            }
            .h5{
              width: 260px;
              font-size: 20px;
              font-weight: bolder;
            }
            .headt{
              font-size: 22px;
              font-weight: 900;
            }

            .txt2{
                font-size: 16px;
                font-weight: bold;
              }
           
            .btn111{
                font-size: 17px;
                padding: 1% 40% 1% 42%;

            }
            .ltext1{
                font-size: 17px;
              ;

            }
            .ltext2{
              font-size: 17px;

          }
            .textc{
              font-size: 20px;
              font-weight: 100;
            }
            .circle1{
             
              margin-left: 50%;
              width: 70px;
              margin-top: 5px;
          
            }
            
            .circl{
              margin-bottom: 70px;

    
            }
            .he{
              font-size: 12px;
              font-weight: 0;

            }
            .circles{
              width: 83%;          
              }
              .circles2{
                width: 83%;
                
              }
              .headtitle{
                margin: 0 0% 0 0%;
                font-size: 10px;
        
        
              
              }
            
          
          
          }
          @media (max-width:393px)   {
         
           
            .circle1{
             
              margin-left: 20%;
              width: 60px;
          
            }
            .he{
              font-size: 14px;
              font-weight: 0;
              color: #000;
              margin-top: 2px;
            }
            .btn111{
              font-size: 14px;
              padding: 1% 40% 1% 42%;

          }
          .ltext1{
            font-size: 14px;

        }
        .ltext2{
          font-size: 15px;

      }
      .textc{
        font-size: 20px;
        font-weight: 200;
      }
      .headtitle{
        margin: 0 0% 0 0%;
        font-size: 10px;


      
      }
      .in{
        
      }
      .txt2{
        font-size: 16px;
        font-weight: 900;
      }
      .circles{
        width: 83%;          
        }
        .circles2{
          width: 83%;
          
        }
        .textcircle{
          width: 500%;
        }
        .h5{
          width: 270px;

        }
        .pproo{
          font-size: 14px;
          width: 270px;
        font-weight: 100;
      }
    
      
          
          
          }
  
  
        
