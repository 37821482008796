.signupBtn {
    width: 100%;
    height: 30px;
    font-size: 15px;
    color: #fff;
    background-color: #EF4E22;
    border: none;
    border-radius: 45px;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease 0s;
    cursor: pointer;
    outline: none;
}

button:focus {
    outline: none;
}

.signupBtn:focus {
    outline: none;
}

.signupBtn:hover {
    background-color: #fca527;
}

.regFrom p {
    overflow: hidden;
    text-align: center;
    font-size: 12px;
}

.regFrom p:before,
.regFrom p:after {
    background-color: #000;
    content: "";
    display: inline-block;
    height: .5px;
    position: relative;
    vertical-align: middle;
    width: 50%;
}

.regFrom p:before {
    right: 0.5em;
    margin-left: -50%;
}

.regFrom p:after {
    left: 0.5em;
    margin-right: -50%;
}

.GBtn,
.FBtn {
    width: 100%;
    height: 22px;
    font-size: 11px;
    letter-spacing: 1px;
    font-weight: 500;
    color: blue;
    background-color: #fff;
    transition: all 0.3s ease 0s;
    cursor: pointer;
    outline: none;
}

.aBtn a {
    color: #EF4E22;
    font-size: 9px;
}

.errMsg {
    text-transform: capitalize;
}

.personIcon {
    font-size: 100px;
    margin-left: 100px;
}

.signinSuccessIcons {
    font-size: 40px;
    margin-left: 140px;
}