.hotN {
  vertical-align: -webkit-baseline-middle;
}

.addressCont {
  font-size: 14px;
  margin-top: -3px;
}

.Button {
  width: 80px;
  height: 20px;
  font-size: 9px;
  font-weight: 500;
  color: #fff;
  background-color: #856ac5;
  border: none;
  border-radius: 45px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none;
}


.Button:hover {
  background-color: #fca527;
}

.revD {
  margin-top: -6px;
  margin-left: -8px;
}


.DSearchButton {
  position: fixed;
  top: 93%;
  left: 10%;
  width: 80%;
  height: 28px;
  font-size: 14px;
  font-family: Gotham Rounded Bold;
  color: #fff;
  margin: 0 auto;
  padding: 0;
  display: inline-block;
  line-height: 30px;
  text-align: center;
  background-color: #EF4E22;
  border: none;
  border-radius: 45px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none;
  z-index: 1000;
}

.DSearchButton:hover {
  background-color: #fca527;
}

.newFull {
  margin-left: 30px;
}



.facility {
  vertical-align: middle;
}

.facility1 {
  vertical-align: text-bottom;
}


.dropbtn {
  background-color: #fff;
  color: black;
  padding: 2px;
  padding-left: 10px;
  border-radius: 0px 10px 10px 10px;
  border: 1px solid rgb(205, 206, 206);
  box-shadow: 2px 2px 2px 2px rgb(205, 206, 206);
  font-size: 12px;
  border: none;
  border-radius: 15px;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 85px;
  border-radius: 10px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content button {
  color: black;
  text-align: center;
  border: none;
  padding-left: 50%;
  outline: none;
  font-family: Montserrat Regular;
  background: none;
  display: block;
}

/* .dropdown-content button:hover {
  background-color: #ddd;
} */

.dropdown:hover .dropdown-content {
  display: block;
}

/* .dropdown:hover .dropbtn {
  background-color: #b0b0b0;
} */

.dropbtn::after .dropdown-content {
  display: none;
}


#popover-basic {
  margin-top: 30px;
}

.topCitiesBtns {
  border: none;
  background: none;
  display: block;
  font-family: Montserrat Regular;
  color: #EF4E22;
}

.mapHeading {
  margin: -3px;
  padding: -3px;
}

.availableRoomsCont {
  padding: 0;
}

.reviewCont {
  height: 200px;
}

.PDPrice {
  font-size: 20px;
}

.locationHeading {
  margin-top: 15px;
  margin-left: 3px;
}

.vg {
  font-size: 14px;
}

.knowMore {
  margin: 0;
  padding: 0;
  font-size: 18px;
}

.knowMore {
  margin: 0;
  padding: 0;
  font-size: 18px;
}

.knowMore2 {
  font-size: 18px;
}

.roomNamePD {
  font-size: 16px;
}

.roomDetailsText {
  font-size: 10px;
}

.cancelText {
  font-size: 8px;
}

.cancelImage {
  width: 10px;
}

.maxText {
  font-size: 8px;
}

.priceRoomAvPD {
  font-size: 16px;
}

.knowMoreDesc {
  font-size: 13px;
}


.price {
  margin-top: 2px;
}

.roomBottom {
  padding-left: 15px;
  padding-right: 15px;
  margin-top: -20px;
}

.revText {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  /* number of lines to show */
  -webkit-box-orient: vertical;
}



.priceContain {
  margin-top: 0px;
}

.mapPic {
  color: #fff;
  /* margin-right: 18px; */
}

.mapp {
  background-image: linear-gradient(to right, rgba(6, 33, 82, .4), rgba(6, 33, 82, .4)), url('./map.svg');
  background-repeat: no-repeat;
  background-size: cover;
  border: 1px solid rgb(205, 206, 206);
  border-radius: 25px 25px 25px 25px;
}

.iitem {
  width: 100%;
}

.ccaption {
  color: #fff;
  font-weight: 900;
  /* margin-right: 16px; */
  margin-top: -5px;
  height: 20px;
}

.CarouselImg {
  height: 200px;
}


.vAllBtn {
  margin-left: 15px;
}


/* Dropdown */


.dropdown {
  position: relative;
  display: inline-block;
  margin-left: 5px;
  white-space: nowrap;
}

.dropdown-btn {
  font-size: 12px;
  background: none;
  border-radius: 15px;
  border: 1px solid rgb(205, 206, 206);
  box-shadow: 2px 2px 2px 2px rgb(205, 206, 206);
  padding-left: 5px;
  height: 20px;
  cursor: pointer;
  font-family: Montserrat Regular;
}

.ddicon {
  padding-bottom: 3px;
}

.dropdown-btn:hover,
.dropdown-btn:focus {
  background: #f2f2f2;
}

.dropdown-items {
  position: absolute;
  font-family: Montserrat Regular;
  background-color: #f1f1f1;
  min-width: 85px;
  border-radius: 10px;
  margin-left: -5px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-item {
  padding-top: 0px;
  text-align: center;
  padding-bottom: 0px;
  cursor: pointer;
  font-family: Montserrat Regular;
}

.dropdown-item:not(first-child) {
  padding-top: 0px;
  padding-bottom: 0px;
}

.dropdown-item:not(last-child) {
  padding-top: 0px;
  padding-bottom: 0px;
}

.isVisible {
  visibility: visible;
}

.isHidden {
  visibility: hidden;
}